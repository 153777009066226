import React from 'react';
import { useRecordContext } from 'react-admin';
import RecordLinkField from '../inputs/RecordLinkField';
import FullNameField from '../inputs/FullNameField';
import get from 'lodash/get';

const UserReferenceField = ({ source, targetId }) => {
    const record = useRecordContext();

    return (
        <RecordLinkField resource="users" targetId={targetId}>
            <FullNameField user={get(record, source)} />
        </RecordLinkField>
    )
}

export default UserReferenceField;
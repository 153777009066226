import React from 'react';
import { StatCard } from './StatCard';
import { CardContent, Skeleton, Typography } from '@mui/material';
import { LineChart, lineElementClasses } from '@mui/x-charts';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

export function CommentHistoryCard({
    provider,
    fromDate,
    toDate
}: {
    provider: string;
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery({
        queryKey: ['comments_history', provider, fromDate, toDate],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/comments_history`
            );

            url.searchParams.append('filter', 'day');
            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            if (provider !== 'all') {
                url.searchParams.append('provider', provider);
            }

            const response = await httpClient(url);

            return (
                response &&
                (response.json as Record<string, Record<string, number>>)
            );
        }
    });

    const translate = useTranslate();

    if (isLoading) {
        return (
            <Skeleton
                variant="rectangular"
                height={500}
                sx={{ marginTop: 5, borderRadius: 5 }}
            />
        );
    }

    if (!data || Object.keys(data).length === 0) {
        return (
            <StatCard sx={{ marginTop: 5 }} elevation={10}>
                <CardContent>
                    <Typography variant="h2" fontSize={18} marginBottom={3}>
                        {translate(
                            'resources.statistics.comments_history.section_title'
                        )}
                    </Typography>

                    <Typography variant="body1" fontSize={16} marginBottom={3}>
                        No data available
                    </Typography>
                </CardContent>
            </StatCard>
        );
    }

    return (
        <StatCard sx={{ marginTop: 5 }} elevation={10}>
            <CardContent>
                <Typography variant="h2" fontSize={18} marginBottom={3}>
                    {translate(
                        'resources.statistics.comments_history.section_title'
                    )}
                </Typography>

                <LineChart
                    height={400}
                    series={Object.entries(data).map(([provider, value]) => ({
                        data: Object.values(value),
                        label: provider,
                        area: true,
                        stack: 'total',
                        showMark: false
                    }))}
                    xAxis={[
                        {
                            scaleType: 'point',
                            data: Object.keys(Object.values(data)[0])
                        }
                    ]}
                    sx={{
                        [`& .${lineElementClasses.root}`]: {
                            display: 'none'
                        }
                    }}
                    slotProps={{
                        legend: {
                            position: {
                                horizontal: 'middle',
                                vertical: 'top'
                            }
                        }
                    }}
                />
            </CardContent>
        </StatCard>
    );
}

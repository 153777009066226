export const ALLOWED_CODE_ACTES = {
    "AN1-DEPOT": {
        title: "Dépôt du texte",
        nextStep: "AN1",
        order: 0
    },
    "AN1": {
        title: "1ère lecture",
        nextStep: "SN1",
        order: 1
    },
    "AN1-COM-FOND-SAISIE": {
        title: "Examen en commission",
        parent: "AN1"
    },
    "AN1-DEBATS-SEANCE": {
        title: "Discussion en séance",
        parent: "AN1"
    },
    "SN1-DEPOT": {
        title: "Dépôt du texte",
        nextStep: "SN1",
        order: 0
    },
    "SN1": {
        title: "1ère lecture",
        chambre: "SN",
        order: 1
    },
    "SN1-COM-FOND-SAISIE": {
        title: "Examen en commission",
        parent: "SN1"
    },
    "SN1-DEBATS": {
        title: "Discussion en séance",
        parent: "SN1"
    },
    "ANLUNI": {
        title: "Lecture unique",
        chambre: "AN",
        order: 1
    },
    "ANLUNI-DEPOT": {
        title: "Dépôt du texte",
        chambre: "AN",
        order: 0
    },
    "ANLUNI-COM-FOND-SAISIE": {
        title: "Examen en commission",
        parent: "ANLUNI"
    },
    "ANLUNI-DEBATS-SEANCE": {
        title: "Discussion en séance",
        parent: "ANLUNI"
    },
    "AN2": {
        title: "2ème lecture",
        nextStep: "SN2"
    },
    "AN2-COM-FOND-SAISIE": {
        title: "Examen en commission",
        parent: "AN2"
    },
    "AN2-DEBATS": {
        title: "Discussion en séance",
        parent: "AN2"
    },
    //"AN2-COM": "2ème lecture en commission",
    //"CMP": "Discussion du texte en commission mixte paritaire",
    //"CMP-DEBATS-AN": "Discussion du texte en commission mixte paritaire",
    //"CMP-DEBATS-SN": "Discussion du texte en commission mixte paritaire",
    //"CC": "Saisine du conseil constitutionnel",
    "PROM": {
        title: "Promulgation",
        alwaysShow: true
    }
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { useTranslate } from 'react-admin';
import dayjs from '../../utils/dayJsSetup';
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
	filters: {
		display: "flex",
		flexDirection: "row",
        alignItems: "center",
        paddingLeft: "0.5rem",
        backgroundColor: "#FFF",
	},
	filterItem: {
		marginRight: "0.5em",
	},
	dateFilter: {
		marginRight: "15px",
		padding: "5px",
	},
    errorMessage : {
		color: "#d32f2f !important",
		fontSize: "0.75em !important",
        paddingTop: "5px"
	},
}));

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

// DATE FORMAT : date=2022-10-03~2022-10-21
export const DashboardDatePicker = (props) => {
    const [startDate, setStartDate] = useState(dayjs(thirtyDaysAgo).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(dayjs(tomorrow).format("YYYY-MM-DD"));
    const [dateError, setDateError] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    useEffect(() => {
        if (startDate > endDate) { setDateError(true); } 
        else { props.setDateRangeCallback(`${startDate}~${endDate}`); setDateError(false); }
    }, [startDate, endDate])

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.filters}>
                <div className={classes.filterItem}>
                    <TextField
                        value={startDate}
                        type='date'
                        onChange={(event) => setStartDate(event.target.value)}
                        className={classes.dateFilter}
                    />
                </div>
                <div className={classes.filterItem}>
                    <TextField
                        value={endDate}
                        type='date'
                        onChange={(event) => setEndDate(event.target.value)}
                        className={classes.dateFilter}
                    />
                </div>
            </div>
            { dateError && <div className={classes.errorMessage}>{translate("pos.dashboard.date_error")}</div> }
        </div>
    );
}
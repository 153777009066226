import React from "react";
import { Filter, SearchInput, DateInput, useTranslate } from "react-admin";

const TagFilter = (props) => {
    const translate = useTranslate();
    
    return (
        <Filter {...props}>
            <SearchInput source="query" alwaysOn variant={"outlined"} />
            <DateInput source='created_at_gte' alwaysOn label={translate("resources.filters.from_date")} variant={"outlined"} />
			<DateInput source='created_at_lte' alwaysOn label={translate("resources.filters.to_date")} variant={"outlined"} />
        </Filter>
    )
}

export default TagFilter;
import React from 'react';
import { FormatAlignLeftOutlined, RemoveRedEye } from '@mui/icons-material';
import styles from './AgendaCompteRendus.module.scss';

const AgendaCompteRendus = ({ pointsOdj }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Agenda et compte-rendus
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {pointsOdj?.length ?
          <>
            {
              pointsOdj.map(({ creation, objet, uid }, index) => (
                <div key={uid} className={styles.item}>
                  <div className={styles.itemInfo}>
                    <div className={styles.date}>{new Date(creation).toLocaleDateString("fr-FR", {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}</div>
                    <div className={styles.itemObjet}>{objet}</div>
                  </div>
                  <div className={styles.buttonsContainer}>
                    <button
                      variant="outline"
                      className={styles.button}
                      aria-label="Voir le compte-rendu"
                    >
                      <FormatAlignLeftOutlined />
                      CR
                    </button>
                    <button
                      variant="outline"
                      className={styles.button}
                      aria-label="Voir la vidéo"
                    >
                      <RemoveRedEye />
                      Vidéo
                    </button>
                  </div>
                </div>
              ))
            }
          </>
          :
          <i>Pas de réunions</i>
        }
      </div>
    </div>
  )
};

export default AgendaCompteRendus;

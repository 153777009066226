import React, { useState } from 'react';
import { BooleanInput, TextInput, DateTimeInput, Create, TabbedForm, FormTab, SaveButton, useTranslate, Toolbar } from 'react-admin';
import { Box, Alert, AlertTitle, Stack } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSettingsContext } from '../../utils/SettingsProvider';
import ImageUrlInput from '../../inputs/ImageUrlInput';
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import MultiSelectInput from '../../inputs/MultiSelectInput';
import LinkArticleInput from '../../inputs/LinkArticleInput';
import ShareIcon from '@mui/icons-material/Share';
import FormSectionTitle from '../../utils/FormSectionTitle';
import ContextMultiSelectInput from '../../inputs/ContextMultiSelectInput';
import contextExample from "../../images/context_sources.png";
import HelperText from '../../utils/HelperText';
import PositionInput from '../../inputs/PositionInput';
import SubApplicationSelectInput from '../../inputs/SubApplicationSelectInput';

const useStyles = makeStyles(theme => ({
	hiddenElement: {
		display: "none !important",
	},
	infoBox: {
		marginTop: "15px",
		marginBottom: "15px",
		boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important"
	},
	infoBoxLink: {
		textDecoration: "none !important",
		color: "rgb(1, 67, 97) !important",
		fontWeight: "bold !important",
		cursor: "pointer",
		"&:hover": {
			opacity: "0.8 !important"
		}
	},
	select: {
		minWidth: "210px !important"
	},
}))


const DebateCreateToolbar = props => {
	const translate = useTranslate();
	return (
		<Toolbar {...props}>
			<SaveButton transform={data => ({ ...data, is_published: true })} />
			<Box display={"flex"} ml={2} pt={"22px"}>
				<DateTimeInput source="published_at" label={translate('resources.groups.labels.draft')} />
			</Box>
		</Toolbar>
	)
}

export const getLocaleNeutralThesis = (locale) => {
	switch(locale) {
		case "fr": return "Sans opinion";
		case "en": return "No opinion";
		case "it": return "Senza opinione";
		case "de": return "Unentschlossen";
		case "es": return "Sin opinión";
		case "pt": return "Sem opinião";
		case "pt-BR": return "Sem opinião";
	}
}

const DebateCreate = props => {
	const translate = useTranslate();
	const createDefaultValues = { has_started: true, pro_thesis: translate("resources.groups.labels.default_pro_thesis"), against_thesis: translate("resources.groups.labels.default_against_thesis"), allowTagDuplicate: undefined };
	const config = useSettingsContext();
	const [debateTitle, setDebateTitle] = useState();

	const transformData = (data) => {
		let positionList = [data.pro_thesis, data.against_thesis];
		if (data.pro_thesis && data.against_thesis && data.has_neutral_thesis) {
			positionList = [data.pro_thesis, data.against_thesis, (config?.settings?.vote?.neutralThesisName || data.language ? getLocaleNeutralThesis(data.language) : translate("resources.groups.fields.neutral_thesis"))];
		}

		let tagObjects = [];
		let tag_id_array = [];
		if (data.tag_list) {
			data.tag_list.forEach((tag) => tagObjects.push({ name: tag.trim() }));
		}
		if (data.tag_id_list) {
			tag_id_array = data.tag_id_list.map(item => item.id);
			if (config?.settings?.modules?.allowTagDuplicate === false) { data.tag_id_list.forEach(item => tagObjects.push({ name: item.name })); }
		}

		return {
			...(data.has_neutral_thesis && { neutral_thesis: (config?.settings?.vote?.neutralThesisName || data.language ? getLocaleNeutralThesis(data.language) : translate("resources.groups.fields.neutral_thesis")) }),
			...(data.name !== undefined && { name: data.name }),
			...(data.subtitle !== undefined && { subtitle: data.subtitle }),
			...(data.ends_at !== undefined && { ends_at: data.ends_at }),
			...(data.published_at !== undefined && { published_at: data.published_at, is_published: false }),
			...(data.is_active !== undefined && { is_active: data.is_active }),
			...(data.image_copyright !== undefined && { image_copyright: data.image_copyright }),
			...(data.image_alt !== undefined && { image_alt: data.image_alt }),
			...(data.is_default !== undefined && { is_default: data.is_default }),
			...(data.is_pending !== undefined && { is_pending: data.is_pending }),
			...(data.origin_image_url !== undefined && { origin_image_url: data.origin_image_url, is_user_image: true }),
			...(data.context_source_list !== undefined && { context_source_list: JSON.stringify(data.context_source_list) }),
			...(data.content_uids && { content_uids: data.content_uids.join(",") }),
			...(data.tag_id_list !== undefined && { tag_id_list: tag_id_array.join(',') }),
			...(data.tag_list !== undefined && { tag_objects: JSON.stringify(tagObjects) }),
			...(data.language !== undefined && { language: data.language }),
			...(data.sub_application_id !== undefined && { sub_application_id: data.sub_application_id }),
			...(JSON.stringify(positionList) !== undefined && { position_list: JSON.stringify(positionList) })
		};
	}

	const validateDate = (value) => {
		const selectedDate = new Date(value);
		const now = new Date();
		if (selectedDate.setMilliseconds(0) < now.setMilliseconds(0)) {
			return translate("resources.groups.labels.date_invalid");
		}
		return undefined;
	};	

	return (
		<>
			<Create title={translate("resources.groups.create_title")} {...props} redirect="list" transform={transformData}>
				<TabbedForm defaultValues={createDefaultValues} toolbar={<DebateCreateToolbar />}>
					<FormTab label={translate('resources.groups.tabs.main')}>
						<FormSectionTitle title={translate('resources.groups.form_title.debate')} />
						<TextInput source="name" fullWidth variant="outlined" required helperText={false} onChange={(e) => setDebateTitle(e.target?.value)} />
						{ config?.settings?.actions?.allowDebateSubtitle === true && 
							<Box mt={1} width={"100%"}>
								<TextInput source="subtitle" fullWidth variant="outlined" multiline minRows={2} helperText={translate("resources.groups.labels.subtitle")} />
							</Box>
						}
						<PositionInput />
						<Stack direction={"row"}>
							{config?.settings?.translation?.enable === true &&
								<Box mb={1} mr={1}>
									<LanguageSelectInput label={translate("resources.settings.fields.language")} />
								</Box>
							}
							{config?.settings?.actions?.allowDebateBranding === true && (
								<Box>
									<SubApplicationSelectInput />
								</Box>
							)}
						</Stack>
						{config?.settings?.modules?.sources !== false &&
							<LinkArticleInput
								tagSource={"tag_id_list"}
								tagInitialSource={"content_tags"}
								tagFormatInitial={(initialValue) => initialValue?.map(e => e.tag)}
							/>
						}
						{config?.settings?.modules?.contextSources &&
							<>
								<FormSectionTitle
									isNew
									example
									exampleTitle={translate('resources.groups.form_title.context')}
									exampleDescription={translate('resources.groups.example.context')}
									exampleImageUrl={contextExample}
									title={translate('resources.groups.form_title.context')}
									descriptionOne={translate('resources.groups.form_description.context_first_description')}
									descriptionTwo={translate('resources.groups.form_description.context_second_description')}
								/>
								<Box display={{ xs: 'block', sm: 'flex' }} sx={{ width: '100%' }} mb={1}>
									<ContextMultiSelectInput
										query={debateTitle}
										reloadOnQueryChange={true}
									/>
								</Box>
							</>
						}
					</FormTab>
					<FormTab label={translate('resources.groups.tabs.advanced')}>
						<MultiSelectInput
							isCreatable
							isAsync
							source="tag_list"
							resource={"tags"}
							format={(v) => v.map((e) => {
								return { label: e.display_name.trim(), value: e.name.trim() };
							})}
							sort={{ field: "taggings_count", order: "DESC" }}
							label={translate('pos.main.tags')}
							placeholder={translate("resources.groups.labels.tag_multiselect")}
							helperText={translate("resources.helpers.tags")}
							noOptionsMessage={translate("resources.groups.labels.no_elements")}
							fullWidth
						/>
						<Box flex={1} mt={3} style={{ display: "flex" }}>
							<BooleanInput source="is_active" format={v => !v} parse={v => !v} defaultValue="true" label={translate('resources.groups.labels.is_active')} />
							{config?.settings?.synthesis?.defaultGroup === true &&
								<BooleanInput source="is_default" fullwidth="true" label={translate('resources.groups.labels.pinned_debate')} />
							}
							<BooleanInput source="is_pending" label={translate('resources.groups.fields.is_pending')} />
						</Box>
						<Box flex={1} mb={1}>
							<DateTimeInput source="ends_at" label={translate('resources.groups.labels.ends_at')} validate={validateDate}/>
						</Box>
						<Box flex={1} mt={2}>
							<ImageUrlInput
								source="origin_image_url"
								initialSource={"image_url"}
								helperText={translate('resources.helpers.debate_image')}
							/>
						</Box>
						<HelperText text={translate("resources.groups.fields.image_info")} />
						<Stack direction={"row"} mt={2}>
							<Box flex={1}>
								<TextInput source="image_copyright" variant="outlined" fullWidth helperText={false} />
								<HelperText text={translate('pos.main.not_required')} />
							</Box>
							<Box flex={1} ml={2}>
								<TextInput source="image_alt" variant="outlined" fullWidth helperText={false} />
								<HelperText text={translate('pos.main.not_required')} />
							</Box>
						</Stack>
					</FormTab>
				</TabbedForm>
			</Create>
			<ShareInfoBox />
		</>
	)
};

export const ShareInfoBox = () => {
	const classes = useStyles();
	const translate = useTranslate();
	return (
		<>
			<Box display={{ xs: 'block', sm: 'flex' }} mb={0} mt={2}>
				<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
					<Alert severity="info" className={classes.infoBox} icon={<ShareIcon fontSize="inherit" />}>
						<AlertTitle>{translate("pos.main.automatic_share")}</AlertTitle>
						<a className={classes.infoBoxLink} href="https://docs.logora.fr/configuration/social" rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
					</Alert>
				</Box>
			</Box>
			<Box display={{ xs: 'block', sm: 'flex' }} mb={3} mt={0}>
				<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
					<Alert severity="info" className={classes.infoBox} icon={<ShareIcon fontSize="inherit" />}>
						<AlertTitle>{translate("pos.main.argument_share")}</AlertTitle>
						<a className={classes.infoBoxLink} href="https://docs.logora.fr/faq/share" rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
					</Alert>
				</Box>
			</Box>
		</>
	);
}

export default DebateCreate;
import { useAuthenticated } from 'react-admin';
import { acteurData } from '../acteurs';
import { organeData } from '../organes';
import { scrutinData } from '../scrutins';
import { dossierData } from '../dossiers';
import { documentData } from '../documents';
import { questionData } from '../questions';
import { amendementData } from '../amendements';
import { interventionData } from '../interventions';
import { reunionData } from '../reunions';
import { MultiList } from './MultiList';
import cloneDeep from 'lodash/cloneDeep';

export const Search = () => {
    const datasets = {
        'dossiers': cloneDeep(dossierData),
        'acteurs': cloneDeep(acteurData),
        'amendements': cloneDeep(amendementData),
        'scrutins': cloneDeep(scrutinData),
        'reunions': cloneDeep(reunionData),
        'interventions': cloneDeep(interventionData),
        'documents': cloneDeep(documentData),
        'questions': cloneDeep(questionData),
        'organes': cloneDeep(organeData)
    }

    useAuthenticated()

    return  <MultiList datasets={datasets} showSearch={true} height='90vh' />
};

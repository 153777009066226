import React, { useCallback, useState, useEffect } from "react";
import {
	useTranslate,
	useDataProvider,
	useGetIdentity,
	useRedirect,
} from "react-admin";
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ModerationStack from "./ModerationStack";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import SectionTitle from "../../utils/SectionTitle";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "15px",
		display: "flex"
	},
	total: {
		marginBottom: "15px"
	},
	success: {
		maxWidth: "max-content"
	},
	successContainer: {
	},
	successButton: {
		marginTop: "15px",
	}
}));

const ModerationList = () => {
	const [moderationStack, setModerationStack] = useState([]);
	const [total, setTotal] = useState(0);
	const [stackTotal, setStackTotal] = useState(0);
	const [currentEntry, setCurrentEntry] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const { data: identity } = useGetIdentity();
	const classes = useStyles();
	const redirectTo = useRedirect();
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const location = useLocation();

	const fetchModerationStack = useCallback(async (currentPage = 1) => {
		setModerationStack([]);
		setCurrentEntry(0);
		setStackTotal(0);
		setIsLoading(true);
		if (currentPage) { setCurrentPage(currentPage) }
		try {
			const response = await dataProvider.getList("moderation_entries", {
				sort: { field: "created_at", order: "ASC" },
				pagination: { page: currentPage, perPage: 25 },
				filter: { active_entries: identity.id, ...location.state }
			});
			const currentStack = response.data;
			const total = response.total;
			if (total) { setTotal(total) };
			if (total === 0) {
				setTotal(total);
				setIsLoading(false);
			}
			if (currentStack.length > 0) {
				setStackTotal(currentStack.length);
				setModerationStack(currentStack);
				lockModerationStack(currentStack);
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [dataProvider]);

	const lockModerationStack = useCallback(async (moderationStack) => {
		try {
			await dataProvider.updateMany("moderation_entries", {
				ids: moderationStack?.map(m => m.id),
				data: { is_locked: true, locked_by: identity.id, locked_at: new Date() }
			});
		} catch (error) {
			console.log(error);
		}
	}, [dataProvider]);

	useEffect(() => {
		if (identity) {
			fetchModerationStack(1);
		} else {
			redirectTo("/moderation_entries/lobby")
		}
	}, [identity])

	const addNotesToContent = (notes) => {
		moderationStack[currentEntry].moderator_notes = notes;
	}

	const addReasonToContent = (reason) => {
		moderationStack[currentEntry].moderation_reason = reason;
	}

	const addSelectionStatusToEntry = (score) => {
		moderationStack[currentEntry].moderable.score = score;
	}

	const addReformulationToEntry = (reformulation) => {
		moderationStack[currentEntry].moderable.reformulated_content = reformulation;
	}

	const handleUpdate = (getPreviousContent = false) => {
		if (getPreviousContent) {
			setCurrentEntry(currentEntry - 1);
		} else {
			setTotal(total - 1);
			if (stackTotal === 1) {
				fetchModerationStack(1);
			} else {
				setStackTotal(stackTotal - 1);
			}

			if (stackTotal > 1) {
				setCurrentEntry(currentEntry + 1);
			} else {
				setStackTotal(0);
			}
		}
	}

	if (identity == undefined) return <><CircularProgress /></>;

	return (
		<>
			<SectionTitle
				title={translate("resources.moderation_entries.label.name")}
			/>
			<div className={classes.root}>
				{total === 0 && moderationStack && isLoading === false ?
					<div className={classes.successContainer}>
						<Alert severity="success" className={classes.success}>
							<AlertTitle>Bravo !</AlertTitle>
							{translate("resources.moderation_entries.label.moderation_completed")}
						</Alert>
						<Button className={classes.successButton} onClick={() => fetchModerationStack(1)}>{translate("resources.moderation_entries.label.load_more")}</Button>
					</div>
					:
					<ModerationStack
						isLoading={isLoading}
						moderationEntry={moderationStack[currentEntry]}
						onUpdate={handleUpdate}
						onUpdateReason={addReasonToContent}
						onUpdateNotes={addNotesToContent}
						onUpdateSelection={addSelectionStatusToEntry}
						onReformulate={addReformulationToEntry}
						currentPage={currentPage}
					/>
				}
			</div>
		</>
	);
};

export default ModerationList;

import React, { useState, useEffect, useCallback } from "react";
import {
	useTranslate, 
	useDataProvider,
} from "react-admin";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import ModerableElement from "./ModerableElement";
import Pagination from '@mui/material/Pagination';

const AuthorMessages = (props) => {
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading]= useState(true);
    const [total, setTotal] = useState();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const fetchAuthorMessages = useCallback(async (page) => {
		setIsLoading(true);
		try {
			const response = await dataProvider.getList("messages", {
				sort: { field: "created_at", order: "DESC" },
				pagination: { page: page, perPage: 10 },
				filter: { user_id: props.userId }
			});
            const total = response.total;
			if (total) { setTotal(total) };
			let messages = response.data;
            if (props.currentEntryId) {
                messages = messages.filter(message => message.id !== props.currentEntryId)
            }
            setIsLoading(false);
            setMessages(messages);
		} catch (error) { console.log(error) }
	}, [dataProvider, props.userId]);

    useEffect(() => {
        if (props.userId) {
            fetchAuthorMessages(currentPage);
        }
    }, [props.userId, currentPage])

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div>
                { isLoading ?
                    <CircularProgress />
                :
                    <>
                        <Typography sx={{ paddingBottom: "20px" }}>
                            {messages.length > 0 ? 
                                messages.map(m => 
                                    <ModerableElement key={m.id} moderable={m} moderableType={"Message"} hideInfos showStatus />
                                )
                            :
                                translate("resources.moderation_entries.label.no_arguments")
                            }
                        </Typography>
                        <Pagination page={currentPage} onChange={handleChangePage} count={Math.ceil(total / 10)} variant="outlined" />
                    </>
                }
            </div>
        </>
    )
}

export default AuthorMessages;
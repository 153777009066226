import { Card } from '@mui/material';
import React from 'react';

interface StatCardProps extends React.ComponentProps<typeof Card> {}

export function StatCard({
    children,
    sx: styles,
    variant,
    ...props
}: StatCardProps) {
    return (
        <Card
            sx={{ borderRadius: 5, padding: 1, ...styles }}
            variant={variant ?? 'outlined'}
            {...props}
        >
            {children}
        </Card>
    );
}

import React from 'react';
import { Edit, TextInput, TextField, TabbedForm, FormTab, ReferenceManyField, RadioButtonGroupInput, Datagrid, DateField, BooleanInput, Labeled, Pagination, usePermissions, FunctionField, NumberField, useTranslate, SaveButton, Toolbar } from 'react-admin';
import { makeStyles } from "@mui/styles";
import { flags } from '../debate/translation_entries/TranslationList';
import { useSettingsContext } from "../utils/SettingsProvider";
import UserTitle from './UserTitle';
import ImageUrlInput from '../inputs/ImageUrlInput';
import Box from "@mui/material/Box";
import ModerationActionToolbar from '../utils/ModerationActionToolbar';

const useStyles = makeStyles((theme) => ({
    flexRow: {
        "& > .ra-input > .MuiBox-root": {
            width: "100% !important",
        }
    },
    title: {
        paddingRight: "10px",
        color: "#5B7FF1 !important",
        textTransform: "uppercase",
        minWidth: "fit-content",
        fontSize: "0.95rem !important",
    },
    line: {
        width: "100%",
        height: "1px",
        backgroundColor: "#5B7FF1",
        border: "0px"
    }
}));

const UserEdit = props => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const classes = useStyles();
    const config = useSettingsContext();

    const EditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit title={<UserTitle />} {...props}>
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab label={translate('pos.tabs.main')} contentClassName={classes.flexRow}>
                    <Box display={{ xs: 'block', sm: 'flex' }} mt={{ xs: "1em" }} mb={{ xs: "1em" }} justifyContent={"center"}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <Labeled label={translate("resources.users.fields.image")}>
                                <ImageUrlInput
                                    source='origin_image_url'
                                    initialSource={'image_url'}
                                    tinyImage
                                />
                            </Labeled>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} ml={{ xs: 0, sm: '0.5em' }}>
                            <Labeled>
                                <TextField source="identifier" label="ID" />
                            </Labeled>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} ml={{ xs: 0, sm: '0.5em' }}>
                            <Labeled label={translate("resources.users.fields.language")}>
                                <FunctionField render={(record) => <>{ record.language?.toUpperCase() } { flags[record?.language] }</>} />
                            </Labeled>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} ml={{ xs: 0, sm: '0.5em' }}>
                            <Labeled>
                                <TextField source="uid" label="UID" />
                            </Labeled>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} ml={{ xs: 0, sm: '0.5em' }}>
                            <Labeled>
                                <NumberField source="moderation_score"/>
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }} >
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="first_name" fullWidth variant={"outlined"} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="last_name" fullWidth variant={"outlined"} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="email" fullWidth variant={"outlined"} disabled />
                        </Box>
                    </Box>
                    <TextInput source="moderator_notes" fullWidth multiline minRows={3} />
                    <Box sx={{ borderLeft: "2px solid #e7e7e7", paddingLeft: "15px", width: "100%", marginBottom: "2em" }}>
                        <TextInput source="description" fullWidth variant={"outlined"} multiline minRows={3} />
                        <Box display={{ xs: 'block', sm: 'flex' }} width={"100%"}>
                            <Box mr={{ xs: 0, sm: '0.5em' }}>
                                <FunctionField label={translate("resources.messages.fields.status")} render={record => !record.moderation_entry ? "" : record.moderation_entry?.status === 'rejected' ? translate('resources.messages.status.rejected') : record.moderation_entry?.status === 'accepted' ? translate('resources.messages.status.accepted') : translate('resources.messages.status.pending')} />
                            </Box>
                            <ModerationActionToolbar noRedirect={true} />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="age" fullWidth variant={"outlined"} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="occupation" fullWidth variant={"outlined"} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="interests" fullWidth variant={"outlined"} />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="city" fullWidth variant={"outlined"} />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }} width={"100%"} >
                        <RadioButtonGroupInput source="moderation_status" optionValue="id" row={false} choices={[
                            { id: 'default', name: 'resources.users.fields.default' },
                            { id: 'banned', name: 'resources.users.fields.banned' },
                            { id: 'untouchable', name: 'resources.users.fields.untouchable' },
                            { id: 'pending', name: 'resources.users.fields.pending' },
                        ]} />
                        <RadioButtonGroupInput source="role" optionValue="id" row={false} choices={[
                            { id: 'contributor', name: 'resources.users.fields.role_default' },
                            { id: 'editor', name: 'resources.users.fields.role_editor' },
                            config?.settings?.actions?.moderatorRole === true && { id: 'moderator', name: 'resources.users.fields.role_moderator' },
                        ]} />
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }} width={"100%"}>
                        {config?.settings?.notifications?.email === true &&
                            <Box flex={0.5} mr={{ xs: 0, sm: '0.5em' }}>
                                <Labeled label={translate('resources.users.fields.receives_emails')}>
                                    <BooleanInput source="receives_emails" />
                                </Labeled>
                            </Box>
                        }
                        <Box flex={0.5} mr={{ xs: 0, sm: '0.5em' }}>
                            <Labeled label={translate('resources.users.fields.accepts_provider_email')}>
                                <BooleanInput disabled={permissions !== "super_admin"} source="accepts_provider_email" />
                            </Labeled>
                        </Box>
                    </Box>
                    <Labeled label={translate('resources.users.fields.points')}>
                        <TextField source="points" />
                    </Labeled>
                </FormTab>
                <FormTab label={translate('pos.tabs.arguments')}>
                    <ReferenceManyField
                        sort={{ field: 'created_at', order: 'DESC' }}
                        reference="messages"
                        target="user_id"
                        source="identifier"
                        pagination={<Pagination />}
                    >
                        <Datagrid bulkActionButtons={null}>
                            <DateField source="created_at" showTime={true} />
                            <TextField source="content" />
                            <FunctionField label={translate("resources.messages.fields.status")} render={record => record.is_deleted ? translate('resources.messages.fields.is_deleted') : record.moderation_entry?.status === 'rejected' ? translate('resources.messages.status.rejected') : record.moderation_entry?.status === 'accepted' ? translate('resources.messages.status.accepted') : translate('resources.messages.status.pending')} />
                            <ModerationActionToolbar noRedirect />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label={translate('pos.tabs.debate_suggestions')}>
                    <ReferenceManyField
                        sort={{ field: 'created_at', order: 'DESC' }}
                        reference="debate_suggestions"
                        target="user_id"
                        source="identifier"
                        pagination={<Pagination />}
                    >
                        <Datagrid bulkActionButtons={null}>
                            <DateField source="created_at" showTime={true} />
                            <TextField source="name" />
                            <FunctionField label={translate("resources.messages.fields.status")} render={record => record.is_deleted ? translate('resources.messages.fields.is_deleted') : record.moderation_entry?.status === 'rejected' ? translate('resources.messages.status.rejected') : record.moderation_entry?.status === 'accepted' ? translate('resources.messages.status.accepted') : translate('resources.messages.status.pending')} />
                            <ModerationActionToolbar noRedirect />
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export default UserEdit;
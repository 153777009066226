import React from 'react';
import { AmendmentBox } from '@logora/parliament.amendment.amendment_box'
import { List } from '../../../parliament/search/List';
import { amendementData } from '../../amendements';
import styles from './AmendementList.module.scss';
import cloneDeep from 'lodash/cloneDeep';

export const AmendementList = ({ document }) => {
  const getAmendementDataset = () => {
    const amendementsFilters = amendementData.filters.concat([
      { field: "texteLegislatifRefUid", hidden: true, defaultValue: document?.uid, type: 'text' },
    ])
    const amendementsDataset = Object.assign(cloneDeep(amendementData), { filters: amendementsFilters })
    return amendementsDataset
  }

  /*
  amendementData.filters = [
    {
      field: "groupePolitiqueRef",
      label: "Groupe politique",
      type: "select",
      defaultValue: "",
      value: "",
      options: [...new Map(amendements.map(item => [item.groupePolitiqueRef.uid, item])).values().map((amendement) => ({
        label: amendement.groupePolitiqueRef?.libelle,
        value: amendement.groupePolitiqueRef?.libelleAbrev,
      }))],
    },
    {
      field: "divisionArticleDesignationCourte",
      // field: "numeroOrdreDepot",
      label: "Article",
      type: "select",
      defaultValue: "",
      options: [...new Map(amendements.map(item => [item.divisionArticleDesignationCourte, item])).values().map((amendement) => ({
        label: amendement.divisionArticleDesignationCourte,
        value: amendement.divisionArticleDesignationCourte,
      }))]
    }
  ]
  */

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Amendements
        </div>
      </div>
      <div className={styles.contentContainer}>
        {document?.uid &&
          <List
            dataset={getAmendementDataset()}
            globalFilters={[]}
            display={true}
            key={"amendements"}
            role="tabpanel"
            tabIndex={0}
            showSearch={true}
            Children={AmendmentBox}
          />
        }
      </div>
    </div>
  );
};

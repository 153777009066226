import React from "react";
import { useLocation } from "react-router-dom";
import { useRecordContext } from "react-admin";
import MuiToolbar from "@mui/material/Toolbar";
import ArgumentSaveButton from "./ArgumentSaveButton";
import ArgumentSelectButton from "./ArgumentSelectButton";
import { makeStyles } from "@mui/styles";
import ModerationActionToolbar from "../../utils/ModerationActionToolbar";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	buttonLine: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: "10px",
	},
	button: {
		margin: "0 15px",
	},
}));

const ArgumentEditToolbar = ({ score }) => {
	const classes = useStyles();
	const record = useRecordContext();
    const location = useLocation();
	const path = location.pathname.includes('messages') ? 'messages' : 'comments'

	return (
		<MuiToolbar className={classes.root}>
			<>
				<ModerationActionToolbar path={path} />
				{record && score && (
					<div className={classes.buttonLine}>
						<ArgumentSaveButton record={record} currentResource={path} score={score} />
					</div>
				)}
				<div className={classes.buttonLine}>
					<ArgumentSelectButton currentRecord={record} currentResource={path} score={score} />
				</div>
			</>
		</MuiToolbar>
	);
};

export default ArgumentEditToolbar;

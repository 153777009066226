import React from "react";
import { Route } from 'react-router-dom';
import { CustomRoutes } from "react-admin";
import { Account } from './Account';
import { Team } from './Team';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';

export const routes = [
    <CustomRoutes>
        <Route path="/account" element={<Account />} />
        <Route path="/team" element={<Team />} />
    </CustomRoutes>,
    <CustomRoutes noLayout>
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
    </CustomRoutes>
]
import React from 'react';
import { TextField, RichTextField, SimpleForm, EditBase, NumberField, Labeled, useTranslate, FunctionField, Link } from 'react-admin';
import { UserReferenceField } from '../../users';
import { Grid, Stack, IconButton, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import MailIcon from '@mui/icons-material/MailOutline';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import RecordLinkField from '../../inputs/RecordLinkField';

const REPORTABLE_TYPES = {
    "DebateSuggestion": "debate_suggestions",
    "Message": "messages",
    "Comment": "comments",
    "Proposal": "proposals"
}

const REPORTABLE_TRANSLATIONS_KEYS = {
    "DebateSuggestion": "see_suggestion",
    "Message": "see_message",
    "Comment": "see_comment",
    "Proposal": "see_proposal"
}

const useStyles = makeStyles((theme) => ({
	textField: {
		background: "rgba(0, 0, 0, 0.08)",
		borderRadius: "4px",
		padding: "16.5px 14px",
		border: "1px solid #ccc",
		marginRight: "15px",
		marginBottom: "10px",
		width: "500px"
	}
}))

const ReportEdit = ({ id, onCancel }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <EditBase id={id}>
            <Stack direction="row" p={2}>
                <Typography variant="h6" flex="1">
                    {translate('resources.messages.labels.detail')}
                </Typography>
                <IconButton onClick={onCancel} size="small">
                    <CloseIcon />
                </IconButton>
            </Stack>
            <SimpleForm
                resource="reports"
                toolbar={null}
                sx={{ maxWidth: '600px !important' }}
            >
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={12}>
                        <FunctionField render=
                            { record =>
                                <RecordLinkField
                                    resource={REPORTABLE_TYPES[record.reportable_type]}
                                    targetId={"reportable_id"}
                                >
                                    { translate("resources.reports.labels." + REPORTABLE_TRANSLATIONS_KEYS[record.reportable_type]) }
                                </RecordLinkField>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled>
                            <TextField source="reportable_id" label={translate('resources.reports.labels.id')} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
						<Labeled label={translate('resources.reports.labels.reportable_author')}>
                            <UserReferenceField
                                targetId="reportable.author.hash_id"
                                source="reportable.author"
                            />
                        </Labeled>
					</Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.content')}>
                            <RichTextField className={classes.textField} source="reportable.content" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.score')} >
                            <NumberField source="reportable.score" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.mail_to')}>
                            <FunctionField render={(record) => (
                                <Link
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = `mailto:${record?.reportable_author_email}`;
                                        e.preventDefault();
                                    }}
                                >
                                    <MailIcon />
                                </Link>
                            )} />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.author')}>
                            <UserReferenceField source="author" targetId="author.hash_id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.description')}>
                            <TextField source="description" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.fields.classification')}>
                        <FunctionField
                            render={(record) => {
                                let classificationTranslation;
                                switch (record.classification) {
                                    case "TOXICITY":
                                    classificationTranslation = translate("resources.moderation_entries.select.toxicity");
                                    break;
                                    case "OBSCENE":
                                    classificationTranslation = translate("resources.moderation_entries.select.obscene");
                                    break;
                                    case "HATE_SPEECH":
                                    classificationTranslation = translate("resources.moderation_entries.select.hate_speech");
                                    break;
                                    case "INCOHERENT":
                                    classificationTranslation = translate("resources.moderation_entries.select.incoherent");
                                    break;
                                    case "SPAM":
                                    classificationTranslation = translate("resources.moderation_entries.select.spam");
                                    break;
                                    case "UNSUBSTANTIAL":
                                    classificationTranslation = translate("resources.moderation_entries.select.unsubstantial");
                                    break;
                                    default:
                                    classificationTranslation = translate("resources.moderation_entries.select.undefined");
                                }

                                return classificationTranslation;
                            }}
                        />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label={translate('resources.reports.labels.mail_to')}>
                            <FunctionField render={(record) => (
                                <Link
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = `mailto:${record?.author?.email}`;
                                        e.preventDefault();
                                    }}
                                >
                                    <MailIcon />
                                </Link>
                            )} />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleForm>
        </EditBase>
    )
};

export default ReportEdit;
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslate, useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    form: { width: '300px', display: 'flex', 'flex-direction': 'column', justifyContent: "center", alignItems: "start" },
    submit: { 'margin-top': '1em', 'margin-bottom': '0.5em' },
});

const UpdatePasswordForm = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const initialState = {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: ""
    };
    const [state , setState] = useState(initialState);

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    const handleUpdatePassword = (event) => {
        event.preventDefault();
        const data = {
            current_password: state.currentPassword,
            password: state.newPassword,
            password_confirmation: state.newPasswordConfirmation
        };
        dataProvider.updatePassword(data).then(response => {
            notify(
                translate('resources.update_password.notifications.success'),
                {
                    type: 'info',
                    undoable: true
                }  
            );
            setState(initialState);
        }).catch(error => {
            notify(
                translate('resources.update_password.notifications.error'),
                {
                    type: 'warning',
                    undoable: true   
                } 
            );
            setState(initialState);
        });
    };

    return (
        <form className={classes.form} onSubmit={handleUpdatePassword}>
            <FormControl margin={"normal"} fullWidth>
                <TextField 
                    className={classes.input} 
                    name={"currentPassword"} 
                    value={state.currentPassword} 
                    label={translate("resources.update_password.fields.current_password")} 
                    type={"password"} 
                    onChange={handleChange}
                    required 
                />
            </FormControl>
            <FormControl margin={"normal"} fullWidth>
                <TextField 
                    className={classes.input} 
                    name={"newPassword"} 
                    value={state.newPassword} 
                    label={translate("resources.update_password.fields.new_password")} 
                    type={"password"} 
                    onChange={handleChange}
                    required 
                />
            </FormControl>
            <FormControl margin={"normal"} fullWidth>
                <TextField 
                    className={classes.input} 
                    name={"newPasswordConfirmation"} 
                    value={state.newPasswordConfirmation} 
                    label={translate("resources.update_password.fields.new_password_confirmation")} 
                    type={"password"} 
                    onChange={handleChange} 
                    required
                />
            </FormControl>
            <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                fullWidth={false}
            >
                {translate("resources.update_password.fields.save")}
            </Button>
        </form>
    );
};

export default UpdatePasswordForm;
import httpClient from "../httpClient";

const apiUrl = process.env.REACT_APP_API_URL;

export const dataProvider = {
	updatePassword: async (data) => {
		const url = `${apiUrl}/users/update_password`;
		const { response } = await httpClient(url, { method: "patch", body: JSON.stringify(data) });
		return { data: response };
	},

	updateMe: async (data) => {
		const url = `${apiUrl}/update_me`;
		return httpClient(url, {
			method: "patch",
			body: data,
		}).then(({ json }) => ({
			data: { ...json },
		})).catch((error) => {});
	},
	
	recoverPassword: async (data) => {
		const finalData = {
			email: data.email,
		};
		if (data.application) {
			finalData["application"] = data.application;
		} else {
			finalData["admin"] = true;
		}
		if (data.redirectUrl) {
			finalData["redirect_url"] = data.redirectUrl;
		}
		const url = `${apiUrl}/users/forgot_password`;
		const { response } = await httpClient(url, { method: "post", body: JSON.stringify(finalData) });
		return { data: response };
	},

	resetPassword: async (data) => {
		const finalData = {
			reset_password_token: data.resetToken,
			password: data.newPassword,
			password_confirmation: data.confirmPassword,
		};
		const url = `${apiUrl}/users/reset_password`;
		return httpClient(url, { method: "patch", body: JSON.stringify(finalData) }).then(({ headers, json }) => ({
			data: json.success,
		})).catch((error) => {});
	},

	createMany: (resource, params) => {
		const ids = params.ids.slice(0, params.ids.length - 1);
		ids.forEach((id) => {
			params.data["email"] = id;
			httpClient(`${apiUrl}/${resource}`, {
				method: "post",
				body: JSON.stringify(params.data),
			});
		});
		return httpClient(`${apiUrl}/${resource}`, {
			method: "post",
			body: JSON.stringify({
				email: params.ids.slice(params.ids.length - 1, params.ids.length)[0],
				role: params.data.role,
			}),
		}).then(({ json }) => ({
			data: params.ids,
		})).catch((error) => {});
	},
};
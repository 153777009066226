import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Button,
    useUpdateMany,
    useNotify,
    useRedirect,
    useUnselectAll,
    useTranslate
} from 'react-admin';

const BulkRemoveDebateButton = (props) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll('sources');
    const translate = useTranslate();
    const [removeDebate, { isLoading }] = useUpdateMany(
        'sources',
        {
            ids: props.selectedIds,
            data: { allow_debate: false }
        },
        {
            mutationMode: 'undoable',
            onSuccess: () => {
                notify(
                    translate('resources.sources.notifications.success'),
                    { 
                        type: 'info',
                        undoable: true
                    }
                );
                redirectTo('/sources');
                unselectAll();
            },
            onFailure: () => {
                notify(
                    translate('resources.sources.notifications.fail'),
                    { type: 'warning' }
                );
            },
        }
    );

    return (
        <Button
            label={translate('pos.actions.remove_debate')}
            onClick={() => removeDebate()}
            disabled={isLoading}
            style={{ margin: '0 10px', color: "#F44336" }}
        >
            <ClearIcon
                color="#F44336"
                style={{ color: '#F44336' }}
            />
        </Button>
    );
};

export default BulkRemoveDebateButton;
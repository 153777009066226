import React, { useState, useEffect, useCallback, cloneElement } from 'react';
import { useDataProvider, useAuthenticated } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
    CircularProgress
} from '@mui/material';
import styles from "./Show.module.scss"
import { BackLink } from '@logora/debate.action.back_link';

export const Show = ({ resource, includes = [], backText, backLink, children }) => {
    const { id } = useParams()
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState(null)

    useAuthenticated()

    const fetchRecord = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(resource, {
                id: id,
                include: includes
            });
            const record = response.data;
            if (record) {
                setRecord(record)
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }, [dataProvider]);

    useEffect(() => {
        if (resource) {
            fetchRecord();
        }
    }, []);

    if (!children) return null

    return (
        <div className={styles.container}>
            {backText && backLink &&
                <BackLink text={backText} to={backLink} />
            }
            {loading || !record ?
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
                :
                <>
                    {cloneElement(children, { resource: record, resourceId: id })}
                </>
            }
        </div>
    );
};
import React, { useState, useEffect } from "react";
import { useDataProvider, useTranslate, Button } from "react-admin";
import { makeStyles } from "@mui/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CreatableSelect from "react-select/creatable";
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles({
	form: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "0 24px 16px 24px",
	},
	selectInput: {
		width: "100%",
	},
	inputLabel: {
		alignSelf: "start",
		marginTop: "15px",
		marginBottom: "10px",
	},
	selectContainer: {
		width: "100%",
		"& > div > div > div:last-child": {
			width: "100%",
			"& > div": {
				width: "100%",
			},
		},
		"& input": {
			width: "100% !important",
		},
		"&::placeholder": {
			zIndex: "-1",
		},
	},
	inviteButton: {
		margin: "16px 24px !important",
		border: "1px solid #5B7FF1 !important",
		color: "#5B7FF1 !important",
		padding: "10px",
		"& span": {
			padding: "0 !important",
		},
	},
	select: {
		width: "100% !important",
		"& > div": {
			paddingTop: "13px !important",
			paddingBottom: "13px !important",
		},
	},
	helperText: {
		width: "100%",
		fontSize: "16px",
		padding: "5px",
		fontWeight: "bold",
		'& > svg': {
			width: "16px",
			height: "16px"
		}
	}
});

const InvitationDialog = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const dataProvider = useDataProvider();
	const [open, setOpen] = useState(props.openInvitationDialog);
	const [mails, setMails] = useState([]);
	const [role, setRole] = useState("editor");

	useEffect(() => {
		setOpen(props.openInvitationDialog);
	}, [props.openInvitationDialog]);

	const handleClose = () => {
		props.onClose();
		setOpen(false);
	};

	const sendInvitations = () => {
		dataProvider
			.createMany("application_invitations", {
				ids: mails.map((mail) => mail.trim().toLowerCase()),
				data: {
					role: role,
				},
			})
			.then(() => {
				props.onSendInvitation();
				setMails([]);
				setOpen(false);
			});
	};

	const components = {
		DropdownIndicator: null,
	};

	const handleChange = (value) => {
		const cleanedValues = value
			? value.map((e) => {
				return e.value.trim().toLowerCase();
			})
			: [];
		setMails(cleanedValues);
	};

	return (
		<Dialog onClose={handleClose} aria-labelledby='invitationDialog' open={open} maxWidth={"sm"} fullWidth>
			<DialogTitle>{translate("resources.application_members.invite_dialog_title")}</DialogTitle>
			<form className={classes.form}>
				<div className={classes.inputLabel}>{translate("pos.actions.send_to")}</div>
				<CreatableSelect
					className={classes.selectContainer}
					components={components}
					isMulti
					onChange={handleChange}
					placeholder='nom@domaine.fr'
					noOptionsMessage={() => translate("pos.actions.invitation_email_placeholder")}
				/>
				<Typography variant="body1" component="span" className={classes.helperText}>
					<HelpOutlineIcon /> {translate("resources.helpers.invitation_multi_select")}
				</Typography>
				<div className={classes.inputLabel}>{translate("pos.actions.invite_as")}</div>
				<FormControl variant='outlined' className={classes.selectInput}>
					<Select
						id='role_select'
						value={role}
						onChange={(event) => setRole(event.target.value)}
						fullWidth
						className={classes.select}
					>
						<MenuItem value={"admin"}>{translate("resources.application_members.admin")}</MenuItem>
						<MenuItem value={"editor"}>{translate("resources.application_members.editor")}</MenuItem>
						<MenuItem value={"moderator"}>{translate("resources.application_members.moderator")}</MenuItem>
					</Select>
				</FormControl>
			</form>
			<Button
				className={classes.inviteButton}
				alignIcon='left'
				label={translate("pos.actions.invite") + (mails.length > 0 ? ` (${mails.length})` : "")}
				onClick={() => sendInvitations()}
			></Button>
		</Dialog>
	);
};

export default InvitationDialog;

import React, { useState } from 'react';
import { OAuth2Button } from '@logora/debate.auth.oauth2_button';
import { Confirm, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { Button, Stack, Switch, Typography } from '@mui/material';
import { Integration, IntegrationStatusEnum } from './integration';
import theme from '../../theme';

interface IntegrationActionsProps {
    integration: Integration | { status: 'not_connected' };
    authUrl: string;
    provider: string;
    redirectUri: string;
    clientId: string;
    scope: string;
    state?: string;
    accessType?: string;
    forceAuth?: boolean;
}

const formatter = new Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric'
});

export const IntegrationActions = ({
    integration,
    authUrl,
    provider,
    redirectUri,
    state,
    clientId,
    scope,
    accessType,
    forceAuth
}: IntegrationActionsProps) => {
    const translate = useTranslate();
    const [isConfirmSuspendOpen, setIsConfirmSuspendOpen] = useState(false);
    const [isConfirmResumeOpen, setIsConfirmResumeOpen] = useState(false);

    const refresh = useRefresh();
    const [update, { isLoading }] = useUpdate(
        'integrations',
        {},
        { onSuccess: () => refresh() }
    );

    const handleSuspend = () => {
        setIsConfirmSuspendOpen(true);
    };

    const handleResume = () => {
        setIsConfirmResumeOpen(true);
    };

    const handleConfirmSuspend = async () => {
        if (integration.status === 'not_connected') return;

        await update('integrations', {
            id: integration.id,
            data: {
                status: IntegrationStatusEnum.suspended
            }
        });
        setIsConfirmSuspendOpen(false);
    };

    const handleConfirmResume = async () => {
        if (integration.status === 'not_connected') return;

        await update('integrations', {
            id: integration.id,
            data: {
                status: IntegrationStatusEnum.active
            }
        });
        setIsConfirmResumeOpen(false);
    };

    const handleCloseSuspend = () => {
        setIsConfirmSuspendOpen(false);
    };

    const handleCloseResume = () => {
        setIsConfirmResumeOpen(false);
    };

    return (
        <>
            <Stack
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: '#F5F1EF', borderRadius: 3 }}
                paddingInline={1}
                marginBottom={1}
            >
                <Stack direction="row" alignItems="center">
                    <Switch
                        checked={integration.status === 'active'}
                        onChange={
                            integration.status === 'active'
                                ? handleSuspend
                                : handleResume
                        }
                        disabled={integration.status === 'not_connected'}
                    />

                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        color={
                            integration.status === 'active'
                                ? theme.palette.primary.main
                                : theme.palette.grey['600']
                        }
                    >
                        {integration.status === 'active' ? 'ON' : 'OFF'}
                    </Typography>
                </Stack>

                {integration.status === 'active' && (
                    <>
                        {integration.next_retrieval_at && (
                            <Typography
                                fontSize={11}
                                whiteSpace="nowrap"
                                color={theme.palette.grey['600']}
                            >
                                {translate(
                                    'resources.integrations.fields.next_retrieval_date'
                                )}
                                :{' '}
                                {new Date(integration.next_retrieval_at) >
                                new Date()
                                    ? formatter.format(
                                          new Date(
                                              integration.next_retrieval_at
                                          )
                                      )
                                    : '-'}
                            </Typography>
                        )}

                        <OAuth2Button
                            popup={false}
                            authDialogUrl={authUrl}
                            provider={provider}
                            redirectUri={redirectUri}
                            state={state}
                            clientId={clientId}
                            scope={scope}
                            responseType="code"
                            accessType={accessType}
                            forceAuth={forceAuth}
                        >
                            <Typography fontSize={8} fontWeight={600}>
                                {translate(
                                    'pos.social_networks.actions.edit_access'
                                )}
                            </Typography>
                        </OAuth2Button>
                    </>
                )}

                {integration.status === 'not_connected' && (
                    <OAuth2Button
                        popup={false}
                        authDialogUrl={authUrl}
                        provider={provider}
                        redirectUri={redirectUri}
                        state={state}
                        clientId={clientId}
                        scope={scope}
                        responseType="code"
                        accessType={accessType}
                        forceAuth={forceAuth}
                    >
                        <Button variant="contained" sx={{ width: '100%' }}>
                            <Typography fontSize={8} fontWeight={600}>
                                {translate(
                                    'pos.social_networks.actions.connect'
                                )}
                            </Typography>
                        </Button>
                    </OAuth2Button>
                )}

                {integration.status === 'suspended' && (
                    <Typography
                        fontSize={12}
                        whiteSpace="nowrap"
                        color={theme.palette.grey['600']}
                    >
                        {translate('pos.social_networks.actions.reactivate')}
                    </Typography>
                )}
            </Stack>

            <Confirm
                isOpen={isConfirmSuspendOpen}
                loading={isLoading}
                title={translate(
                    'resources.integrations.confirm.suspend.message',
                    {
                        provider
                    }
                )}
                content={translate(
                    'resources.integrations.confirm.suspend.description',
                    {
                        provider
                    }
                )}
                onConfirm={handleConfirmSuspend}
                onClose={handleCloseSuspend}
            />

            <Confirm
                isOpen={isConfirmResumeOpen}
                loading={isLoading}
                title={translate(
                    'resources.integrations.confirm.resume.message',
                    {
                        provider
                    }
                )}
                content={translate(
                    'resources.integrations.confirm.resume.description',
                    {
                        provider
                    }
                )}
                onConfirm={handleConfirmResume}
                onClose={handleCloseResume}
            />
        </>
    );
};

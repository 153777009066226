import React from 'react';
import { useSettingsContext } from './SettingsProvider';

export const ActiveModulesContext = React.createContext<
    Record<string, boolean>
>({
    isDebateSpaceActive: true,
    isParliamentActive: false,
    isSocialModerationActive: false,
    isSourceActive: false
});

export const ActiveModulesContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const settingsContext = useSettingsContext();
    const modules = settingsContext?.settings?.modules
        ? (settingsContext?.settings?.modules as Record<string, boolean>)
        : {};

    return (
        <ActiveModulesContext.Provider
            value={{
                isDebateSpaceActive: modules.debateSpace !== false,
                isParliamentActive: modules.parliament === true,
                isSocialModerationActive: modules.socialModeration === true,
                isSourceActive: modules.sources !== false
            }}
        >
            {children}
        </ActiveModulesContext.Provider>
    );
};

export const useActiveModulesContext = () =>
    React.useContext(ActiveModulesContext);

import React from "react";
import { Filter, SearchInput, DateInput, TextInput, useTranslate, SelectInput, BooleanInput } from "react-admin";
import AutocompleteReferenceInput from "../../inputs/AutocompleteReferenceInput";

const DebateFilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props} sx={{ marginBottom: "10px" }}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<DateInput source='created_at_gte' label={translate("resources.filters.from_date")} alwaysOn variant={"outlined"} />
			<DateInput source='created_at_lte' label={translate("resources.filters.to_date")} alwaysOn variant={"outlined"} />
			<SelectInput
				style={{ marginBottom: "0" }}
				source='has_content_tags'
				resettable
				choices={[
					{ id: "true", name: translate("resources.groups.fields.has_content_tags") },
					{ id: "false", name: translate("resources.groups.fields.no_content_tags") },
				]}
				alwaysOn
				variant={"outlined"}
				margin="none"
			/>
			<AutocompleteReferenceInput
				source="content_tags_id" 
				reference="sources_tags" 
				sort={{ field: 'created_at', order: 'DESC' }}
				label={translate('resources.groups.fields.content_tags_id')} 
				optionText={choice => `${choice.name}`} 
				optionValue={"id"}
				alwaysOn
			/>
			<TextInput source='id' label="ID" alwaysOn variant={"outlined"} sx={{ maxWidth: "80px" }} />
			<BooleanInput sx={{ padding: "7px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", marginTop: "8px" }} source="is_active" alwaysOn label={translate("resources.groups.fields.is_active")} />
			<BooleanInput sx={{ padding: "7px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", marginTop: "8px" }} source="is_published" alwaysOn label={translate("resources.groups.fields.published")} />
		</Filter>
	);
};

export default DebateFilter;

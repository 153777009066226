import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import {
	TextField,
	RichTextField,
	DateField,
	SimpleForm,
	EditBase,
	FunctionField,
	Labeled,
	NumberInput,
	useRecordContext,
	useTranslate
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Grid, Stack, Link, Chip, Typography, IconButton } from "@mui/material";
import { UserReferenceField } from "../../users";
import { getLocaleIcon, getLocaleName } from '@logora/debate.util.lang_emojis';
import CloseIcon from "@mui/icons-material/Close";
import ArgumentEditToolbar from "./ArgumentEditToolbar";
import RecordLinkField from "../../inputs/RecordLinkField";

const useStyles = makeStyles((theme) => ({
	textField: {
		background: "rgba(0, 0, 0, 0.08)",
		borderRadius: "4px",
		padding: "16.5px 14px",
		border: "1px solid #ccc",
		marginRight: "15px",
		marginBottom: "10px",
		width: "500px"
	},
	reply: {
		marginRight: "5px",
		fontSize: "0.875rem"
	},
	accepted: {
		background: "#4caf5022",
		color: "#4caf50"
	},
	rejected: {
		background: "#f4433622",
		color: "#f44336"
	},
	pending: {
		background: "#ff990022",
		color: "#ff9900"
	}
}));

const ArgumentEdit = ({ id, onCancel }) => {
	const [score, setScore] = useState();
	const classes = useStyles();
	const translate = useTranslate();
	const location = useLocation();

	return (
		<EditBase id={id}>
			<Stack direction="row" p={2}>
				<Typography variant="h6" flex="1">
					{translate("resources.messages.labels.detail")}
				</Typography>
				<IconButton onClick={onCancel} size="small">
					<CloseIcon />
				</IconButton>
			</Stack>
			<SimpleForm
				resource='messages'
				sx={{ maxWidth: '600px !important' }}
				toolbar={<ArgumentEditToolbar score={score} />}
			>
				<Grid container rowSpacing={1} mb={1}>
					<Grid item xs={12}>
						<FunctionField
							render={(record) =>
								record?.moderation_entry?.status === "rejected"
									? <Chip className={classes.rejected} label={translate("resources.messages.status.rejected")} />
									: record.moderation_entry?.status === "accepted"
										? <Chip className={classes.accepted} label={translate("resources.messages.status.accepted")} />
										: <Chip className={classes.pending} label={translate("resources.messages.status.pending")} />
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<FunctionField
							render={(record) =>
								record?.moderation_entry?.status === "rejected" && record?.moderation_entry?.moderation_reason
								&& <Chip label={translate(`resources.moderation_entries.select.${record.moderation_entry?.moderation_reason.toLowerCase()}`)} />
							}
						/>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<TextField label='ID' source='id' />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<UserReferenceField
								targetId="author.hash_id"
								source="author"
							/>
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<GroupField
								label={translate('pos.main.source')}
							/>
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled className={classes.textField}>
							<RichTextField source='content' variant="outlined" />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<NumberInput
							source='score'
							label={translate("resources.messages.fields.score")}
							step={1}
							onChange={(e) => setScore(e.target.value)}
							min={0}
							max={100}
							helperText={translate("resources.messages.labels.score_update")}
						/>
					</Grid>
					<Grid item xs={12}>
						<NumberInput
							disabled
                            source="moderation_entry.moderation_score"
                            label={translate('resources.messages.fields.moderation_score')}
                            transform={v => Math.floor(100 - v) / 100}
                            options={{ style: "percent" }}
                        />
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<LanguageField />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<DateField source='created_at' showTime={true} />
						</Labeled>
					</Grid>
					<FunctionField
						render={(record) =>
							record?.is_reply &&
							<Grid item xs={12}>
								<span className={classes.reply}>{translate("resources.messages.labels.reply_to")}</span>
								<RecordLinkField
									targetId={"reply_to_id"}
									resource={location.pathname.includes("messages") ? "messages" : "comments"}
								>
									{translate('resources.reports.labels.see_message')}
								</RecordLinkField>
							</Grid>
						}
					/>
					<Grid item xs={12}>
						<SourcesField />
					</Grid>
				</Grid>
			</SimpleForm>
		</EditBase>
	);
};

const LanguageField = () => {
	const record = useRecordContext();
	console.log(record)
	if (record?.language) {
		return (
			<p>{ `${getLocaleName(record.language)} ${getLocaleIcon(record.language)}` }</p>
		)
	}
}

const SourcesField = () => {
	const record = useRecordContext();
	const translate = useTranslate();

	if (record?.sources?.length > 0) {
		return (
			<>
				<Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }} variant="caption">{translate("resources.messages.fields.sources")}</Typography>
				{record.sources.map((source) => (
					<a
						style={{
							width: "100%",
							display: "block",
							color: "#5B7FF1",
							"margin-bottom": "15px",
							"textDecoration": "none",
						}}
						href={source.source_url}
						target='_blank'
						rel='noopener noreferrer'
					>
						{source.publisher} - {source.title}
					</a>
				))}
			</>
		);
	} else {
		return <span></span>;
	}
};

const GroupField = () => {
	const record = useRecordContext();
	if (record?.group_type === "Source") {
		return (
			record.group.source_url ?
				<Link href={record.group.source_url} target='_blank' rel={"noopener noreferrer"} style={{ "textDecoration": "none" }}>
					{record.group_name}
				</Link>
			:
				record.group_name
		);
	} else {
		return <RecordLinkField resource={"groups"} targetId="group.slug" source="group_name" />;
	}
}

export default ArgumentEdit;
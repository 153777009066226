export const customCreatableSelectStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: 56, 
        padding: '0 4px',
        borderRadius: 4,
        borderColor: '#0000003b',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#343a40'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#bbb',
        marginLeft: "0",
        fontStyle: "italic"
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#dee2e6'
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#f5f5f5' : (state.isFocused ? '#e9ecef' : '#fff'),
        color: '#495057',
        '&:active': {
            backgroundColor: state.isSelected ? '#dcdcdc' : '#f5f5f5'
        }
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        borderRadius: 4,
        boxShadow: '0 4px 11px rgba(0, 0, 0, 0.1)'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black'
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ECEFF1',
        padding: '4px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'black',
        padding: "0 3px 0 6px"
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#ECEFF1',
        cursor: 'pointer',
        borderRadius: "100%",
        backgroundColor: '#B0BEC5',
        marginLeft: "4px",
        height: "20px",
        width: "20px",
        padding: "3px",
        '&:hover': {
            borderRadius: "100%",
            backgroundColor: '#B0BEC5',
            color: '#ECEFF1',
        }
    })
};

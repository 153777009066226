import React from 'react';
import get from 'lodash/get';
import { Link, useRecordContext } from 'react-admin';

const RecordLinkField = ({ resource, targetId, source, children }) => {
    const record = useRecordContext();
    const slug = get(record, targetId);

    return (
        record ? (
            <Link to={`/${resource}/${slug}`} onClick={e => e.stopPropagation()}>
                <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                    { children || get(record, source) }
                </div>
            </Link >
        ) : null
    );
};

export default RecordLinkField;
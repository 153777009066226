import React from 'react';
import { useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import httpClient from '../httpClient';
import { CircularProgress, Stack } from '@mui/material';
import { providerIcons } from './utils/providerIcons';

export const AuthPage = () => {
    const { provider } = useParams();
    const [queryParams] = useSearchParams();
    const code = queryParams.get('code');
    const error = queryParams.get('error');

    useEffect(() => {
        if (!provider || !code || error) {
            window.location.replace('/#/');
            return;
        }

        const url = new URL(
            `/social_moderator/integrations/${provider}`,
            process.env.REACT_APP_SOCIAL_MODERATOR_URL as string
        );

        url.searchParams.append('code', code);

        httpClient(url.toString(), { method: 'POST' })
            .then((res) => console.log(res))
            .catch((e) => console.error(e))
            .finally(() => window.location.replace('/#/'));
    }, []);

    if (!provider) return null;

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            gap={5}
        >
            {providerIcons[provider]}
            <CircularProgress />
        </Stack>
    );
};

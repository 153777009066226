import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Show } from '../components/show/Show';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box as BoxMui, Tab, Tabs } from '@mui/material';
import { ActeLegislatifDetails } from './DossierTexte/ActeLegislatifDetails';
import { useDataProvider, useRedirect } from 'react-admin';
import { Box } from '@logora/parliament.ui.box';
import { Timeline } from './Timeline/Timeline';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { ALLOWED_CODE_ACTES } from './actesDefinitions';
import styles from "./DossierShow.module.scss";

const DossierDetails = ({ resource }) => {
    const redirectTo = useRedirect();
    const dataProvider = useDataProvider();
    const [selectedTab, setSelectedTab] = useState(0);
    const [actesLegislatifs, setActesLegislatifs] = useState([]);
    const [milestones, setMilestones] = useState([]);
    const [document, setDocument] = useState(null);
    const [searchParams] = useSearchParams();
    const [currentActe, setCurrentActe] = useState(null);
    const [indexCurrentActe, setIndexSelectedActe] = useState(0);
    const [selectedActe, setSelectedActe] = useState(null);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const getCurrentActe = (actesLegislatifs) => {
        const now = Date.now();
        let lastActe = null;

        for (let acte of actesLegislatifs) {
            if (acte.dateActe === null) {
                continue
            }

            if (new Date(acte.dateActe) > now) {
                break
            }
            lastActe = acte
        }
        return lastActe
    }

    const fetchDocument = useCallback(async (uid) => {
        try {
            const { data } = await dataProvider.getOne('documents', {
                id: uid,
                include: ['alineas', 'subdivisions'],
            });

            setDocument(data);
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    }, [dataProvider]);

    const fetchActesLegislatifs = useCallback(async () => {
        try {
            const { data } = await dataProvider.getList('actesLegislatifs', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'createdAt', order: 'DESC' },
                filter: { dossierRefUid: resource.uid },
            });

            const filteredActesLegislatifs = data.filter(a => Object.keys(ALLOWED_CODE_ACTES).includes(a.codeActe))
                .sort((a, b) => ALLOWED_CODE_ACTES[a.codeActe]?.order - ALLOWED_CODE_ACTES[b.codeActe]?.order)
            setActesLegislatifs(filteredActesLegislatifs)

            const currentActeUid = searchParams.get("acteLegislatif")
            let acteLegislatif = null
            if (currentActeUid) {
                acteLegislatif = filteredActesLegislatifs.find((acte) => acte.uid === currentActeUid);
            } else {
                acteLegislatif = getCurrentActe(filteredActesLegislatifs);
            }

            setCurrentActe(getCurrentActe(filteredActesLegislatifs))
            setSelectedActe(acteLegislatif)
            setIndexSelectedActe(filteredActesLegislatifs.findIndex((acte) => acte.uid === acteLegislatif.uid))
        } catch (error) {
            console.error('Error fetching actesLegislatifs:', error);
        }
    }, [dataProvider, resource.uid, getCurrentActe, searchParams]);

    const handleChangeActe = (acteLegislatifUid) => {
        if (acteLegislatifUid) {
            const acteLegislatif = actesLegislatifs.find((acte) => acte.uid === acteLegislatifUid);

            setSelectedActe(acteLegislatif);
            setIndexSelectedActe(actesLegislatifs.indexOf(acteLegislatif))
        }
    }

    useEffect(() => {
        fetchActesLegislatifs();
    }, [resource.uid]);

    useEffect(() => {
        if (selectedActe?.documentRefUid) {
            console.log(selectedActe?.documentRefUid)
            fetchDocument(selectedActe?.documentRefUid)
        } else {
            setDocument(null)
        }
    }, [selectedActe])

    useEffect(() => {
        const newMilestones = []

        const mainActes = actesLegislatifs?.filter(f => !ALLOWED_CODE_ACTES[f.codeActe]?.parent)
            .filter(f => !ALLOWED_CODE_ACTES[f.codeActe]?.alwaysShow)
            .reduce((accumulator, current) => {
                if (!current.codeActe.includes("DEPOT") || !accumulator.find((item) => item.codeActe.includes("DEPOT"))) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);

        mainActes?.map((a => {
            const acteDefinition = ALLOWED_CODE_ACTES[a.codeActe]
            newMilestones.push({
                title: acteDefinition.title,
                acteLegislatif: a,
                chambre: a.chambre || acteDefinition.chambre,
                steps: actesLegislatifs.filter(c => ALLOWED_CODE_ACTES[c.codeActe].parent === a.codeActe).map(step => ({
                    title: ALLOWED_CODE_ACTES[step.codeActe].title,
                    acteLegislatif: step,
                }))
            });
            if (acteDefinition.nextStep && !mainActes.find(b => b.codeActe === acteDefinition.nextStep)) {
                const nextStep = ALLOWED_CODE_ACTES[acteDefinition.nextStep]
                newMilestones.push({
                    title: nextStep.title,
                    chambre: nextStep.chambre,
                    steps: []
                });
            }
        }))
        // Prendre tous les étapes montrées à chaque fois et les ajouter au tableau
        const alwaysShownActes = Object.keys(ALLOWED_CODE_ACTES).filter(k => ALLOWED_CODE_ACTES[k].alwaysShow)
        alwaysShownActes.map(code => {
            const acteLegislatif = mainActes.find(a => a.codeActe === code)
            newMilestones.push({
                title: ALLOWED_CODE_ACTES[alwaysShownActes].title,
                ...(acteLegislatif && { acteLegislatif }),
                chambre: acteLegislatif?.chambre || ALLOWED_CODE_ACTES[alwaysShownActes].chambre,
                steps: []
            });
        })

        setMilestones(newMilestones);
    }, [actesLegislatifs]);

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label className={styles.label} text={resource.typeInitiative} />
            </div>
            <div className={styles.title}>
                {resource.titre}
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox
                            firstName={resource.acteurPrincipalRef?.prenom}
                            lastName={resource.acteurPrincipalRef?.nom}
                            imageUrl={resource.acteurPrincipalRef?.urlImage}
                            parliamentGroup={{
                                groupName: resource.acteurPrincipalRef?.groupeParlementaire?.libelle,
                                color: resource.acteurPrincipalRef?.groupeParlementaire?.couleurAssociee
                            }}
                            className={styles.auteur}
                            onClick={() => redirectTo(`/parliament/acteurs/${resource.acteurPrincipalRefUid}`)}
                        />
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Commission" className={styles.boxContainer}>
                        <div className={styles.link} onClick={() => redirectTo(`/parliament/organes/${resource.organeRefUid}`)}>
                            {resource.commissionRef?.libelle}
                        </div>
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Date de mise à jour" className={styles.boxContainer}>
                        <div className={styles.boxBody}>
                            {new Date(resource.dateDernierActe).toLocaleDateString('fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                weekday: 'long',
                            })}
                        </div>
                    </Box>
                </div>
            </div>
            <div>
                <BoxMui className={styles.listNavigation} sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#FFF' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#000',
                            },
                        }}>
                        <Tab label="LE TEXTE" className={styles.tabs} />
                    </Tabs>
                </BoxMui>
                <div sx={{ p: 3 }}>
                    {selectedTab === 0 &&
                        <div className={styles.tab}>
                            <div className={styles.timelineWrapper}>
                                <Timeline
                                    milestones={milestones}
                                    selectedActeUid={selectedActe?.uid}
                                    currentActeUid={currentActe?.uid}
                                    onChangeActe={handleChangeActe}
                                />
                            </div>
                            <div className={styles.navigationButton}>
                                {indexCurrentActe > 0 && (
                                    <button className={styles.previousButton} onClick={() => handleChangeActe(actesLegislatifs[indexCurrentActe - 1].uid)}>
                                        <>
                                            <ChevronLeft fontSize='small' />
                                            <span>Étape précédente</span>
                                        </>
                                    </button>
                                )}
                                {indexCurrentActe < (actesLegislatifs.length - 1) && (
                                    <button className={styles.nextButton} onClick={() => handleChangeActe(actesLegislatifs[indexCurrentActe + 1].uid)}>
                                        <span>Étape suivante</span>
                                        <ChevronRight fontSize='small' />
                                    </button>
                                )}
                            </div>
                            {currentActe &&
                                <ActeLegislatifDetails
                                    acte={selectedActe}
                                    document={document}
                                    pointsOdj={resource?.pointsOdj}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export const DossierShow = () => {
    return (
        <Show
            resource={"dossiers"}
            includes={["acteurPrincipalRef.groupeParlementaire", "commissionRef", "pointsOdj"]}
            backLink={"#/parliament/recherche/dossiers"}
            backText={"Voir tous les dossiers"}
        >
            <DossierDetails />
        </Show >
    );
};

import React from 'react';
import { TextInput, Create, FormTab, NumberInput, TabbedForm, DateInput, Toolbar, SaveButton } from 'react-admin';
import { useTranslate } from 'react-admin';
import { useSettingsContext } from '../../utils/SettingsProvider';
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import MultiSelectInput from '../../inputs/MultiSelectInput';
import ImageUrlInput from '../../inputs/ImageUrlInput';
import LinkArticleInput from '../../inputs/LinkArticleInput';
import { Box, Alert, Stack } from "@mui/material";
import HelperText from '../../utils/HelperText';
import FormSectionTitle from '../../utils/FormSectionTitle';

const ConsultationCreateToolbar = props => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	)
}

const ConsultationCreate = props => {
	const translate = useTranslate();
	const config = useSettingsContext();

	const transformData = (data) => {
		const tagObjects = [];
		if (data.tag_list) { data.tag_list.forEach((tag) => tagObjects.push({ name: tag })) }

		return {
			...(data.origin_image_url && { origin_image_url: data.origin_image_url }),
			...(data.tag_list !== undefined && { tag_objects: JSON.stringify(tagObjects) }),
			...(data.description_url !== undefined && { description_url: data.description_url }),
			...(data.description !== undefined && { description: data.description }),
			...(data.image_copyright !== undefined && { image_copyright: data.image_copyright }),
			...(data.image_alt !== undefined && { image_alt: data.image_alt }),
			...(data.synthesis_description !== undefined && { synthesis_description: data.synthesis_description }),
			...(data.content_uids !== undefined && { content_uids: data.content_uids.join(",") }),
			...(data.vote_goal !== undefined && { vote_goal: data.vote_goal }),
			...(data.ends_at !== undefined && { ends_at: data.ends_at }),
			...(data.title !== undefined && { title: data.title }),
			...(data.language !== undefined && { language: data.language }),
			...(data.tag_id_list !== undefined && { tag_id_list: data.tag_id_list.map(item => item.id).join(',') })
		}
	}

	return (
		<Create title={translate("resources.debate_suggestions.create_title")} {...props} redirect="list" transform={transformData}>
			<TabbedForm toolbar={<ConsultationCreateToolbar />}>
				<FormTab label={translate("resources.consultations.name", { smart_count: 1 })}>
					<FormSectionTitle title={translate("resources.consultations.name", { smart_count: 1 })} />
					<TextInput source="title" fullWidth variant="outlined" required />
					<TextInput source="description" fullWidth variant="outlined" multiline required />
					<TextInput source="description_url" fullWidth variant="outlined" />
					<TextInput source="synthesis_description" fullWidth variant="outlined" multiline />
					<Box display={{ xs: 'block', sm: 'flex' }} mb={0}>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<DateInput source="ends_at" variant="outlined" />
						</Box>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<NumberInput source="vote_goal" variant="outlined" />
						</Box>
					</Box>
					{config?.settings?.translation?.enable === true &&
						<Box mb={1}>
							<LanguageSelectInput label={translate("resources.settings.fields.language")} />
						</Box>
					}
					<FormSectionTitle title={translate('pos.main.consultation_tags')} />
					<MultiSelectInput
						isCreatable
						isAsync
						source='tag_list'
						resource={"tags"}
						format={(v) => v.map((e) => {
							return { label: e.display_name, value: e.name };
						})}
						sort={{ field: "taggings_count", order: "DESC" }}
						label={translate('pos.main.consultation_tags')}
						placeholder={translate("resources.groups.labels.tag_multiselect")}
						helperText={translate("resources.helpers.consultation_tags")}
						noOptionsMessage={translate("resources.groups.labels.no_elements")}
						fullWidth
						required
					/>
					<Box flex={1} mt={2}>
						<Alert severity="info">
							{translate("resources.consultations.labels.consultation_tags_info")}
						</Alert>
					</Box>
					<FormSectionTitle title={"image"} />
					<Box flex={1} mt={2}>
						<ImageUrlInput
							source="origin_image_url"
							initialSource={"image_url"}
							helperText={translate("pos.main.consultation_image_format")}
							errorMessage={translate('resources.consultations.labels.image_error')}
							required
						/>
					</Box>
					<Stack direction={"row"} mt={2}>
						<Box flex={1}>
							<TextInput source="image_copyright" variant="outlined" fullWidth helperText={false} />
							<HelperText text={translate('pos.main.not_required')} />
						</Box>
						<Box flex={1} ml={2}>
							<TextInput source="image_alt" variant="outlined" fullWidth helperText={false} />
							<HelperText text={translate('pos.main.not_required')} />
						</Box>
					</Stack>
					{config?.settings?.modules?.sources !== false &&
						<LinkArticleInput
							tagSource={"tag_id_list"}
							tagInitialSource={"content_tags"}
							tagFormatInitial={(initialValue) => initialValue?.map(e => e.tag)}
						/>
					}
				</FormTab>
			</TabbedForm>
		</Create>
	)
};

export default ConsultationCreate;
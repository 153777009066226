import React from 'react';
import { Box, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { StatCard } from './StatCard';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

export function CommentCountCard({
    fromDate,
    toDate
}: {
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery({
        queryKey: ['comments_count', fromDate, toDate],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/comments_count`
            );

            url.searchParams.append('filter', 'provider');
            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            const response = await httpClient(url);

            return response && (response.json as Record<string, number>);
        }
    });

    const translate = useTranslate();

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                width={450}
                height={300}
                sx={{ marginTop: 2, borderRadius: 5 }}
            />
        );

    if (!data) return null;

    return (
        <Box>
            <Stack direction="row" gap={5} marginTop={2}>
                <StatCard>
                    <CardContent>
                        <Typography variant="h2" fontSize={18} marginBottom={3}>
                            {translate(
                                'resources.statistics.comments_count.section_title'
                            )}
                        </Typography>

                        <Stack direction="row" gap={2}>
                            <PieChart
                                series={[
                                    {
                                        data: Object.entries(data).map(
                                            ([provider, value]) => ({
                                                id: provider,
                                                label: provider,
                                                value: value
                                            })
                                        ),
                                        innerRadius: 70,
                                        highlightScope: {
                                            fade: 'global',
                                            highlight: 'item'
                                        }
                                    }
                                ]}
                                width={400}
                                height={200}
                                slotProps={{
                                    legend: {
                                        itemMarkWidth: 10,
                                        itemMarkHeight: 10
                                    }
                                }}
                            />
                        </Stack>
                    </CardContent>
                </StatCard>
            </Stack>
        </Box>
    );
}

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { 
    useUpdate, 
    useNotify, 
    useRecordContext, 
    useRedirect, 
    useRefresh, 
    useTranslate 
} from "react-admin";

const useStyles = () => ({
    button: {
        color: "#5B7FF1"
    }
});

const ArgumentSelectButton = ({ 
    currentRecord, 
    currentResource, 
    noRedirect = false, 
    onSelect 
}) => {
    const notify = useNotify();
    const contextRecord = useRecordContext();
    const record = currentRecord || contextRecord;
    const redirectTo = useRedirect();
    const translate = useTranslate();
    const refresh = useRefresh();
    const classes = useStyles();

    const [isSelected, setIsSelected] = useState(record?.score == 99);

	useEffect(() => {
		setIsSelected(record?.score == 99)
	}, [record])

    const [approve, { isLoading }] = useUpdate(
        "messages",
        {
            id: record?.id,
            data: { score: isSelected ? 50 : 99, manual_score: true },
            previousData: record,
        },
        {
            onSuccess: () => {
                refresh();
				setIsSelected(!isSelected);
                if (onSelect) onSelect();
                notify(
                    translate(`resources.${currentResource || "messages"}.notifications.${isSelected ? "unselect_success" : "select_success"}`), 
                    { type: "info" }
                );
                if (!noRedirect) {
                    redirectTo(currentResource ? `/${currentResource}` : "/messages");
                }
            },
            onFailure: () => {
                notify(
                    translate(`resources.${currentResource || "messages"}.notifications.select_failure`),
                    { type: "warning" }
                );
            },
        }
    );

    return (
        <Button 
            variant='outlined' 
            className={classes.button} 
            size='small' 
            onClick={() => approve()} 
            disabled={isLoading}
        >
            {isSelected
                ? translate("pos.actions.unselect_argument") 
                : translate("pos.actions.select_argument")}
        </Button>
    );
};

export default ArgumentSelectButton;
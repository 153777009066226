import React, { useState } from "react";
import {
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
  TabbedForm,
  TabbedFormTabs,
  FormTab,
  Labeled,
  useRecordContext,
  useTranslate,
  NumberInput,
} from "react-admin";
import { Box, Alert, Typography, FormLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { JsonField, JsonInput } from "react-admin-json-view";
import ColorInput from "../inputs/ColorInput";
import ImageUrlInput from "../inputs/ImageUrlInput";
import MultiSelectInput from "../inputs/MultiSelectInput";
import SettingsToolbar from "./SettingsToolbar";
import SettingsTitle from "./SettingsTitle";
import SectionTitle from "../utils/SectionTitle";
import get from "lodash/get";
import HelperText from "../utils/HelperText";
import { AuthSettings } from "./AuthSettings";
import { useSettingsContext } from "../utils/SettingsProvider";

const useListStyles = makeStyles((theme) => ({
  relativeElement: {
    position: "relative",
  },
  flexRow: {
    "& > .ra-input > .MuiBox-root": {
      width: "100% !important",
    },
  },
  alert: {
    width: "100% !important",
    marginBottom: "2em",
  },
  info: {
    width: "max-content !important",
    marginBottom: "1.5em !important",
    marginTop: "0 !important",
  },
  infoTextInput: {
    width: "max-content !important",
    marginBottom: "1.5em !important",
    marginTop: "5px !important",
  },
}));

let availableFonts = [
  { id: "Montserrat", name: "Montserrat" },
  { id: "Roboto", name: "Roboto" },
  { id: "Poppins", name: "Poppins" },
  { id: "Open Sans", name: "Open Sans" },
  { id: "Lato", name: "Lato" },
  { id: "Titillium", name: "Titillium" },
  { id: "Oswald", name: "Oswald" },
  { id: "Crimson Pro", name: "Crimson Pro" },
  { id: "Libre Franklin", name: "Libre Franklin" },
  { id: "Work Sans", name: "Work Sans" },
  { id: "Raleway", name: "Raleway" },
  { id: "Source Sans Pro", name: "Source Sans Pro" },
  { id: "PT Sans", name: "PT Sans" },
  { id: "Lora", name: "Lora" },
  { id: "Nunito Sans", name: "Nunito Sans" },
  { id: "Kadwa", name: "Kadwa" },
  { id: "Heebo", name: "Heebo" },
  { id: "Noto Serif", name: "Noto Serif" },
  { id: "Jaldi", name: "Jaldi" },
];

const fontFormatter = (font) => {
  if (
    availableFonts.filter((currentFont) => currentFont.id === font).length === 0
  ) {
    availableFonts.push({ id: font, name: font });
  }
  return font;
};

const SettingsEdit = () => {
  const [isChecked, setIsChecked] = useState(undefined);
  const [commentsChecked, setCommentsChecked] = useState(undefined);
  const classes = useListStyles();
  const translate = useTranslate();
  const settingsContext = useSettingsContext();

  const isDebateSpaceActive =
    settingsContext?.settings?.modules?.debateSpace !== false;
  const isParliamentActive =
    settingsContext?.settings?.modules?.parliament === true;
  const hasSourceWhiteList = 
    settingsContext?.settings?.actions?.sourceWhiteList === true;

  return (
    <>
      <SectionTitle
        title={translate("resources.settings.section_title")}
        subtitle={translate("resources.settings.section_subtitle")}
      />
      <Edit title={<SettingsTitle />} id={"settings"}>
        <TabbedForm toolbar={<SettingsToolbar />} tabs={<TabbedFormTabs />}>
          <FormTab
            label={translate("resources.settings.tabs.main")}
            contentClassName={classes.flexRow}
          >
            <Typography variant="h5" gutterBottom>
              {translate("resources.settings.section_title")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput variant={"outlined"} source="name" disabled />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.provider_name")}
                  source="provider.name"
                />
              </Box>
            </Box>
            <TextInput
              variant={"outlined"}
              label={translate("resources.settings.fields.provider_url")}
              source="provider.url"
            />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.company_name")}
                  source="provider.companyName"
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.company_address")}
                  source="provider.companyAddress"
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  variant={"outlined"}
                  label={translate(
                    "resources.settings.fields.company_description"
                  )}
                  source="provider.companyDescription"
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  variant={"outlined"}
                  helperText={false}
                  label={translate("resources.settings.fields.language")}
                  source="language"
                  choices={[
                    { id: "fr", name: "Français" },
                    { id: "en", name: "English" },
                    { id: "es", name: "Español" },
                    { id: "de", name: "Deutsch" },
                    { id: "pt", name: "Português" },
                    { id: "pt-BR", name: "Português (Brasil)" },
                    { id: "it", name: "Italiano" },
                    { id: "rm", name: "Rumantsch" },
                  ]}
                />
              </Box>
            </Box>
            <Box flex={1} mt={{ xs: "1em" }}>
              <Labeled
                label={translate("resources.settings.fields.logo_desktop")}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.relativeElement}
                >
                  <ImageUrlInput
                    label={translate("resources.settings.fields.logo_desktop")}
                    source="logo.desktop"
                    tinyImage
                  />
                </Box>
              </Labeled>
            </Box>
            <Box flex={1} mt={{ xs: "2em" }}>
              <Typography variant="h5" gutterBottom>
                {translate("resources.settings.labels.security")}
              </Typography>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  disabled
                  variant={"outlined"}
                  source="api_key"
                  label={translate("resources.settings.fields.api_key")}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  disabled
                  variant={"outlined"}
                  source="api_secret"
                  label={translate("resources.settings.fields.api_secret")}
                />
              </Box>
            </Box>
            <MultiSelectInput
              isCreatable
              isClearable={false}
              source="allowed_domains"
              initialSource="allowed_domains"
              format={(v) =>
                v.map((e) => {
                  return { label: e, value: e };
                })
              }
              label={translate("resources.settings.fields.allowed_domains")}
              placeholder={translate("resources.groups.labels.tag_multiselect")}
              helperText={translate(
                "resources.settings.labels.allowed_domains_helper"
              )}
            />
          </FormTab>
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab
              label={translate("resources.settings.tabs.personnalisation")}
              contentClassName={classes.flexRow}
            >
              <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "1em" }}>
                <Typography variant="h5" gutterBottom>
                  {translate("resources.settings.fields.font_family_header")}
                </Typography>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      variant={"outlined"}
                      helperText={false}
                      label={translate("resources.settings.fields.font_family")}
                      source="theme.fontFamily"
                      format={(value) => fontFormatter(value)}
                      onCreate={() => {
                        const newFontName = prompt("Ajouter une police");
                        const newFont = { id: newFontName, name: newFontName };
                        availableFonts.push(newFont);
                        return newFont;
                      }}
                      choices={availableFonts}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate("resources.settings.fields.font_family_info")}
                    </Alert>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <SelectInput
                      variant={"outlined"}
                      helperText={false}
                      label={translate(
                        "resources.settings.fields.title_font_family"
                      )}
                      source="theme.titleFontFamily"
                      format={(value) => fontFormatter(value)}
                      onCreate={() => {
                        const newFontName = prompt("Ajouter une police");
                        const newFont = { id: newFontName, name: newFontName };
                        availableFonts.push(newFont);
                        return newFont;
                      }}
                      choices={availableFonts}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate(
                        "resources.settings.fields.title_font_family_info"
                      )}
                    </Alert>
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput
                    source="theme.useGoogleFonts"
                    label={translate(
                      "resources.settings.fields.use_google_fonts"
                    )}
                    helperText={false}
                  />
                </Box>
                <Alert severity="info" className={classes.infoTextInput}>
                  {translate("resources.settings.fields.use_google_fonts_info")}
                </Alert>
              </Box>
              <Box flex={1} maxWidth={"350px"}>
                <Typography variant="h5">
                  {translate("resources.settings.labels.colors")}
                </Typography>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate("resources.settings.fields.main_color")}
                    source={"theme.callPrimaryColor"}
                    defaultColor={"#417ec7"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.call_primary_info")}
                </Alert>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate("resources.settings.fields.for_color")}
                    source="theme.forPrimaryColor"
                    defaultColor={"#c24d50"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.first_position_info")}
                </Alert>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate("resources.settings.fields.against_color")}
                    source="theme.againstPrimaryColor"
                    defaultColor={"#7980bb"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.second_position_info")}
                </Alert>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate(
                      "resources.settings.fields.neutral_thesis_color"
                    )}
                    source="theme.thirdPositionColorPrimary"
                    defaultColor={"#9B9B9B"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.neutral_position_info")}
                </Alert>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate(
                      "resources.settings.fields.tag_text_color"
                    )}
                    source="theme.tagTextColor"
                    defaultColor={"#417ec7"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.tag_text_color_info")}
                </Alert>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <ColorInput
                    label={translate(
                      "resources.settings.fields.tag_border_color"
                    )}
                    source="theme.tagBorderColor"
                    defaultColor={"#417ec7"}
                  />
                </Box>
                <Alert severity="info" className={classes.info}>
                  {translate("resources.settings.fields.tag_border_color_info")}
                </Alert>

                <BooleanInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.dark_mode")}
                  source="theme.enableDarkMode"
                  helperText={false}
                />
                <Alert severity="info" className={classes.infoTextInput}>
                  {translate("resources.settings.fields.dark_mode_info")}
                </Alert>
              </Box>
              <Box flex={1} mt={3}>
                <Typography variant="h5" gutterBottom mb={"15px"}>
                  {translate("resources.settings.fields.boxes")}
                </Typography>
                <TextInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.box_border")}
                  source="theme.boxBorder"
                  helperText={false}
                />
                <Alert severity="info" className={classes.infoTextInput}>
                  {translate("resources.settings.fields.box_border_info")}
                </Alert>
                <TextInput
                  variant={"outlined"}
                  label={translate("resources.settings.fields.box_shadow")}
                  source="theme.boxShadow"
                  helperText={false}
                />
                <Alert severity="info" className={classes.infoTextInput}>
                  {translate("resources.settings.fields.box_shadow_info")}
                </Alert>
                <TextInput
                  variant={"outlined"}
                  label={translate(
                    "resources.settings.fields.box_border_radius"
                  )}
                  source="theme.boxBorderRadius"
                  helperText={false}
                />
                <Alert severity="info" className={classes.infoTextInput}>
                  {translate(
                    "resources.settings.fields.box_border_radius_info"
                  )}
                </Alert>
              </Box>
              <Box flex={1} mt={3}>
                <Typography variant="h5" gutterBottom mb={"15px"}>
                  {translate("resources.settings.fields.font_sizes")}
                </Typography>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate("resources.settings.fields.font_size_extra_large")}
                      source="theme.fontSizeExtraLarge"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate("resources.settings.fields.font_size_extra_large_info")}
                    </Alert>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate("resources.settings.fields.font_size_large")}
                      source="theme.fontSizeLarge"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate("resources.settings.fields.font_size_large_info")}
                    </Alert>
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <TextInput
                    variant={"outlined"}
                    label={translate(
                      "resources.settings.fields.font_size_normal"
                    )}
                    source="theme.fontSizeNormal"
                    helperText={false}
                  />
                  <Alert severity="info" className={classes.infoTextInput}>
                    {translate("resources.settings.fields.font_size_normal_info")}
                  </Alert>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate("resources.settings.fields.font_size_small")}
                      source="theme.fontSizeSmall"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate("resources.settings.fields.font_size_small_info")}
                    </Alert>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.font_size_extra_small"
                      )}
                      source="theme.fontSizeExtraSmall"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate(
                        "resources.settings.fields.font_size_extra_small_info"
                      )}
                    </Alert>
                  </Box>
                </Box>
              </Box>
              <Box flex={1}>
                <Typography variant="h5" gutterBottom mb={"15px"}>
                  {translate("resources.settings.fields.font_weights")}
                </Typography>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.font_weight_normal"
                      )}
                      source="theme.fontWeightNormal"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate(
                        "resources.settings.fields.font_weight_normal_info"
                      )}
                    </Alert>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.font_weight_bold"
                      )}
                      source="theme.fontWeightBold"
                      helperText={false}
                    />
                    <Alert severity="info" className={classes.infoTextInput}>
                      {translate("resources.settings.fields.font_weight_bold_info")}
                    </Alert>
                  </Box>
                </Box>
              </Box>
              <Box flex={1}>
                <Typography variant="h5" gutterBottom>
                  {translate("resources.settings.fields.routes")}
                </Typography>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <TextInput
                    variant={"outlined"}
                    label={translate("resources.settings.fields.prefixPath")}
                    source="routes.prefixPath"
                    fullWidth
                  />
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate("resources.settings.fields.indexPath")}
                      source="routes.indexPath"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate("resources.settings.fields.debatePath")}
                      source="routes.debatePath"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <TextInput
                    variant={"outlined"}
                    label={translate("resources.settings.fields.userPath")}
                    source="routes.userPath"
                    fullWidth
                  />
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.consultationIndexPath"
                      )}
                      source="routes.consultationIndexPath"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.consultationPath"
                      )}
                      source="routes.consultationPath"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.suggestionPath"
                      )}
                      source="routes.suggestionPath"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                  <TextInput
                    variant={"outlined"}
                    label={translate(
                      "resources.settings.fields.informationPath"
                    )}
                    source="routes.informationPath"
                    fullWidth
                  />
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "2em" }}>
                    <TextInput
                      variant={"outlined"}
                      label={translate(
                        "resources.settings.fields.commentPath"
                      )}
                      source="routes.commentPath"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </FormTab>
          )}
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab label={translate("resources.settings.labels.modules")}>
              <Box flex={1} mb={{ xs: "2em" }}>
                <Typography variant="h5">
                  {translate("resources.settings.labels.modules")}
                </Typography>
                <Typography variant="caption">
                  {translate("resources.settings.labels.toggle_modules")}
                </Typography>
              </Box>
              <Labeled
                label={translate(
                  "resources.settings.fields.modules_consultation"
                )}
              >
                <BooleanInput
                  source="modules.consultation"
                  fullwidth="true"
                  label={translate(
                    "resources.settings.fields.modules_consultation"
                  )}
                  helperText={false}
                />
              </Labeled>
              <Alert severity="info" className={classes.alert}>
                {translate("resources.settings.labels.consultation_info")}
                <br />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://demo.logora.fr/consultation/si-vous-etiez-candidat-quelles-seraient-vos-propositions-pour-ameliorer-la-vie-des-francais"
                >
                  {translate("resources.settings.labels.consultations_example")}
                </a>
              </Alert>
              <Labeled
                label={translate("resources.settings.fields.modules_comments")}
              >
                <BooleanInput
                  source="modules.comments"
                  fullwidth="true"
                  label={translate(
                    "resources.settings.fields.modules_comments"
                  )}
                  onChange={(e) => setCommentsChecked(e.target.checked)}
                  helperText={false}
                />
              </Labeled>
              <CommentsCountInput isChecked={commentsChecked} />
              <Alert severity="info" className={classes.alert}>
                {translate("resources.settings.labels.comments_info")}
                <br />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://demo.logora.fr/synthese_comments.html"
                >
                  {translate("resources.settings.labels.comments_example")}
                </a>
              </Alert>
              <Box
                flex={1}
                mt={1}
                flexDirection={"column"}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Labeled
                  label={translate(
                    "resources.settings.fields.modules_suggestions"
                  )}
                >
                  <BooleanInput
                    source="modules.suggestions.active"
                    defaultValue={true}
                    fullwidth="true"
                    label={translate(
                      "resources.settings.fields.modules_suggestions"
                    )}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    helperText={false}
                  />
                </Labeled>
                <VoteGoalInput isChecked={isChecked} />
                <NumberInput
                  source="modules.suggestions.expiration_delay"
                  defaultValue={30}
                  label={translate(
                    "resources.settings.fields.expiration_delay"
                  )}
                  type="number"
                  helperText={null}
                />
                <NumberInput
                  source="modules.suggestions.minPoints"
                  defaultValue={100}
                  label={translate("resources.settings.fields.min_points")}
                  type="number"
                  helperText={null}
                />
                <Alert severity="info" className={classes.alert}>
                  {translate("resources.settings.labels.suggestions_info")}
                  <br />
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://demo.logora.fr/suggestions"
                  >
                    {translate("resources.settings.labels.suggestions_example")}
                  </a>
                </Alert>
              </Box>
              <Labeled
                label={translate(
                  "resources.settings.fields.modules_context_sources"
                )}
              >
                <BooleanInput
                  source="modules.contextSources"
                  fullwidth="true"
                  label={translate(
                    "resources.settings.fields.modules_context_sources"
                  )}
                  helperText={false}
                />
              </Labeled>
              <Alert severity="info" className={classes.alert}>
                {translate("resources.settings.labels.context_sources_info")}
              </Alert>
              { hasSourceWhiteList &&
                <MultiSelectInput
                  isCreatable
                  isClearable={false}
                  source="allowed_sources"
                  initialSource="allowed_sources"
                  format={(v) =>
                    v.map((e) => {
                      return { label: e, value: e };
                    })
                  }
                  label={translate("resources.settings.fields.allowed_sources")}
                  placeholder={translate("resources.groups.labels.tag_multiselect")}
                  helperText={translate(
                    "resources.settings.labels.allowed_sources_helper"
                  )}
                />
              }
            </FormTab>
          )}
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab
              label={translate("resources.settings.tabs.authentication")}
            >
              <AuthSettings />
            </FormTab>
          )}
          <FormTab label={translate("resources.settings.tabs.moderation")}>
            <Typography variant="caption">
              {translate("resources.settings.labels.moderation")}
            </Typography>
            <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "1em" }}>
              <SelectInput
                variant={"outlined"}
                fullWidth
                label={translate("resources.settings.fields.moderation_type")}
                source="moderation.type"
                choices={[
                  {
                    id: "pre",
                    name: translate(
                      "resources.settings.fields.before_publication"
                    ),
                  },
                  {
                    id: "post",
                    name: translate(
                      "resources.settings.fields.after_publication"
                    ),
                  },
                ]}
                helperText={false}
              />
            </Box>
            <Box flex={1} mb={{ xs: "1em" }}>
              <SelectInput
                variant={"outlined"}
                fullWidth
                label={translate("resources.settings.fields.moderation_method")}
                source="moderation.mode"
                choices={[
                  {
                    id: "manual",
                    name: translate("resources.settings.fields.manual"),
                  },
                  {
                    id: "smart",
                    name: translate("resources.settings.fields.smart"),
                  },
                  { id: "logora_llm", name: "Logora AI" },
                  { id: "provider", name: "Netino" },
                  { id: "perspective", name: "Perspective API" },
                ]}
                helperText={false}
              />
            </Box>
            <Box flex={1} mb={{ xs: "2em" }}>
              <TextInput
                source="moderation.accepted_threshold"
                label={translate(
                  "resources.settings.fields.accepted_threshold"
                )}
                type="number"
                defaultValue={50}
                helperText={"resources.settings.fields.accepted_threshold_info"}
              />
            </Box>
            <Box flex={1} mb={{ xs: "2em" }}>
              <TextInput
                source="moderation.rejected_threshold"
                label={translate(
                  "resources.settings.fields.rejected_threshold"
                )}
                type="number"
                defaultValue={95}
                helperText={"resources.settings.fields.rejected_threshold_info"}
              />
            </Box>
            <Box flex={1} mb={{ width: "100%", xs: "2em" }}>
              <TextInput
                source="moderation.blacklist"
                label={translate("resources.settings.fields.blacklist")}
                multiline
                fullWidth
                helperText={translate(
                  "resources.settings.fields.blacklist_helper"
                )}
                minRows={5}
              />
              <HelperText
                text={translate(
                  "resources.settings.fields.blacklist_field_use"
                )}
              />
            </Box>
          </FormTab>
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab label={translate("resources.settings.tabs.emails")}>
              <Typography variant="caption">
                {translate("resources.settings.labels.emails")}
              </Typography>
              <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "1em" }}>
                <BooleanInput
                  variant={"outlined"}
                  fullWidth
                  label={translate("resources.settings.fields.email")}
                  source="notifications.email"
                  helperText={false}
                />
              </Box>
              <Box flex={1} mb={{ xs: "2em" }}>
                <BooleanInput
                  variant={"outlined"}
                  fullWidth
                  label={translate("resources.settings.fields.newsletter")}
                  source="notifications.newsletter"
                  helperText={false}
                />
              </Box>
              <Typography variant="caption">
                {translate("resources.settings.labels.emails_tracking")}
              </Typography>
              <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "1em" }}>
                <TextInput
                  source="analytics.source_key"
                  label={translate("resources.settings.fields.source_key")}
                  helperText={false}
                  variant={"outlined"}
                />
              </Box>
              <Box flex={1} mb={{ xs: "1em" }}>
                <TextInput
                  source="analytics.medium_key"
                  label={translate("resources.settings.fields.medium_key")}
                  helperText={false}
                  variant={"outlined"}
                />
              </Box>
              <Box flex={1} mb={{ xs: "1em" }}>
                <TextInput
                  source="analytics.campaign_key"
                  label={translate("resources.settings.fields.campaign_key")}
                  helperText={false}
                  variant={"outlined"}
                />
              </Box>
              <Box flex={1} mb={{ xs: "1em" }}>
                <TextInput
                  source="analytics.content_key"
                  label={translate("resources.settings.fields.content_key")}
                  helperText={false}
                  variant={"outlined"}
                />
              </Box>
            </FormTab>
          )}
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab label={translate("resources.settings.tabs.translation")}>
              <Typography variant="caption">
                {translate("resources.settings.labels.translation")}
              </Typography>
              <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "1em" }}>
                <BooleanInput
                  variant={"outlined"}
                  fullWidth
                  label={translate(
                    "resources.settings.fields.enable_translation"
                  )}
                  source="translation.enable"
                />
              </Box>
              <JsonInput
                label={translate(
                  "resources.settings.fields.translation_methods"
                )}
                source="translation.translationMethods"
                jsonString={false}
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: false,
                  displayDataTypes: false,
                }}
              />
            </FormTab>
          )}
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab
              label={translate("resources.settings.tabs.ads")}
              contentClassName={classes.flexRow}
            >
              <Box flex={1} mb={{ xs: "2em" }}>
                <BooleanInput
                  variant={"outlined"}
                  label={translate("resources.settings.labels.authorize_ads")}
                  source="ads.display"
                />
              </Box>
              <Box flex={1} mb={{ xs: "2em" }}>
                <Typography variant="h5" gutterBottom>
                  Google Ad Manager
                </Typography>
                <Box mt={{ xs: "2em" }}>
                  <FormLabel>Article Ad</FormLabel>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        variant={"outlined"}
                        label="Path"
                        source="ads.googleAdManager.article.path"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        variant={"outlined"}
                        label="ID"
                        source="ads.googleAdManager.article.id"
                        fullWidth
                      />
                    </Box>
                    <JsonInput
                      label="Targeting"
                      source="ads.googleAdManager.article.targeting"
                      jsonString={false}
                      reactJsonOptions={{
                        name: null,
                        collapsed: false,
                        enableClipboard: false,
                        displayDataTypes: false,
                      }}
                    />
                  </Box>
                </Box>
                <Box mt={{ xs: "2em" }}>
                  <FormLabel>Main Thread Ad</FormLabel>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        variant={"outlined"}
                        label="Path"
                        source="ads.googleAdManager.thread.path"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        variant={"outlined"}
                        label="ID"
                        source="ads.googleAdManager.thread.id"
                        fullWidth
                      />
                    </Box>
                    <JsonInput
                      label="Targeting"
                      source="ads.googleAdManager.thread.targeting"
                      jsonString={false}
                      reactJsonOptions={{
                        name: null,
                        collapsed: false,
                        enableClipboard: false,
                        displayDataTypes: false,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </FormTab>
          )}
          {(isDebateSpaceActive || isParliamentActive) && (
            <FormTab label={translate("resources.settings.fields.texts")}>
              <Typography variant="caption">
                {translate("resources.settings.labels.texts_translation")}
              </Typography>
              <JsonField
                label={translate("resources.settings.fields.texts")}
                source="text"
                jsonString={false}
                reactJsonOptions={{
                  name: null,
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            </FormTab>
          )}
        </TabbedForm>
      </Edit >
    </>
  );
};

const CommentsCountInput = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const topComments = get(record, "comments.showTopComments", 3);
  return props.isChecked === false ? null : (
    <TextInput
      source="comments.showTopComments"
      label={translate("resources.settings.fields.top_comments")}
      type="number"
      defaultValue={topComments}
      helperText={translate("resources.settings.labels.top_comments_helper")}
    />
  );
};

const VoteGoalInput = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const voteGoal = get(record, "modules.suggestions.vote_goal", 30);
  return (record && record.modules?.suggestions?.active === false) ||
    props.isChecked === false ? null : (
    <TextInput
      sx={{ marginBottom: "15px" }}
      source="modules.suggestions.vote_goal"
      label={translate("resources.settings.fields.vote_goal")}
      type="number"
      defaultValue={voteGoal}
      helperText={translate("resources.settings.labels.vote_goal_helper")}
    />
  );
};

export default SettingsEdit;

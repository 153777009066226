import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    useTranslate,
    FunctionField
} from 'react-admin';
import SectionTitle from '../utils/SectionTitle';
import CustomPagination from '../utils/CustomPagination';
import { ExportIcon } from '.';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  exportButton: {
    color: "#5B7FF1"
  },
})

const ExportList = (props) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <SectionTitle
                title={translate("resources.exports.section_title")}
                subtitle={translate("resources.exports.section_subtitle")}
                bottomMarged
            />
            <List
                title={translate('resources.exports.name', { smart_count: 2 })}
                sort={{ field: 'created_at', order: 'DESC' }}
                actions={null}
                filter={{ countless: true }}
                disableSyncWithLocation
                pagination={<CustomPagination alwaysOn />}
                {...props}
            >
                <Datagrid bulkActionButtons={null}>
                    <TextField source="name" />
                    <DateField source="created_at" label={translate("resources.exports.fields.created_at")} showTime={true} />
                    <TextField source="data_name" />
                    <FunctionField
                        label={translate("resources.exports.fields.status")}
                        render={(record) =>
                            record?.status === "pending"
                            ? translate("resources.exports.fields.pending")
                            : record?.status === "success"
                            ? translate("resources.exports.fields.success")
                            : record?.status === "error"
                            ? translate("resources.exports.fields.error")
                            : null
                        }
                    />
                    <FunctionField
                        label={translate("resources.exports.fields.export_button")}
                        render={(record) =>
                            record.status === "success" ?
                                <a className={classes.exportButton} href={record.file_path}>
                                    <ExportIcon />
                                </a>
                            :
                                null
                        }
                    />
                </Datagrid>
            </List>
        </>
    );
}

export default ExportList;
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FullNameField from '../../inputs/FullNameField';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';
import { useTranslate, useDataProvider } from 'react-admin';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	moderableHeader: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		marginBottom: "15px",
		padding: "15px",
		borderRadius: "6px",
		boxShadow: "none",
		"&::before" : { display: "none" }
	},
}))

const UserDetail = (props) => {
	const [userNotes, setUserNotes] = useState("");
	const [isUpdating, setIsUpdating] = useState();
	const [author, setAuthor] = useState(props.entry.moderable_type == "User" ? props.entry.moderable : props.entry.moderable.author)
	const dataProvider = useDataProvider();
    const classes = useStyles();
	const translate = useTranslate();

	const updateUserNote = async () => {
		if (userNotes != author.moderator_notes) { 
			setIsUpdating(true);
			try {
				const response = await dataProvider.update("users", {
					data: { 
						slug: author.slug,
						moderator_notes: userNotes 
					}
				});
				setTimeout(() => {
					setIsUpdating(false);
				}, 2000);
			} catch (error) { console.log(error) }
		}
	}

	const resetUpdatingState = () => {
		setUserNotes(author.moderator_notes);
		setIsUpdating(undefined);
	}
	
	useEffect(() => {
		resetUpdatingState();
		setAuthor(props.entry.moderable_type == "User" ? props.entry.moderable : props.entry.moderable.author);
	}, [props.entry])

	useEffect(() => {
		setUserNotes(author.moderator_notes);
	}, [author])

	return (
		<Box sx={{ padding: "15px 15px 15px 15px", maxWidth: "600px" }} >
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} alignContent={"center"}>
				<InputLabel sx={{ paddingRight: "1em" }}>{translate("resources.moderation_entries.label.moderator_notes")}</InputLabel>
				<FullNameField user={author} />
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
				<TextField sx={{ marginTop: "5px", background: "rgba(0, 0, 0, 0.08)" }} onChange={(e) => setUserNotes(e.target.value)} value={userNotes || ""} className={classes.textField} variant="outlined" multiline minRows={3} fullWidth  />
				<Button sx={{ padding: "7px", border: "1px solid", marginTop: "5px", height: "max-content", alignSelf: "flex-start" }} onClick={updateUserNote}>
					{isUpdating === true ?
						translate("resources.groups.fields.is_loading")
					: isUpdating === undefined ?
						translate("resources.moderation_entries.label.save")
					: 
						<CheckIcon />
					}
				</Button>
			</Box>
		</Box>
	)
}

export default UserDetail;
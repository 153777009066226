import {
  Button,
  Confirm
} from 'react-admin';
import React, { useState } from 'react';

const ButtonWithConfirm = ({ onConfirm, confirmText, disabled = false, className, children }) => {
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        setOpen(false);
        onConfirm();
    };

    return (
      <>
        <Button 
          className={className} 
          onClick={() => setOpen(true)}
          disabled={disabled}
        >
          {children}
        </Button>
        <Confirm
            isOpen={open}
            content={confirmText}
            onConfirm={handleConfirm}
            onClose={() => setOpen(false)}
        />
      </>
    )
};

export default ButtonWithConfirm;
import React from 'react';
import { Show } from '../components/show';
import { Box } from '@mui/material';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { AssembleeHemicycle } from '@logora/parliament.parliament_chamber.assemble_hemicycle';
import { Label } from '@logora/parliament.ui.label';
import { MultiList } from '../search/MultiList';
import { useDataProvider } from 'react-admin';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { groupesVotantsData, voteData } from '../votes';
import styles from './ScrutinShow.module.scss';
import useFetchScrutinVotes from './useFetchScrutinVotes';
import cloneDeep from 'lodash/cloneDeep';
import cx from 'classnames';

const ScrutinDetails = ({ resource }) => {
    const dataProvider = useDataProvider();
    const { votes, loading: votesLoading, error: votesError } = useFetchScrutinVotes(dataProvider, resource.uid);

    const getCodeClassName = (code) => {
        switch (code) {
            case "rejeté":
                return "rejected";
            case "adopté":
                return "adopted";
            default:
                return "undefined";
        }
    }

    const getPercentages = () => {
        let pour;
        let contre;
        let abstentions;

        pour = Math.ceil(parseInt(resource.pour) / parseInt(resource.nombreVotants) * 100);
        contre = Math.ceil(parseInt(resource.contre) / parseInt(resource.nombreVotants) * 100);
        abstentions = Math.ceil(parseInt(resource.abstentions) / parseInt(resource.nombreVotants) * 100);

        return { pour, contre, abstentions }
    }

    const attributeVoteToHemicyle = (vote) => {
        if (vote.votePosition === "nonVotants") { return; }
        return {
            id: parseInt(vote?.acteurRef?.mandatPrincipal?.placeHemicycle),
            color: vote.positionVote === "pour" ? '#2E7D32' : vote.positionVote === "contre" ? '#D32F2F' : vote.positionVote === "abstention" ? "#455A64" : "#CFD8DC",
            labelBackgroundColor: "#ffffffe0",
            label:
                <Box className={cx(styles.labelHemicycle, `${vote.positionVote === "pour" ? styles.adopted : vote.positionVote === "contre" ? styles.rejected : styles.neutral }`)}>
                    <Label text={vote.positionVote} className={cx(styles.votePosition, `${vote.positionVote === "pour" ? styles.adopted : vote.positionVote === "contre" ? styles.rejected : styles.neutral }`)}/>
                    <MemberBox
                        className={styles.scrutinMemberBox}
                        firstName={vote?.acteurRef?.prenom}
                        lastName={vote?.acteurRef?.nom}
                        imageUrl={vote?.acteurRef?.urlImage}
                        parliamentGroup={{
                            color: vote?.acteurRef?.groupeParlementaire?.couleurAssociee,
                            groupName: vote?.acteurRef?.groupeParlementaire?.libelle
                        }}
                    />
                </Box>,
            position: 'top'
        }
    }

    const getDatasets = () => {
        const votesFilters = voteData.filters.concat([
            { field: "scrutinRefUid", hidden: true, defaultValue: resource.uid, type: 'text', defaultSort: true },
        ])
        const votesDataset = Object.assign(cloneDeep(voteData), { filters: votesFilters })

        const groupesVotantsFilters = groupesVotantsData.filters.concat([
            { field: "scrutinRefUid", hidden: true, defaultValue: resource.uid, type: 'text', defaultSort: true },
        ])
        const groupesVotantsDataset = Object.assign(cloneDeep(groupesVotantsData), { filters: groupesVotantsFilters })

        return {
            'groupesVotants': groupesVotantsDataset,
            'votes': votesDataset,
        }
    }

    const getResourceObject = () => {
        return resource.objet;
    }

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label text={"Scrutin"} />
            </div>
            <div className={styles.scrutinContainer}>
                <p className={styles.titleBox}>{`${resource.libelleTypeVote.charAt(0).toUpperCase() + resource.libelleTypeVote.slice(1)} n° ${resource.numero} sur ${resource.titre}`}</p>
            </div>
            <div className={styles.scrutinContent}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em" width="100%">
                    <Box display="flex" flexDirection={"column"}>
                        <Box display="flex" flexDirection="row" gap="1em">
                            <UiBox className={styles.uiBox} title="Date du vote">
                                {new Date(resource.dateScrutin).toLocaleDateString()}
                            </UiBox>
                            <UiBox className={styles.uiBox} title="Résultat">
                                <Label className={styles[`${getCodeClassName(resource.code)}`]} text={resource.code} />
                            </UiBox>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="1em" marginTop="1em">
                            <UiBox className={styles.uiBox} title="Objet du scrutin">
                                { getResourceObject() }
                            </UiBox>
                            <UiBox className={styles.uiBox} title="Synthèse">
                                <Box display="flex" flexDirection="column">
                                    <span>Nombre de votants : {resource.nombreVotants}</span>
                                    <span>Nombre de suffrages exprimés : {resource.suffragesExprimes}</span>
                                    <span>Nombre de suffrages requis pour adoption : {resource.nbrSuffragesRequis}</span>
                                </Box>
                            </UiBox>
                        </Box>
                    </Box>
                    <UiBox className={styles.uiBox} title="Votes">
                        <AssembleeHemicycle
                            className={styles.svgIcon}
                            highlightedSeats={votes.map(attributeVoteToHemicyle)}
                            legend={
                                [
                                    { label: `Pour (${getPercentages().pour}%)`, color: "#2E7D32" },
                                    { label: `Contre (${getPercentages().contre}%)`, color: "#D32F2F" },
                                    { label: `Abstention (${getPercentages().abstentions}%)`, color: "#455A64"}
                                ]
                            }
                        />
                    </UiBox>
                </Box>
            </div>
            <div className={styles.scrutinLists}>
                <MultiList
                    datasets={getDatasets()}
                    showListSearch={true}
                    navigateOnChange={false}
                    variant={"tab"}
                />
            </div>
        </>
    );
};

export const ScrutinShow = () => {
    return (
        <Show
            resource={'scrutins'}
            includes={["groupesVotants"]}
            backLink={'#/parliament/recherche/scrutins'}
            backText={'Voir tous les scrutins'}
        >
            <ScrutinDetails />
        </Show>
    );
};

import React, { useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import * as ReactColor from 'react-color';
import { makeStyles } from '@mui/styles';
import { useInput } from 'react-admin';

const useStyles = makeStyles(theme => ({
    resetButton: {
        position: "absolute",
        top: '34px',
        left: '90%',
        "&:hover" : {
            color: '#777',
            cursor: "pointer",
        }
    },
    colorInputPopup : {
        position: "absolute",
        zIndex: "2"
    },
    colorInputCover : {
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0, 
        right: 0,
    },
    relativeElement: {
        position: "relative",
    },
}));

const ColorInput = props => {
    const classes = useStyles();
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired
    } = useInput(props);
    
    const [show, setShow] = useState(0);
    
    const Picker = ReactColor["ChromePicker"];
    const resetColor = useCallback(
        (event, defaultColor) => {
            event.preventDefault();
            field.onChange(defaultColor);
        }, [field])

    return (
        <div className={classes.relativeElement}>
            <TextField
              margin="normal"
              onFocus={() => setShow(true)}
              label={props.label}
              error={(isTouched || isSubmitted) && invalid}
              helperText={(isTouched || isSubmitted) && invalid ? error?.message : props.helperText}
              className={props.className}
              required={isRequired}
              source={props.source}
              onChange={field.onChange}
              value={field.value}
              variant={"outlined"}
              fullWidth
            />
            <div className={classes.resetButton} onClick={(event) => resetColor(event, props.defaultColor)}><SettingsBackupRestoreIcon titleAccess="Couleur par défaut"/></div>
            {
                show ? (
                    <div className={classes.colorInputPopup}>
                        <div className={classes.colorInputCover} onClick={() => setShow(false)} />
                        <Picker
                            {...props.options}
                            color={field.value}
                            onChange={color => field.onChange(color.hex)}
                        />
                    </div>
                ) : (
                    null
                )
            }
        </div>
    );
};

export default ColorInput;
import React from 'react';
import { useTranslate } from 'react-admin';
import { useSettingsContext } from '../utils/SettingsProvider';
import { BooleanInput, TextInput, required, maxLength } from 'react-admin';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

const PositionInput = ({ disabled = false }) => {
    const translate = useTranslate();
    const config = useSettingsContext();
    const validateThesis = [required(), maxLength(19)];
    const neutralThesisName = config.settings?.vote?.neutralThesisName || translate("resources.groups.fields.neutral_thesis");

    return (
            <Stack direction={"column"}>
                <Stack direction={"row"} mb={0} mt={3} spacing={1}>
                    <TextInput source="pro_thesis" placeholder={translate('resources.groups.placeholder.pro_thesis')} variant="outlined" required validate={validateThesis} disabled={disabled}/>
                    <TextInput source="against_thesis" variant="outlined" required validate={validateThesis} disabled={disabled} />
                    {config?.settings?.vote?.neutralThesis === true && 
                        <BooleanInput source="has_neutral_thesis" fullwidth="true" label={translate("resources.groups.fields.has_neutral_thesis", { thesis: `"${neutralThesisName}"` })} defaultValue={true}/>
                    }
                </Stack>
                {config?.settings?.vote?.neutralThesis === true && 
                    <Box mb={3}>
                        <Alert severity="info" >
                            {translate("resources.groups.labels.neutral_thesis_info", { thesis: `"${neutralThesisName}"` })}
                        </Alert>
                    </Box>
                }
            </Stack>
    );
};

export default PositionInput;
import React, { useState, useEffect } from "react";
import { useDataProvider, useTranslate } from 'react-admin';
import { makeStyles, withStyles } from '@mui/styles';
import { OAuth2Button } from '@logora/debate.auth.oauth2_button';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const callbackUrl = process.env.REACT_APP_API_AUTH_CALLBACK;

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        padding:"10px 40px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    formInput: {
        margin:"10px 0 !important",
        width: "300px",
        height: "50px"
    },
    submitButton: {
        margin: "20px 0",
        width: "300px",
        height: "50px"
    },
    error: {
        color: "#d32f2f !important",
        paddingTop: "5px"
    },
    oauthButton: {
        padding: "20px 0 30px 0",
        borderRadius: "6px"
    },
    providerImg: {
        width: "50px",
        marginRight: "15px"
    }
}));

const StyledButton = withStyles({
    root: {
        marginTop: "1em !important",
        background: '#417ec7 !important',
        color: "white !important",
        "&:hover": {
            background: "#417ec799 !important"
        }
    },
  })(Button);

const SSOAuth = ({ isLoading, onSubmit }) => {
    const classes = useStyles();
	const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [userEmail, setUserEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [ssoSettings, setSsoSettings] = useState();
    const [error, setError] = useState(false);

    useEffect(() => {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentApplication');
        localStorage.removeItem('currentUserRole');
    }, []);

    const handleSubmit = (event, code = null) => {
        let userParams = {};
        if (event) { event.preventDefault(); }
        if (code) {
            userParams = {
                grant_type: "assertion",
                assertion_type: "oauth2_server_admin",
                sso_provider: ssoSettings?.shortname,
                assertion: code
            }
            onSubmit(userParams);
        }
    }

    const handleDomainCheck = async (event) => {
        event.preventDefault();
        const domain = userEmail.split('@').pop();
        if(userEmail && domain) {
            dataProvider.getSsoSettings(domain).then(response => {
                setSsoSettings(response.data);
                setIsChecked(true);
                setError(false);
            }).catch(error => {
                setError(true);
            });
        }
    }

    return (
        <>
            { isChecked ?
                <div className={classes.form}>
                    { isLoading ? 
                        <CircularProgress />
                    :
                        <>
                            <OAuth2Button 
                                authDialogUrl={ssoSettings?.sso?.authDialogUrl}
                                clientId={ssoSettings?.sso?.clientId}
                                scope={ssoSettings?.sso?.scope}
                                responseType={"code"}
                                redirectUri={callbackUrl}
                                onCode={(code) => handleSubmit(null, code)}
                                onClose={() => null}
                                className={classes.oauthButton}
                            >
                                <div style={{ border: `1px solid ${ssoSettings?.theme?.callPrimaryColor}`, padding: "10px 20px", borderRadius: "6px", backgroundColor: ssoSettings?.theme?.callPrimaryColor, color: "#FFF", cursor: "pointer", display: "flex", alignItems: "center" }}>
                                    <img className={classes.providerImg} src={ssoSettings?.logo.desktop} alt='' />
                                    { translate('pos.login.login_with', { name: ssoSettings?.provider?.name }) }
                                </div>
                            </OAuth2Button>
                        </>
                    }
                    
                </div>
            :
                <form className={classes.form} onSubmit={(event) => handleDomainCheck(event)}>
                    <>
                        <TextField required className={classes.formInput} id="email" type="email" label={translate("pos.login.sso_email")} value={userEmail}
                            onChange={e => setUserEmail(e.target.value)}
                        />
                        <StyledButton type="submit" className={classes.submitButton}>
                            { translate("pos.login.check_sso") }
                        </StyledButton>
                        { error && <p className={classes.error}>{ translate("resources.update_password.notifications.error_sso") }</p> }
                    </>
                </form>
            }
        </>
    )
}

export default SSOAuth;
import React, { useState, useRef } from "react";
import {
	List,
	Datagrid,
	TextField,
	ArrayField,
	DateField,
	ChipField,
	SingleFieldList,
	NumberField,
	useRecordContext,
	TopToolbar,
	CreateButton,
	Button,
	useTranslate,
	useListContext,
	Pagination,
	} from "react-admin";
import { useSettingsContext } from "../../utils/SettingsProvider";
import { makeStyles } from "@mui/styles";
import DebateFilter from "./DebateFilter";
import PinIcon from '@mui/icons-material/PushPin';
import AuthorField from '../../inputs/AuthorField';
import LinkField from "../../inputs/LinkField";
import DebateSuggestions from "./DebateSuggestions";
import SectionTitle from "../../utils/SectionTitle";
import LinkIcon from '@mui/icons-material/Link';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOffAlt';
import ExportButton from "../../utils/ExportButton";

const useStyles = makeStyles((theme) => ({
	starIcon: {
		color: "gold",
	},
	rowCentered: {
		textAlign: "center",
	},
	flexLine: {
		display: "flex",
		flexWrap: "nowrap",
		whiteSpace: "nowrap",
	},
	mainButton: {
		background: "#5B7FF1 !important",
		color: "white !important",
		padding: "10px 15px !important",
		fontSize: "18px !important"
	},
	shareButton: {
		marginLeft: "auto !important",
		alignSelf: "end",
		border: "1px solid #5B7FF1 !important",
		color: "#5B7FF1 !important",
		padding: "10px",
		"& svg": {
			paddingRight: "5px !important"
		},
		"& span": {
			marginRight: "0 !important",
			whiteSpace: "nowrap !important"
		}
	},
}));

const DebateListActions = () => {
	const { filterValues, sort } = useListContext();
	const classes = useStyles();
	return (
		<TopToolbar>
			<CreateButton className={classes.mainButton} />
			<ExportButton dataName={"Group"} sort={sort} filers={filterValues} />
		</TopToolbar>
	);
}

const DebateList = (props) => {
	const translate = useTranslate();
	const settingsContext = useSettingsContext();
	const pageTokens = useRef({});

	return (
		<>
			<DebateSuggestions />
			<SectionTitle
				title={translate("resources.groups.section_title")}
				subtitle={translate("resources.groups.section_subtitle")}
			/>
			<List
				title={translate("resources.groups.name", { smart_count: 2 })}
				filters={<DebateFilter />}
				actions={<DebateListActions />}
				sort={{ field: "created_at", order: "DESC" }}
				filterDefaultValues={{ is_published: true, is_active: true }}
				{...props}
			>
				<Datagrid rowClick='edit' bulkActionButtons={null}>
					<TextField source='identifier' label='ID' />
					<TextField source='name' />
					<DateField source='created_at' showTime={true} />
					<NumberField source='messages_count' />
					<NumberField source='upvotes' />
					<PositionPercentageField label={translate("resources.groups.fields.results")} />
					<LinkField source='direct_url' label={translate("resources.groups.fields.link")} content={translate("pos.main.view_link")} />
					<AuthorField source="group_context.author" label={translate("resources.groups.labels.created_by")} />
					<ArrayField source='content_tags' sortable={false}>
						<SingleFieldList linkType={false}>
							<ChipField variant='outlined' source='tag.display_name' />
						</SingleFieldList>
					</ArrayField>
					{settingsContext?.settings?.synthesis?.defaultGroup === true &&
						<DefaultGroupField source='is_default' label={translate('resources.groups.fields.is_default')} />
					}
					<ShareDebateButton />
				</Datagrid>
			</List>
		</>
	)
};

const PositionPercentageField = () => {
	const record = useRecordContext();
	const classes = useStyles();
	if (record?.group_context) {
		if (record.votes_count && Object.keys(record.votes_count).length > 0) {
			const winningPosition = Math.floor((100 * record.votes_count[record.group_context.positions[0].id])) > Math.floor((100 * record.votes_count[record.group_context.positions[1].id]))
				?
				`${Math.floor((100 * record.votes_count[record.group_context.positions[0].id]) / record.votes_count["total"])} % ${record.group_context.positions[0].name}`
				:
				`${Math.floor((100 * record.votes_count[record.group_context.positions[1].id]) / record.votes_count["total"])} % ${record.group_context.positions[1].name}`

			return (
				<div className={classes.flexLine}>
					<span>{winningPosition}</span>
				</div>
			)
		} else {
			return <p>0 % {record.group_context.positions[0].name}</p>
		}
	} else { return null; }
}

const DefaultGroupField = () => {
	const record = useRecordContext();
	const classes = useStyles();
	if (record?.is_default) {
		return (
			<div className={classes.rowCentered}>
				<PinIcon className={classes.starIcon} />
			</div>
		);
	} else {
		return null;
	}
};

const ShareDebateButton = () => {
	const record = useRecordContext();
	const translate = useTranslate();
	const classes = useStyles();
	const shareUrl = `https://app.logora.fr/share/g/${record.identifier}`;
	const [content, setContent] = useState(translate('pos.main.share'));
	const [icon, setIcon] = useState(<LinkIcon />);

	const shareDebate = (e) => {
		navigator.clipboard.writeText(shareUrl);
		handleContent();
		e.stopPropagation();
	}

	const handleContent = () => {
		setContent(translate('pos.main.copied_alias'));
		setIcon(<ThumbUpIcon />);
		setTimeout(() => {
			setContent(translate('pos.main.share'));
			setIcon(<LinkIcon />);
		}, 2000);
	};

	if (record?.id) {
		return (
			<Button
				className={classes.shareButton}
				onClick={shareDebate}
			>
				<>{icon} {content}</>
			</Button>
		)
	} else {
		return null;
	}
}

export default DebateList;

import React from 'react';
import { makeStyles } from '@mui/styles';
import AvatarField from './AvatarField';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: '0.5em',
    },
}));

const FullNameField = ({ user, size }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            { user?.image_url &&
                <AvatarField src={user.image_url} size={size} className={classes.avatar} />
            }
            { user.full_name }
        </div>
    );
}

export default FullNameField;
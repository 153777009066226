import { formatDate, formatCapitalize, formatActeur, formatChambre } from '../formatters';
import { QuestionIcon } from '@logora/parliament.icons.regular_icons';
import { Label } from "@logora/parliament.ui.label"

export const formatType = (field, item) => {
    let type = "Question au gouvernement"
    if (item.type === "QE"){
        type = "Question écrite";
    } else if (item.type === "QOSD") {
        type = "Question orale sans débat"
    } else if (item.type === "QG") {
        type = "Question au gouvernement"
    }
    return <Label text={type} />
}

const formatDestinataire = (field, item) => {
    if(item[field]) {
        return item[field].libelleAbrege
    }
}

const formatCodeCloture = (field, item) => {
    let codeCloture = null
    if (item.codeCloture === "REP_PUB"){
        codeCloture =  "Réponse reçue";
    } else if (item.codeCloture === "DE") {
        codeCloture = "Déposée"
    } else if (item.codeCloture === "AU") {
        codeCloture = "Retirée"
    } else if (item.codeCloture === "FM") {
        codeCloture = "Caduque"
    } else if (item.codeCloture === "ID") {
        codeCloture = "Transformée"
    } else if (item.codeCloture === "ANSE") {
        codeCloture = "Transformée"
    }

    if(!codeCloture) return null
    return <Label text={codeCloture} />
}

const questionsFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    dateDepot: { label: 'Date', field: 'dateDepot', format: formatDate, sort: "dateDepot", defaultSort: true },
    type: { label: 'Type', field: 'type', format: formatType },
    auteur: { label: 'Auteur', field: 'acteurRef', include: 'acteurRef.groupeParlementaire', format: formatActeur },
    titre: { label: 'Titre', field: 'titre' },
    rubrique: { label: 'Catégorie', field: 'rubrique', format: formatCapitalize },
    destinataire: { label: 'Ministère destinataire', field: 'minIntRef', include: 'minIntRef', format: formatDestinataire },
    statut: { label: 'Statut', field: 'codeCloture', format: formatCodeCloture }
}

const questionsFilters = [
    {
        field: "chambre", label: "Chambre", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    { field: "type", label: "Type", type: 'select', options: [
        { value: null, label: "Tous" },
        { value: "QG", label: "Question au gouvernement" },
        { value: "QOSD", label: "Question orale sans débat" },
        { value: "QE" , label: "Question écrite" },
    ]},
    { field: "codeCloture", label: "Statut", type: 'select', options: [
        { value: null, label: "Tous" },
        { value: "DE", label: "Déposée" },
        { value: "REP_PUB", label: "Réponse reçue" },
        { value: "AU", label: "Retirée" },
        { value: "FM", label: "Caduque" },
        { value: "ID", label: "Transformée" }
    ]},
    { field: "numero", label: 'Numéro', type: 'text' },
    { field: "rubrique", label: 'Catégorie', type: 'text', format: (v) => v?.toLowerCase() },
];

export const questionData = {
    key: "questions",
    name: "Questions",
    filters: questionsFilters,
    filterMapping: { "organeRefUid": "acteurRef_groupeParlementaireUid"},
    fields: questionsFields,
    icon: <QuestionIcon width={20} height={20} />
}
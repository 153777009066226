import React from 'react';
import {
    Button,
    useRedirect,
    useTranslate
} from 'react-admin';
import Add from '@mui/icons-material/Add';

const DebateSuggestionCreateButton = () => {
    const redirectTo = useRedirect();
    const translate = useTranslate();
    return (
        <div style={{ display: 'flex', justifyContent: "flex-end", paddingTop: "10px" }}>
            <Button
                label={translate('pos.actions.suggestion_create')}
                onClick={() => redirectTo('/debate_suggestions/create')}
                style={{ margin: '0', color: "#5B7FF1" }}
            >
                <Add
                    style={{ color: '#5B7FF1' }}
                />
            </Button>
        </div>
    );
};

export default DebateSuggestionCreateButton;
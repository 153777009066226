import React, { useState } from 'react';
import { useDataProvider, useNotify, useTranslate, useStore } from 'react-admin'
import { Link } from 'react-router-dom';
import { Button, SnackbarContent } from "@mui/material";
import { ExportIcon } from '../exports';

const ExportButton = ({ dataName, filters = {}, sort }) => {
    const [isExporting, setIsExporting] = useState(false);
    const [exports, setExports] = useStore("exports", []);
    const notify = useNotify();
	const translate = useTranslate();
    const dataProvider = useDataProvider();

    const startExport = () => {
        setIsExporting(true);
        dataProvider.create('exports', {
            data_name: dataName,
            filter: filters,
            sort: sort
        }).then((res) => {
            const exportId = res.data.id;
            // setExports([...exports, res.data]);
            // checkExportStatus(exportId);
            setIsExporting(false);
            notify(
                <SnackbarContent 
                    autoHideDuration={5000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    action={<Link to={"/exports"} style={{ color: "#417EC7", textDecoration: "none" }}>{translate("resources.exports.fields.see_exports")}</Link>}
                    message={translate("resources.exports.notifications.created")}
                />
            );
        }).catch(() => {
            setIsExporting(false);
            notify(translate("resources.exports.notifications.error"), { type: "error" });
            console.error("ERROR")
        });
    };
      
    // const checkExportStatus = (exportId) => {
    //     const checkStatus = () => {
    //         dataProvider.getOne("exports", { id: exportId })
    //         .then(res => {
    //             if (res.data.status === 'success') {
    //                 setIsExporting(false);
    //                 notify(translate("resources.exports.notifications.success"), { type: "info" });
    //             } else {
    //                 setTimeout(checkStatus, 5000);
    //             }
    //         })
    //         .catch(error => {
    //             setIsExporting(false);
    //             notify(translate("resources.exports.notifications.error"), { type: "error" })
    //         });
    //     };

    //     checkStatus();
    // };

	return (
        <Button
            disabled={isExporting}
            onClick={startExport}
            startIcon={<ExportIcon />}
        >
            {translate("pos.main.export")}
        </Button>
	);
}

export default ExportButton;
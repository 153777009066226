import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, useRedirect, useDataProvider, useNotify, useRefresh, useTranslate } from "react-admin";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Box from '@mui/material/Box'
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DebateSuggestionCreateButton from "./DebateSuggestionCreateButton";
import AuthorField from "../../inputs/AuthorField";
import RadioButtonUncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/CheckBox';
import ButtonWithConfirm from "../../utils/ButtonWithConfirm";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	title: {
		fontSize: "1rem !important",
		color: "#5B7FF1",
	},
	flexRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginBottom: "5px",
		flexWrap: "nowrap",
	},
	actionButtons: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		marginBottom: "5px",
		flexWrap: "nowrap",
	},
	userSuggestionLabel: {
		color: "#5B7FF1",
		maxWidth: "250px",
		whiteSpace: "nowrap",
	},
	uList: {
		padding: 0,
		margin: 0,
	},
	list: {
		listStyle: "none",
		padding: "0",
	},
	filterRow: {
		whiteSpace: "nowrap",
	},
	filterButton: {
		padding: "0 !important",
	},
});

const DebateSuggestions = () => {
	const [debateSuggestions, setDebateSuggestions] = useState();
	const [debateSuggestionsPage, setDebateSuggestionsPage] = useState(0);
	const [debateSuggestionsCount, setDebateSuggestionsCount] = useState(0);
	const [isUserFiltered, setIsUserFiltered] = useState(false);
	const classes = useStyles();
	const dataProvider = useDataProvider();
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirectTo = useRedirect();

	const publishSuggestion = async (suggestion) => {
		const formattedTagList = (JSON.parse(suggestion.tag_list) || []).map(e => e.id).join(",") || "";
		const formattedObjects = JSON.stringify((JSON.parse(suggestion.tag_list) || []).map(e => { delete e.id; return e })) || "";
		await dataProvider.update("debate_suggestions", {
			id: suggestion.id,
			data: {
				name: suggestion.name,
				is_published: true,
				position_list: suggestion.position_list,
				tag_list: formattedTagList,
				tag_objects: formattedObjects
			},
		});
		notify(translate("resources.admin_suggestions.creation_success"), { type: "info" });
		await fetchDebateSuggestions();
	};

	const deleteSuggestion = async (suggestion) => {
		await dataProvider.update("debate_suggestions", {
			id: suggestion.id,
			data: {
				name: suggestion.name,
				position_list: suggestion.position_list,
				is_reviewed: true,
				is_published: false
			},
		});
		notify(translate("resources.admin_suggestions.delete_success"), { type: "info" });
		await fetchDebateSuggestions();
	};

	const editSuggestion = async (slug) => {
		redirectTo(`/debate_suggestions/${slug}`);
	};

	const formatTagChips = (tag_list, type) => { 
		try {
			return JSON.parse(tag_list).map(({ id, ...rest }) =>
				<Chip label={rest.display_name || rest.name} key={id} variant='outlined' />
			)
		} catch (error) {
			console.log("error: ", error);
		}
	}

	const reloadSuggestionsList = useCallback(() => {
		refresh();
	}, [refresh]);

	const fetchDebateSuggestions = useCallback(() => {
		let filters = { is_accepted: true, is_published: false, is_reviewed: false }
		if (isUserFiltered === true) {
			filters["is_admin"] = false;
		}
		return dataProvider.getList("debate_suggestions", {
			sort: { field: "created_at", order: "DESC" },
			pagination: { page: debateSuggestionsPage + 1, perPage: 5 },
			filter: filters
		}).then(response => {
			const debateSuggestions = response.data;
			setDebateSuggestions(debateSuggestions);
			setDebateSuggestionsCount(response.total)
			reloadSuggestionsList();
		}).catch(error => {});
	}, [dataProvider, debateSuggestionsPage, reloadSuggestionsList, isUserFiltered]);

	useEffect(() => {
		fetchDebateSuggestions();
	}, [fetchDebateSuggestions, isUserFiltered]);

	return (
		<>
			<div className={classes.flexRow}>
				<Typography variant='h6' className={classes.title} component='div'>
					{translate("resources.admin_suggestions.suggested_by_logora")}
				</Typography>
			</div>
			<TableContainer component={Paper} className={classes.table}>
				<Table className={classes.table} size='small' aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell>{translate("resources.groups.fields.name")}</TableCell>
							<TableCell align='left'>{translate("resources.groups.fields.content_tags")}</TableCell>
							<TableCell align='left'>{translate("resources.groups.fields.content_sources")}</TableCell>
							<TableCell align='left'>{translate("resources.groups.labels.created_by")}</TableCell>
							<TableCell align='left'></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{debateSuggestions && debateSuggestions.length > 0 &&
							debateSuggestions.map((suggestion) => (
								<TableRow key={suggestion.id}>
									<TableCell component='th' scope='row'>
										{suggestion.name}
									</TableCell>
									<TableCell align='left'>
										{suggestion.tag_list && formatTagChips(suggestion.tag_list)}
									</TableCell>
									<TableCell align='left'>
										<ul className={classes.uList}>
											{suggestion.content_sources?.length > 0 &&
												<li className={classes.list}>
													<Chip label={`${suggestion.content_sources.length} ${translate("resources.debate_suggestions.labels.content_sources")}`} variant="outlined" />
												</li>
											}
										</ul>
									</TableCell>
									<TableCell align='left'>
										<div className={classes.userSuggestionLabel}>
											<AuthorField record={suggestion} source={'author'} />
										</div>
									</TableCell>
									<TableCell align='right'>
										<div className={classes.actionButtons}>
											<ButtonWithConfirm
												confirmText={translate("pos.actions.confirm_create")}
												onConfirm={() => publishSuggestion(suggestion)}
											>
												<Box display={"flex"} flexDirection={"row"} color={"#4CAF50"} alignItems={"center"} fontSize={"13px !important"}>
													<AddIcon sx={{ marginRight: "10px", fontSize: "16px" }}/> {translate("pos.actions.create_debate")}
												</Box>
											</ButtonWithConfirm>
											<Button
												label={""}
												onClick={() => editSuggestion(suggestion.id)}
												style={{ color: "#5B7FF1" }}
												alignIcon="right"
											>
												<EditIcon />
											</Button>
											<ButtonWithConfirm
												confirmText={translate("pos.actions.confirm_delete")}
												onConfirm={() => deleteSuggestion(suggestion)}
											>
												<DeleteIcon style={{ color: "#f44336" }} />
											</ButtonWithConfirm>
										</div>
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
					<TableFooter>
						<TableRow align='left' className={classes.filterRow}>
							<TableCell align='left'>
								<Button
									label={translate("resources.debate_suggestions.labels.user_filter")}
									onClick={() => setIsUserFiltered(!isUserFiltered)}
									style={{ color: "#5B7FF1" }}
									alignIcon="left"
									className={classes.filterButton}
								>
									{isUserFiltered ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
								</Button>
							</TableCell>
							<TablePagination 
								page={debateSuggestionsPage} 
								count={debateSuggestionsCount} 
								labelRowsPerPage={translate("pos.main.rows_per_page")} 
								rowsPerPage={5} 
								rowsPerPageOptions={[]}
								onPageChange={(event, page) => { setDebateSuggestionsPage(page) }}
								labelDisplayedRows={
									({ from, to, count }) => {
									return '' + from + '-' + to + ` ${translate("pos.main.from_to_pagination")} ` + count
									}
								}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<DebateSuggestionCreateButton />
		</>
	)
};

export default DebateSuggestions;

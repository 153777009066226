import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#5B7FF1",
        },
        secondary: {
            main: "#5B7FF1",
        },
    },
    components: {
        // ... 
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        borderRadius: "0 6px 6px 0",
                    },
                    '&.RaMenuItemLink-active': {
                        color: "#5B7FF1 !important",
                        backgroundColor: "#5B7FF122 !important",
                        borderRadius: "0 6px 6px 0",
                        '& .RaMenuItemLink-icon': {
                            color: '#5B7FF1 !important',
                        },
                    },
                },
            },

       },
    },
});

export default theme;
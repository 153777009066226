import React from "react";
import { Filter, SearchInput, DateInput, TextInput, useTranslate } from "react-admin";

const SourceFilter = (props) => {
	const translate = useTranslate();

	const formatUrl = (url) => {
		if (url) { return url.split('?')[0]; }
		return "";
	}

	return (
		<Filter {...props}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<TextInput source='source_url' label="URL" format={(value) => formatUrl(value)} alwaysOn variant={"outlined"} />
			<TextInput source='uid' alwaysOn variant={"outlined"} />
			<DateInput source='created_at_gte' label={translate("resources.filters.from_date")} variant={"outlined"} />
			<DateInput source='created_at_lte' label={translate("resources.filters.to_date")} variant={"outlined"} />
		</Filter>
	);
};

export default SourceFilter;

import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    box: {
        width: "100%",
        paddingLeft: "0.5rem",
    },
    title: {
        fontSize: "1.5rem !important",
        color: "#5B7FF1",
    },
    titleIconContainer: {
		display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "max-content",
		position: "relative",
		"&::after": {
			width: "calc(100% + 30px)",
			height: "6px",
			content: '""',
			background: "#5B7FF177",
			position: "absolute",
			top: "40px",
			borderRadius: "6px",
		}
    },
    icon: {
        color: "#5B7FF1",
        width: "1.5rem",
        height: "1.5rem",
        paddingLeft: "0.5rem"
    }
});

const DashboardTitle = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.box} style={props.style}>
			<div className={classes.titleIconContainer}>
				<Typography variant="h6" className={classes.title} component="div">{props.title}</Typography>
				<span className={classes.icon}>{props.icon}</span>
			</div>
		</div>
	)
}

export default DashboardTitle;
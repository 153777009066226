import React from "react";
import { Filter, SearchInput, DateInput, useTranslate, SelectInput } from "react-admin";

const ReportFilter = (props) => {
	const translate = useTranslate();

	return (
		<Filter {...props}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<DateInput
				source='created_at_gte'
				alwaysOn
				label={translate("resources.filters.from_date")}
				variant={"outlined"}
				sx={{ marginBottom: "2px" }}
			/>
			<DateInput 
				source='created_at_lte' 
				alwaysOn 
				label={translate("resources.filters.to_date")} 
				variant={"outlined"} 
				sx={{ marginBottom: "2px" }}
			/>
			<SelectInput
				source='classification'
				label={translate("resources.filters.type")}
				resettable
				choices={[
					{ id: "TOXICITY", name: translate("resources.moderation_entries.select.toxicity") },
					{ id: "OBSCENE", name: translate("resources.moderation_entries.select.obscene") },
					{ id: "HATE_SPEECH", name: translate("resources.moderation_entries.select.hate_speech") },
					{ id: "INCOHERENT", name: translate("resources.moderation_entries.select.incoherent") },
					{ id: "SPAM", name: translate("resources.moderation_entries.select.spam") },
					{ id: "UNSUBSTANTIAL", name: translate("resources.moderation_entries.select.unsubstantial") },
				]}
				variant={"outlined"}
				alwaysOn
			/>
			<SelectInput
				source='reportable_type'
				resettable
				label={translate("resources.filters.reportable_type")}
				choices={[
					{ id: "Message", name: translate("resources.reports.labels.argument") },
					{ id: "DebateSuggestion", name: translate("resources.reports.labels.debate_suggestion") },
					{ id: "Proposal", name: translate("resources.reports.labels.proposal") },
				]}
				variant={"outlined"}
				alwaysOn
			/>
		</Filter>
	);
};

export default ReportFilter;

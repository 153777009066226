import React, { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(theme => ({
  flexRow : {
    display: "flex",
    flexDirection: "row",
    justifyCotent: "center",
    alignItems: "center"
  },
  copyIcon : {
      marginBottom: "20px",
      marginLeft: "20px",
      color: "#a6a6a6",
      "&:hover" : {
          cursor: "pointer",
          opacity: "0.8",
      }
  }
}));

const CopyToClipboard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const copyToClipboard = () => {
    if (props.children.length > 1) {
      navigator.clipboard.writeText(props.children[0].props.children)
    } else {
      navigator.clipboard.writeText(props.children.props.children)
    }
    handleTooltipOpen();
  };

  return (
    <div className={classes.flexRow}>
      {props.children}
      <Tooltip 
        PopperProps={{ disablePortal: true }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={translate("pos.main.copied")}
        arrow
      >
        <FileCopyIcon onClick={copyToClipboard} className={classes.copyIcon} />
      </Tooltip>
    </div>
  )
}

export default CopyToClipboard;

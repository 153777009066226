import React from 'react';
import { DashboardDatePicker } from '../../debate/dashboard/DashboardDatePicker';
import { Box, CircularProgress, MenuItem, Select, Stack } from '@mui/material';
import { IntegrationList } from '../integrations/IntegrationList';
import { BatchList } from '../batches/BatchList';
import { makeStyles } from '@mui/styles';
import { SectionTitle } from './SectionTitle';
import { AcceptedContentCard } from './AcceptedContentCard';
import dayjs from 'dayjs';
import { PostCountCard } from './PostCountCard';
import { CommentHistoryCard } from './CommentHistoryCard';
import { CommentCountCard } from './CommentCountCard';
import { useQuery } from '@tanstack/react-query';
import { Integration } from '../integrations/integration';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
    accordion: {
        marginBottom: 50
    },
    accordionButton: {
        backgroundColor: '#0273E826',
        borderRadius: 3,
        width: 'max-content',
        padding: 8,
        cursor: 'pointer',
        fontWeight: 500,
        marginBottom: 20
    }
});

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export function Dashboard() {
    const [dateRange, setDateRange] = React.useState(
        `${dayjs(thirtyDaysAgo).format('YYYY-MM-DD')}~${dayjs(tomorrow).format(
            'YYYY-MM-DD'
        )}`
    );

    const [selectedProvider, setSelectedProvider] =
        React.useState<string>('all');
    const classes = useStyles();

    const { data: integrations, isLoading: isIntegrationsLoading } = useQuery<
        Integration[]
    >({
        queryKey: ['integrations'],
        queryFn: async () => {
            const response = await httpClient(
                `${process.env.REACT_APP_SOCIAL_MODERATOR_URL}/integrations`
            );

            if (!response) {
                return [];
            }

            return response.json as Integration[];
        }
    });

    const translate = useTranslate();

    return (
        <Box sx={{ marginBottom: 5, maxWidth: '95%' }}>
            <SectionTitle
                text={translate('pos.social_networks.dashboard.section_title')}
            />

            <IntegrationList />

            <details className={classes.accordion}>
                <summary className={classes.accordionButton}>
                    {translate('pos.social_networks.dashboard.see_history')}
                </summary>

                <BatchList />
            </details>

            <Stack direction="row" gap={5} marginBottom={5} alignItems="center">
                <SectionTitle
                    text={translate(
                        'pos.social_networks.dashboard.statistics.section_title'
                    )}
                />

                <Stack direction="row" gap={1}>
                    <DashboardDatePicker setDateRangeCallback={setDateRange} />
                </Stack>
            </Stack>

            <Stack direction="row" gap={5}>
                {/* <Box>
                    <Stack direction="row" gap={5} marginTop={5}>
                        <StatCard>
                            <CardContent>
                                <Typography
                                    variant="h2"
                                    fontSize={18}
                                    marginBottom={3}
                                >
                                    Répartition des followers
                                </Typography>

                                <Stack direction="row" gap={2}>
                                    <PieChart
                                        series={[
                                            {
                                                data: Object.entries(
                                                    statistics.followers
                                                        .providers
                                                ).map(([provider, stats]) => ({
                                                    id: provider,
                                                    label: provider,
                                                    value: stats.value
                                                })),
                                                innerRadius: 70,
                                                highlightScope: {
                                                    fade: 'global',
                                                    highlight: 'item'
                                                }
                                            }
                                        ]}
                                        width={400}
                                        height={200}
                                        slotProps={{
                                            legend: {
                                                itemMarkWidth: 10,
                                                itemMarkHeight: 10
                                            }
                                        }}
                                    />
                                </Stack>
                            </CardContent>
                        </StatCard>
                    </Stack>
                </Box> */}

                <CommentCountCard
                    fromDate={dateRange.split('~')[0]}
                    toDate={dateRange.split('~')[1]}
                />
            </Stack>

            <Select
                defaultValue="all"
                value={selectedProvider}
                sx={{
                    minWidth: 200,
                    textTransform: 'capitalize',
                    marginTop: 5
                }}
                onChange={(e) => {
                    setSelectedProvider(e.target.value);
                }}
            >
                <MenuItem value="all">
                    {translate('resources.statistics.filters.provider.all')}
                </MenuItem>
                {integrations?.map((integration) => (
                    <MenuItem
                        key={integration.provider}
                        value={integration.provider}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {integration.provider}
                    </MenuItem>
                ))}
            </Select>

            <Stack
                sx={{ marginBlock: 2 }}
                direction="row"
                gap={4}
                flexWrap="wrap"
            >
                <AcceptedContentCard
                    fromDate={dateRange.split('~')[0]}
                    toDate={dateRange.split('~')[1]}
                    provider={selectedProvider}
                />

                <PostCountCard
                    provider={selectedProvider}
                    fromDate={dateRange.split('~')[0]}
                    toDate={dateRange.split('~')[1]}
                />
            </Stack>

            <CommentHistoryCard
                provider={selectedProvider}
                fromDate={dateRange.split('~')[0]}
                toDate={dateRange.split('~')[1]}
            />
        </Box>
    );
}

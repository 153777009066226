import React, { useState } from "react";
import {
    TextInput,
    SelectInput,
    useTranslate,
    useRecordContext,
    BooleanInput
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { JsonInput } from "react-admin-json-view";

export const AuthSettings = () => {
    const translate = useTranslate()
    const record = useRecordContext()
    const [authType, setAuthType] = useState(record?.auth.type)

    return (
        <>
            <Typography variant="caption">
                {translate("resources.settings.labels.authentication_method")}
            </Typography>
            <Box flex={1} mb={{ xs: "1em" }} mt={{ xs: "2em" }}>
                <SelectInput
                    variant={"outlined"}
                    fullWidth
                    label={translate(
                        "resources.settings.fields.authentication_method"
                    )}
                    source="auth.type"
                    choices={[
                        { id: "jwt", name: "JWT" },
                        { id: "social", name: "Social" },
                        { id: "oauth2_server", name: "Serveur OAuth2.0" },
                        { id: "oauth2", name: "Client OAuth2.0" },
                    ]}
                    helperText={false}
                    onChange={(event) => setAuthType(event.target.value)}
                />
            </Box>

            {((authType === "oauth2_server") || (authType === "jwt")) &&
                <>
                    {authType === "jwt" &&
                        <SelectInput
                            variant={"outlined"}
                            fullWidth
                            label={translate(
                                "resources.settings.fields.jwt_type"
                            )}
                            source="auth.jwtType"
                            choices={[
                                { id: "jws", name: "JWS (défaut)" },
                                { id: "jwe", name: "JWE" }
                            ]}
                            sx={{ marginBottom: "2em" }}
                            helperText={false}
                        />
                    }
                    <TextInput
                        variant={"outlined"}
                        label={translate("resources.settings.fields.login_url")}
                        source="auth.login_url"
                        fullWidth
                    />
                    <TextInput
                        variant={"outlined"}
                        label={translate("resources.settings.fields.signup_url")}
                        source="auth.registration_url"
                        fullWidth
                    />
                    <JsonInput
                        label={translate(
                            "resources.settings.fields.auth_url_params"
                        )}
                        source="auth.trackingParameters"
                        jsonString={false}
                        reactJsonOptions={{
                            name: null,
                            collapsed: false,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                    <TextInput
                        variant={"outlined"}
                        label={translate(
                            "resources.settings.fields.redirect_parameter"
                        )}
                        source="auth.redirectParameter"
                        fullWidth
                    />
                    {(authType === "oauth2_server") &&
                        <TextInput
                            variant={"outlined"}
                            label={translate("resources.settings.fields.oauth2_client_id")}
                            source="auth.clientId"
                            fullWidth
                        />
                    }
                    <TextInput
                        variant={"outlined"}
                        label={translate("resources.settings.fields.client_secret")}
                        source="auth_secret"
                        fullWidth
                    />
                    {(authType === "oauth2_server") &&
                        <>
                            <TextInput
                                variant={"outlined"}
                                label={translate("resources.settings.fields.oauth2_scope")}
                                source="auth.scope"
                                fullWidth
                            />
                            <TextInput
                                variant={"outlined"}
                                label={translate("resources.settings.fields.oauth2_token_endpoint")}
                                source="auth.tokenEndpoint"
                                fullWidth
                            />
                            <SelectInput
                                variant={"outlined"}
                                fullWidth
                                label={translate(
                                    "resources.settings.fields.oauth2_token_endpoint_method"
                                )}
                                source="auth.tokenEndpointMethod"
                                choices={[
                                    { id: "get", name: "GET" },
                                    { id: "post", name: "POST" }
                                ]}
                                helperText={false}
                                sx={{ marginBottom: "2em" }}
                            />
                            <TextInput
                                variant={"outlined"}
                                label={translate("resources.settings.fields.oauth2_user_endpoint")}
                                source="auth.userProfileEndpoint"
                                fullWidth
                            />
                            <TextInput
                                variant={"outlined"}
                                label={translate("resources.settings.fields.openid_issuer_url")}
                                source="auth.issuerUrl"
                                fullWidth
                            />
                        </>
                    }
                    <JsonInput
                        label={translate(
                            "resources.settings.fields.user_profile_mapping"
                        )}
                        source="auth.userProfileMapping"
                        jsonString={false}
                        reactJsonOptions={{
                            name: null,
                            collapsed: false,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                </>
            }

            <TextInput
                variant={"outlined"}
                label={translate("resources.settings.fields.terms_url")}
                source="provider.cguUrl"
                fullWidth
            />
            <TextInput
                variant={"outlined"}
                label={translate("resources.settings.fields.privacy_url")}
                source="provider.privacyUrl"
                fullWidth
            />
            <BooleanInput
                variant={"outlined"}
                label={translate("resources.settings.fields.random_anonymous_name")}
                source="auth.randomAnonymousName"
                fullWidth
            />
        </>
    )
}
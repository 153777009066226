import React from 'react';
import {
    QueryClient,
    QueryClientProvider as ReactQueryClientProvider
} from '@tanstack/react-query';

const queryClient = new QueryClient();

export const QueryClientProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    return (
        <ReactQueryClientProvider client={queryClient}>
            {children}
        </ReactQueryClientProvider>
    );
};

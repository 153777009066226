import en from './en.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';
import it from './it.json';
import pt from './pt.json';
import pt_BR from './pt_BR.json';

import { formalGermanMessages } from '@haleos/ra-language-german';
import frenchMessages from "ra-language-french";
import englishMessages from "ra-language-english";
import spanishMessages from "@blackbox-vision/ra-language-spanish";
import italianMessages from "ra-language-italian";
import portugueseMessages from "ra-language-portuguese";
import portugueseBRMessages from "ra-language-portuguese";

const french = {
	...frenchMessages,
    ...fr
};

const english = {
	...englishMessages,
    ...en
};

const spanish = {
	...spanishMessages,
    ...es
};

const german = {
	...formalGermanMessages,
    ...de
};

const italian = {
	...italianMessages,
    ...it
};

const portuguese = {
	...portugueseMessages,
    ...pt
};

const portugueseBR = {
	...portugueseBRMessages,
    ...pt_BR
};

export default { french, english, spanish, german, italian, portuguese, portugueseBR };
// in src/MyMenu.js
import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { SourceIcon } from './sources';
import { useLocation } from 'react-router-dom';
import SubMenu from '../utils/SubMenu';

export const SourceMenu = () => {
	const translate = useTranslate();
	const location = useLocation();

	return (
			<>
				<SubMenu
					name={translate(`resources.sources.name`, { smart_count: 2 })}
					icon={<SourceIcon />}
					isSubMenuOpen={ location.pathname.includes("sources") || location.pathname.includes("sources_tags") }
            	>
					<Menu.ResourceItem
                        name="sources"
						primaryText={translate(`resources.sources.name`, { smart_count: 2 })}
					/>
					<Menu.ResourceItem
                        name="sources_tags"
						primaryText={translate(`resources.sources_tags.name`, { smart_count: 2 })}
					/>
				</SubMenu>
			</>
	);
};
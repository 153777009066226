import React from "react";
import MuiToolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { useRecordContext, useTranslate } from "react-admin";
import StatusButton from "../../utils/StatusButton";
import dayjs from '../../utils/dayJsSetup';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "0 16px"
	},
	buttonLine: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginBottom: "10px",
	},
	button: {
		margin: "0",
		marginRight: "15px"
	},
    updateButton: {
        marginBottom: "10px"
    }
}));

const TranslationEditToolbar = ({ translatedContent, originLanguage }) => {
	const classes = useStyles();
	const record = useRecordContext();
	const translate = useTranslate();

	return (
		<MuiToolbar className={classes.root}>
			<>
				<div className={classes.buttonLine}>
					<div className={classes.button}>
						<StatusButton 
							resource="translation_entries" 
							record={record} 
							updateObject={{ is_approved: true, approved_at: dayjs().format("YYYY-MM-DD HH:mm:ss"), translated_content: translatedContent, origin_language: originLanguage, status: "accepted" }} 
							type="ACCEPT"
							approveMessage={translate("resources.translation_entries.fields.approve")}
							disapproveMessage={translate("resources.translation_entries.fields.disapprove")}
						/>
					</div>
					<div className={classes.button}>
						<StatusButton 
							resource="translation_entries" 
							record={record} 
							updateObject={{ is_approved: false,  status: "rejected" }} 
							type="REJECT"
							approveMessage={translate("resources.translation_entries.fields.approve")}
							disapproveMessage={translate("resources.translation_entries.fields.disapprove")}
						/>
					</div>
				</div>
			</>
		</MuiToolbar>
	);
};

export default TranslationEditToolbar;

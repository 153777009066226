import React from 'react';
import { Stack } from '@mui/material';
import { IntegrationCard } from './IntegrationCard';
import { FacebookRounded, Instagram, YouTube } from '@mui/icons-material';

const availableIntegrations = [
    {
        icon: (
            <FacebookRounded
                sx={{ fill: '#1877F2', width: 35, height: 'auto' }}
            />
        ),
        provider: 'facebook',
        authUrl: 'https://www.facebook.com/v9.0/dialog/oauth',
        clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID as string,
        scope: 'pages_manage_engagement,pages_read_engagement,pages_read_user_content,pages_manage_metadata,pages_show_list,read_insights'
    },
    {
        icon: <YouTube sx={{ fill: '#FF0000', width: 35, height: 'auto' }} />,
        provider: 'youtube',
        authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: process.env.REACT_APP_GOOGLE_MODERATION_CLIENT_ID as string,
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
        accessType: 'offline'
    },
    {
        icon: <Instagram sx={{ fill: '#e1306c', width: 35, height: 'auto' }} />,
        provider: 'instagram',
        authUrl: 'https://www.instagram.com/oauth/authorize',
        clientId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID as string,
        scope: 'instagram_business_basic,instagram_business_manage_comments',
        forceAuth: true
    }
];

export const IntegrationList = () => {
    return (
        <Stack direction="row" gap={2} sx={{ marginBlock: 4 }}>
            {availableIntegrations.map((integration) => (
                <IntegrationCard
                    key={integration.provider}
                    provider={integration.provider}
                    icon={integration.icon}
                    authUrl={integration.authUrl}
                    redirectUri={process.env.REACT_APP_API_AUTH_CALLBACK!}
                    clientId={integration.clientId}
                    scope={integration.scope}
                    state={`${window.location.origin}/integrations/${integration.provider}/callback`}
                    accessType={integration.accessType}
                    forceAuth={integration.forceAuth}
                />
            ))}
        </Stack>
    );
};

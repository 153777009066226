import { useState, useEffect } from 'react';

const useFetchVotes = (dataProvider, filter) => {
    const [votes, setVotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVotes = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await dataProvider.getList("votes", {
                    filter: { ...filter },
                    include: ['scrutinRef'],
                    pagination: { perPage: 10 }
                });
                const votes = response.data;
                setVotes(votes);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchVotes();
    }, []);

    return { votes, loading, error };
};

export default useFetchVotes;
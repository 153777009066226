// in src/MyLayout.js
import React from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@mui/styles';
import MyMenu from './Menu';
import MyAppBar from './AppBar';
import { MainError } from './MainError';

const useStyles = makeStyles({
  body: {
      marginTop: '20px',
      height: "100%"
  },
});

const MyLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Layout className={classes.body} menu={MyMenu} appBar={MyAppBar} error={MainError}>
      { children }
    </Layout>
  )
}

export default MyLayout;
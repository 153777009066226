import React, { useState } from "react";
import { useTranslate, useDataProvider } from "react-admin";
import { makeStyles, withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from '../images/logo-responsive.png';

const useStyles = makeStyles({
	main: {
		height: "100vh",
		backgroundImage:
			"url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
		backgroundPosition: "center",
		backgroundSize: "cover",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	formContainer: {
		margin: "auto",
		boxShadow:
			"box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
		borderRadius: "4px",
		padding: "15px",
		backgroundColor: "white",
		width: "max-content",
		textAlign: "center",
	},
	header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px",
    },
    logo: {
        width: "80px",
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        color: "#417EC7"
    },
	form: {
		display: "flex",
		minWidth: "300px",
		minHeight: "200px",
		padding: "10px",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	formInput: {
		margin: "10px 0",
		width: "100%",
	},
	forgotPassword: {
		color: "rgba(0, 0, 0, 0.25)",
		paddingTop: "10px",
		alignSelf: "end",
		textDecoration: "none",
		cursor: "pointer",
		"&:hover": {
			color: "rgba(0, 0, 0, 0.15)",
		},
	},
	submitButton: {
		background: "#417ec7",
		display: "block",
		textDecoration: "none",
		borderRadius: "3px",
		margin: "20px auto 10px auto",
		padding: "10px",
		color: "white",
		width: "max-content",
		textTransform: "uppercase",
		cursor: "pointer",
	},
	resetSuccess: {
		padding: "20px 10px",
	},
});

export const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [submitted, setSubmitted] = useState(false);
	const dataProvider = useDataProvider();
	const translate = useTranslate();
	const submit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		const queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);
		const application = urlParams.get("application");
		const redirectUrl = urlParams.get("redirect_url");
		dataProvider.recoverPassword({ email, application, redirectUrl })
			.then(() => true)
			.catch(() => true);
	};

	const StyledButton = withStyles({
		root: {
			background: "#417ec7",
			color: "white",
			"&:hover": {
				background: "#417ec799",
			},
		},
	})(Button);

	const classes = useStyles();
	return (
		<div className={classes.main}>
			<div className={classes.formContainer}>
				<div className={classes.header}>
					<img className={classes.logo} src={logo} width={150} alt="Logo Logora" />
					<div className={classes.title}>{ "Récupération du mot de passe" }</div>
				</div>
				{!submitted ? (
					<form className={classes.form} onSubmit={submit}>
						<TextField
							required
							type='email'
							className={classes.formInput}
							id='email'
							label={translate("pos.login.email")}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<StyledButton type='submit' className={classes.submitButton}>
							{translate("pos.login.forgot_password_submit")}
						</StyledButton>
					</form>
				) : (
					<>
						<div className={classes.resetSuccess}>{translate("pos.login.forgot_password_success")}</div>
						<a href='#/login' className={classes.submitButton}>
							{translate("pos.login.redirect_to_login")}
						</a>
					</>
				)}
			</div>
		</div>
	);
};
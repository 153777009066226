import React from "react";
import { Filter, DateInput, SelectInput, useTranslate } from "react-admin";
import { LANGUAGES } from '@logora/debate.util.lang_emojis';
import { flags } from "./TranslationList";
import { useSettingsContext } from "../../utils/SettingsProvider";

const TranslationFilter = (props) => {
	const translate = useTranslate();
	const settingsContext = useSettingsContext();
	const translationMethods = settingsContext?.settings?.translation?.translationMethods && Object.keys(settingsContext?.settings?.translation?.translationMethods);
	const choices = LANGUAGES.filter(lang => translationMethods?.includes(lang.name)).map(lang => ({ id: lang.name, name: `${lang.icon} ${lang.name.toUpperCase()}` }));

	return (
		<Filter {...props}>
			<SelectInput
				source='status'
				resettable
				alwaysOn
				choices={[
					{ id: "0", name: translate("resources.translation_entries.fields.is_approved") },
					{ id: "1", name: translate("resources.translation_entries.fields.pending") },
					{ id: "2", name: translate("resources.translation_entries.fields.disapproved") },
				]}
				label={translate("resources.messages.fields.status")}
				variant={"outlined"}
			/>
			<DateInput
				source='created_at_gte'
				alwaysOn
				label={translate("resources.filters.from_date")}
				variant={"outlined"}
				sx={{ marginBottom: "4px" }}
			/>
			<DateInput
				source='created_at_lte'
				alwaysOn
				label={translate("resources.filters.to_date")}
				variant={"outlined"}
				sx={{ marginBottom: "4px" }}
			/>
			<SelectInput
				source='origin_language'
				resettable
				choices={choices}
				alwaysOn
				variant={"outlined"}
			/>
			<SelectInput
				source='target_language'
				resettable
				choices={choices}
				alwaysOn
				variant={"outlined"}
			/>
			<SelectInput
				label={translate("resources.translation_entries.fields.type")}
				resettable
				source='translatable_type'
				choices={[
					{ id: "Message", name: translate("resources.translation_entries.fields.message_type") },
					{ id: "Group", name: translate("resources.translation_entries.fields.debate_type") },
					{ id: "Proposal", name: translate("resources.translation_entries.fields.proposal_type") },
					{ id: "Consultation", name: translate("resources.translation_entries.fields.consultation_type") },
					{ id: "Position", name: translate("resources.translation_entries.fields.position_type") },
					{ id: "DebateSuggestion", name: translate("resources.translation_entries.fields.suggestion_type") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
		</Filter>
	);
};

export default TranslationFilter;

import { formatActeurSansGroupe, formatVotePosition, formatGroupeParlementaire } from "../formatters";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";
import { GroupLabel } from "@logora/parliament.parliament_group.group_label"

const formatGroupe = (field, item) => {
    if (item?.acteurRef?.groupeParlementaire) {
        return <GroupLabel groupName={item.acteurRef.groupeParlementaire.libelle} color={item.acteurRef.groupeParlementaire.couleurAssociee || "#000000"} />
    }
}

const votesFields = {
    auteur: { label: 'Auteur', field: 'acteurRef', format: formatActeurSansGroupe },
    groupeParlementaire: { label: "Groupe politique", field: "acteurRef.groupeParlementaire", include: 'acteurRef.groupeParlementaire', format: formatGroupe },
    positionVote: { label: "Position", field: "positionVote", format: formatVotePosition },
}

const votesFilters = [
    { field: "positionVote", label: "Position", type: 'select', defaultValue: null, options: [
        { value: null, label: "Tous" },
        { value: "pour", label: "Pour" },
        { value: "contre", label: "Contre" },
        { value: "abstention" , label: "Abstention" },
    ]},
];

export const voteData = {
    key: "votes",
    name: "Votes",
    filters: votesFilters,
    fields: votesFields,
    searchTable: "acteurRef",
    icon: <VoteIcon width={20} height={20} />,
    rowRedirect: (item) => `/parliament/acteurs/${item.acteurRefUid}`
}
import React from 'react';
import { TextInput, Create, FormTab, Toolbar, SaveButton, TabbedForm, DateTimeInput, useTranslate } from 'react-admin';
import { useSettingsContext } from '../../utils/SettingsProvider';
import Box from "@mui/material/Box";
import LinkArticleInput from '../../inputs/LinkArticleInput';
import FormSectionTitle from '../../utils/FormSectionTitle';
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import PositionInput from '../../inputs/PositionInput';

const DebateSuggestionCreateToolbar = props => {
  const translate = useTranslate();
  return (
    <Toolbar {...props}>
      <SaveButton label={translate('resources.debate_suggestions.labels.save_button')} />
    </Toolbar>
  )
}

const DebateSuggestionCreate = props => {
  const translate = useTranslate();
  const createDefaultValues = { pro_thesis: translate("resources.groups.labels.default_pro_thesis"), against_thesis: translate("resources.groups.labels.default_against_thesis") };
  const config = useSettingsContext();

  const transformData = (data) => {
    let positionList = `${data.pro_thesis}, ${data.against_thesis}`;
    if (data.pro_thesis && data.against_thesis && data.has_neutral_thesis) {
      positionList = `${data.pro_thesis}, ${data.against_thesis}, ${config?.settings?.vote?.name}`;
    }

    return {
      ...(data.has_neutral_thesis && { neutral_thesis: config?.settings?.vote?.name }),
      ...(data.name !== undefined && { name: data.name }),
      ...{ is_published: false },
      ...{ is_reviewed: false },
      ...{ is_accepted: true },
      ...(data.ends_at !== undefined && { ends_at: data.ends_at }),
      ...(data.tag_list !== undefined && { tag_list: JSON.stringify(data.tag_list) }),
      ...(data.tag_objects && { tag_objects: JSON.stringify(data.tag_objects) }),
      ...(data.content_uids && { content_uids: data.content_uids.join(",") }),
      ...(positionList !== undefined && { position_list: positionList }),
      ...(data.language !== undefined && { language: data.language }),
    };
  }

  return (
    <Create title={translate("resources.debate_suggestions.create_title")} {...props} redirect="/groups" transform={transformData}>
      <TabbedForm defaultValues={createDefaultValues} toolbar={<DebateSuggestionCreateToolbar />}>
        <FormTab label={translate("resources.debate_suggestions.name", { smart_count: 1 })}>
          <FormSectionTitle title={translate('resources.groups.form_title.suggestion')} />
          <TextInput source="name" label={translate('resources.groups.labels.debate_question')} fullWidth variant="outlined" required />
          <PositionInput />
          {config?.settings?.translation?.enable === true &&
            <Box mb={1}>
              <LanguageSelectInput label={translate("resources.settings.fields.language")} />
            </Box>
          }
          {config?.settings?.modules?.sources !== false &&
            <LinkArticleInput
              tagSource={"tag_list"}
              tagInitialSource={"tag_list"}
              tagFormatInitial={(initialValue) => initialValue ? JSON.parse(initialValue) : []}
            />
          }
          <Box flex={1} mt={2}>
            <DateTimeInput source="ends_at" label={translate('resources.groups.labels.ends_at')} />
          </Box>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

export default DebateSuggestionCreate;
import React from 'react';
import {
    useListContext
} from 'react-admin';
import BulkStatusButton from './BulkStatusButton';

const ModerationBulkActionButtons = ({ resourceName, getElementId }) => {
    const { data, selectedIds } = useListContext();
	let selectedEntriesIds = data.filter(m => selectedIds.includes(m.id)).map(getElementId || (elm => elm.moderation_entry?.id));

    return (
		<>
			<BulkStatusButton 
				resource="moderation_entries"
				resourceName={resourceName}
				selectedIds={selectedEntriesIds} 
				updateObject={{ status: "accepted" }} 
				type="ACCEPT" 
			/>
			<BulkStatusButton 
				resource="moderation_entries"
				resourceName={resourceName} 
				selectedIds={selectedEntriesIds} 
				updateObject={{ status: "rejected" }} 
				type="REJECT" 
			/>
			{ resourceName === "messages" &&
				<BulkStatusButton 
					resource="messages"
					resourceName={resourceName} 
					selectedIds={data.filter(m => selectedIds.includes(m.id)).map(elm => elm?.id)} 
					updateObject={{ score: 99, manual_score: true }} 
					type="SELECT" 
				/>
			}
		</>
    )
};

export default ModerationBulkActionButtons;
import React from "react";
import Button from "@mui/material/Button";
import { useUpdate, useNotify, useRecordContext, useRedirect, useRefresh, useTranslate } from "react-admin";

const ArgumentSaveButton = ({ score, currentResource }) => {
	const notify = useNotify();
	const record = useRecordContext();
	const redirectTo = useRedirect();
	const translate = useTranslate();
	const refresh = useRefresh();
	const [approve, { isLoading }] = useUpdate(
		"messages",
		{ 	
			id: record.id,
		 	data: { score: score > 100 ? 100 : score < 0 ? 0 : score, manual_score: true },
			previousData: record,
		},
		{
			mutationMode: 'undoable',
			onSuccess: () => {
				refresh();
				notify(translate(`resources.${currentResource || "messages"}.notifications.score_success`), { type: "info", undoable: true });
				redirectTo(currentResource ? `/${currentResource}` : "/messages");
			},
			onFailure: () => {
				notify(translate(`resources.${currentResource || "messages"}.notifications.score_failure`), { type: "warning" });
			},
		}
	);

	return (
		<Button variant='outlined' style={{ color: "#5B7FF1" }} size='small' onClick={() => approve()} disabled={isLoading}>
			{translate("pos.actions.update_score")}
		</Button>
	);
};

export default ArgumentSaveButton;

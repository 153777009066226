import React from 'react';
import { SelectInput, useGetIdentity } from 'react-admin';

const LanguageSelectInput = ({ label, ...props }) => {
    const { isLoading: identityLoading } = useGetIdentity();

    return (
        <SelectInput
            variant={"outlined"}
            isLoading={identityLoading}
            helperText={false}
            label={label}
            source='language'
            required
            resettable
            choices={[
                { id: "fr", name: "Français" },
                { id: "en", name: "English" },
                { id: "de", name: "Deutsch" },
                { id: "it", name: "Italiano" },
                { id: "rm", name: "Rumantsch" }
            ]}
            {...props}
        />
    )
}

export default LanguageSelectInput;
import React from 'react';
import { useTranslate, useRecordContext } from 'react-admin';

const DebateTitle = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return (
        <span>{translate('resources.groups.name', {smart_count: 1})} {record ? `"${record.name}"` : ''}</span>
    )
};

export default DebateTitle;
import React from 'react';
import { useTranslate } from 'react-admin';
import { Grid } from "@mui/material";
import MultiSelectInput from './MultiSelectInput';
import FormSectionTitle from '../utils/FormSectionTitle';
import dayjs from '../utils/dayJsSetup';

const LinkArticleInput = ({ tagSource, tagInitialSource, tagFormatInitial }) => {
    const translate = useTranslate();

    return (
        <>
            <FormSectionTitle title={translate('resources.groups.form_title.link_debate')} />
            <Grid container columnSpacing={2} rowSpacing={1} mb={1}>
                <Grid item xs={6}>
                    <MultiSelectInput
                        isCreatable={true}
                        isAsync
                        source="content_uids"
                        initialSource="content_sources"
                        format={(v) => v?.map((e) => {
                            let source = e?.source
                            let publishedDate = e?.published_date || source?.published_date
                            return { label: `${e?.title || source?.title || e?.uid} ${publishedDate ? `(${translate("resources.sources.fields.published_date")} ${dayjs(publishedDate).format("DD-MM-YYYY")})` : ""}`, value: e?.uid };
                        })}
                        resource={"sources"}
                        sort={{ field: "published_date", order: "DESC" }}
                        label={translate('resources.groups.fields.uid')}
                        helperText={translate("resources.helpers.source_multi_select")}
                        noOptionsMessage={translate("resources.sources.labels.no_options")}
                        formatCreateLabel={(inputValue) => `${translate("pos.main.creatable_no_results")} "${inputValue}", ${translate("pos.main.creatable_uid")}`}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <MultiSelectInput
                        isCreatable={false}
                        isAsync
                        source={tagSource}
                        initialSource={tagInitialSource}
                        formatInitial={tagFormatInitial}
                        format={(v) => v?.map((e) => {
                            let tagId = (e.display_name && (e.display_name !== e.name)) ? e.name : "";
                            let sourcesCount = e.sources_count ? `- (${e.sources_count} ${translate("resources.sources.name", {smart_count: e.sources_count})})` : "";
                            return { label: `${e.display_name || e.name} ${sourcesCount} ${tagId}`, value: { name: e.name, id: e.id, display_name: e.display_name || e.name } };
                        })}
                        resource={"sources_tags"}
                        sort={{ field: 'sources_count', order: 'DESC' }}
                        label={translate('resources.groups.fields.tag_id_list')}
                        helperText={translate('resources.helpers.tags_multi_select')}
                        noOptionsMessage={translate('resources.groups.labels.no_options_tags')}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default LinkArticleInput;
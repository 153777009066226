import React from "react";
import { Link } from "@mui/material";
import { useRecordContext, useTranslate, Labeled } from "react-admin";
import { makeStyles } from "@mui/styles";
import CopyToClipboard from "../utils/CopyToClipboard";

const useStyles = makeStyles((theme) => ({
	embedLink: {
		maxWidth: "800px",
		marginBottom: "20px !important",
	}
}));

const CopyLinkField = ({ label, source }) => {
	const classes = useStyles();
	const record = useRecordContext();
	const translate = useTranslate();

	return (
		record ?
			<Labeled label={label || translate("pos.main.content_link")}>
				<CopyToClipboard>
					<Link className={classes.embedLink} href={record[source]} target='_blank' rel={"noopener noreferrer"}>
						{record[source]}
					</Link>
				</CopyToClipboard>
			</Labeled>
			: null
	);
};

export default CopyLinkField;
import React, { useState, useEffect } from "react";
import { 
    Datagrid, 
    List, 
    TextField, 
    DateField, 
    EditButton,
    useRecordContext,
    FunctionField,
    TopToolbar,
    Button,
    useDataProvider,
    useRefresh,
    useNotify
} from "react-admin";
import { Box, SnackbarContent } from '@mui/material';
import { DocumentIcon } from '@logora/parliament.icons.regular_icons';
import { AmendmentIcon } from "@logora/parliament.icons.regular_icons";
import { DossierIcon } from '@logora/parliament.icons.regular_icons';
import { SpeechIcon } from "@logora/parliament.icons.regular_icons";
import { OrganeIcon } from "@logora/parliament.icons.regular_icons";
import { QuestionIcon } from '@logora/parliament.icons.regular_icons';
import { ReunionIcon } from "@logora/parliament.icons.regular_icons";
import { VoteIcon } from "@logora/parliament.icons.regular_icons";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";
import { Label } from '@logora/parliament.ui.label';
import { useModal, Modal } from '@logora/debate.dialog.modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import styles from './AlertList.module.scss';
import rowStyle from "../../debate/arguments/rowStyle";
import AlertForm from "./AlertForm";

const AlertListActions = () => {
    const { showModal, hideModal } = useModal();
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const createAlert = (formData) => {
        dataProvider.create("alerts", {
            data: { ...formData }
        }).then(() => {
            hideModal();
            refresh();
            notify(
                <SnackbarContent 
                    autoHideDuration={3000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    message={ "Alerte créée avec succès" }
                    sx={{ backgroundColor: "#2E7D32", color: "#FFF" }}
                />
            );
        }).catch(() => {
            console.log("Une erreur est survenue")
        })
    }
	return (
		<TopToolbar>
			<Button className={styles.mainButton} label="Ajouter une alerte" onClick={() => 
                showModal(
                    <Modal style={{ maxWidth: "650px" }}>
                        <AlertForm type="create" onCreate={(data) => createAlert(data)}/>
                    </Modal>
                )
            } />
		</TopToolbar>
	);
}

export const AlertList = () => {
    return (
        <Box display="flex">
            <List
                className={styles.listWrapper}
                resource="alerts"
                actions={<AlertListActions />}
                sort={{ field: 'created_at', order: 'DESC' }}
                disableSyncWithLocation
                component="div"
            >
                <Datagrid bulkActionButtons={null} rowClick={null} rowSx={rowStyle}>
                    <SwitchBooleanField />
                    <DateField 
                        label="Date"
                        source="created_at" 
                        options={{
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        }}
                    />
                    <TextField label="Nom" source="name" />
                    <FunctionField
                        source="Mots-clés"
                        render={record => (
                            <div className={styles.keywords}>
                                {record.keywords.map(k => <Label text={k.name} />)}
                            </div>
                        )}
                    />
                    <SourceField source="Sources" />
                    <FunctionField
                        source="Fréquence"
                        render={record => {
                            switch (record.frequency) {
                                case 'instant': 
                                    return <Label text='Instantané' />;
                                case 'daily': 
                                    return <Label text='Quotidien' />;
                                case 'weekly': 
                                    return <Label text='Hebdomadaire' />;
                                case 'monthly': 
                                    return <Label text='Mensuel' />;
                                default:
                                    return '';
                            }
                        }}
                    />
                    <UpdateButton />
                </Datagrid>
            </List>
        </Box>
    );
}

const SwitchBooleanField = () => {
    const record = useRecordContext();
    if (!record) { return null; }
    return (
        <Switch checked={record.active} />
    )
}

const SourceField = () => {
    const getIcon = (name) => {
        const icons = {
            documents: <DocumentIcon width={18} height={18} />,
            amendements: <AmendmentIcon width={18} height={18} />,
            dossiers: <DossierIcon width={18} height={18} />,
            scrutins: <VoteIcon width={18} height={18} />,
            interventions: <SpeechIcon width={18} height={18} />,
            organes: <OrganeIcon width={18} height={18} />,
            questions: <QuestionIcon width={18} height={18} />,
            reunions: <ReunionIcon width={18} height={18} />,
            votes: <VoteIcon width={18} height={18} />,
            acteurs: <ActeurIcon width={18} height={18} />
        };
        return icons[name.toLowerCase()] || null;
    };

    const record = useRecordContext();
    if (!record || record.sources.length === 0) { return null; }
    return (
        <div className={styles.keywords}>
            {record.sources.map(s => <Label text={s.name} leftIcon={getIcon(s.name)} />)}
        </div>
    )
}

const UpdateButton = () => {
    const { showModal, hideModal } = useModal();
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const updateAlert = (formData) => {
        dataProvider.update("alerts", {
            id: formData.id,
            data: { ...formData }
        }).then(() => {
            hideModal();
            refresh();
            notify(
                <SnackbarContent 
                    autoHideDuration={3000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    message={ "Alerte modifiée avec succès" }
                    sx={{ backgroundColor: "green", color: "#FFF" }}
                />
            );
        }).catch(() => {
            console.log("Une erreur est survenue")
        })
    }

    const deleteAlert = (id) => {
        dataProvider.delete("alerts", {
            id: id,
        }).then(() => {
            hideModal();
            refresh();
            notify(
                <SnackbarContent 
                    autoHideDuration={3000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    message={ "Alerte supprimée avec succès" }
                    sx={{ backgroundColor: "#2E7D32", color: "#FFF" }}
                />
            );
        }).catch(() => {
            console.log("Une erreur est survenue")
        })
    }

    if (!record) { return null; }
    return (
        <Button 
            className={styles.editButton} 
            startIcon={<SettingsIcon />} 
            label={null} 
            onClick={() => 
                showModal(
                    <Modal style={{ maxWidth: "650px" }}>
                        <AlertForm type="edit" onUpdate={(data) => updateAlert(data)} onDelete={(id) => deleteAlert(id)} record={record} />
                    </Modal>
                )
            }
            record={record}
        />
    )
}
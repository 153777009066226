import React from "react";
import { Filter, DateInput, SearchInput, SelectInput, useTranslate } from "react-admin";
import AutocompleteReferenceInput from "../../inputs/AutocompleteReferenceInput";

const ProposalFilter = (props) => {
	const translate = useTranslate();

	return (
		<Filter {...props}>
			<SearchInput 
				source='query' 
				alwaysOn 
				variant={"outlined"} 
			/>
			<DateInput 
				source='created_at_gte' 
				alwaysOn 
				label={translate("resources.filters.from_date")} 
				variant={"outlined"} 
			/>
			<DateInput 
				source='created_at_lte' 
				alwaysOn 
				label={translate("resources.filters.to_date")} 
				variant={"outlined"} 
			/>
			<SelectInput
				style={{ marginBottom: "0" }}
				source='status'
				resettable
				choices={[
					{ id: "accepted", name: translate("resources.messages.status.accepted") },
					{ id: "pending", name: translate("resources.messages.status.pending") },
					{ id: "rejected", name: translate("resources.messages.status.rejected") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
			<AutocompleteReferenceInput
				source="user_id"
				reference="users"
				sort={{ field: 'created_at', order: 'DESC' }}
				label={translate('resources.messages.fields.author')}
				optionText={choice => `${choice.full_name} [${choice.id}]`}
				optionValue={"identifier"}
				alwaysOn
			/>
			<AutocompleteReferenceInput
				source="consultation_id"
				reference="consultations"
				sort={{ field: 'created_at', order: 'DESC' }}
				filters={{ is_published: true }}
				label={translate('resources.messages.fields.consultation')}
				optionText={choice => `${choice.title}`}
				optionValue={"identifier"}
				alwaysOn
			/>
		</Filter>
	);
};

export default ProposalFilter;

import React, { useState, useEffect } from "react";
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from "react-select/async-creatable";
import { makeStyles } from "@mui/styles";
import { useInput, Labeled, useDataProvider, useRecordContext } from "react-admin";
import HelperText from "../utils/HelperText";
import get from 'lodash/get';

const selectStyles = makeStyles(() => ({
	menu: (styles) => ({ ...styles, zIndex: "9999 !important" }),
	menuList: (styles) => ({ ...styles, zIndex: "9999 !important" }),
	menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
}));

const MultiSelectInput = ({ source, resource, initialSource, format, formatInitial, parse, onChange, value, sort = {}, filters = {}, isAsync = false, isCreatable = true, isClearable = true, required = false, validate = null, formatCreateLabel, label, placeholder = "", helperText = "", noOptionsMessage, fullWidth, isLoading = false, isDisabled = false }) => {
	const dataProvider = useDataProvider();
	const record = useRecordContext();
	const { field } = useInput({ source, label, required, fullWidth, validate });
	const [controlledValue, setControlledValue] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const SelectComponent = isAsync ?
		isCreatable ?
			AsyncCreatableSelect
			:
			AsyncSelect
		: CreatableSelect;

	const updateValues = (newValue) => {
		const cleanedValues = newValue
			? newValue.map((e) => {
				return parse ? parse(e) : e.value;
			})
			: [];
		setControlledValue(newValue);
		field.onChange(cleanedValues);
	}

	useEffect(() => {
		if (value) {
			updateValues(value)
		}
	}, [value]);

	useEffect(() => {
		if (record) {
			let formattedValues = [];
			if (initialSource) {
				formattedValues = get(record, initialSource);
				if (formatInitial) {
					formattedValues = formatInitial(formattedValues);
				}
			}
			updateValues(format ? format(formattedValues) : formattedValues);
		}
	}, [record]);

	const loadOptions = async (query) => {
		try {
			let { data } = await dataProvider.getList(resource, {
				sort: sort,
				pagination: { page: 1, perPage: 20 },
				filter: {
					...(query && { query: query }),
					...(filters && { ...filters }),
					countless: true
				},
			});
			if (format) {
				data = format(data);
			}
			return data;
		} catch (error) {
			console.log(error);
		}
	}

	const handleChange = (newValue) => {
		if (!onChange) {
			updateValues(newValue);
		} else {
			onChange(newValue);
		}
	};

	const handleInputChange = (newValue, actionMeta) => {
		switch (actionMeta.action) {
			case 'set-value':
				setInputValue(actionMeta.prevInputValue);
				return actionMeta.prevInputValue;
			default:
				setInputValue(newValue);
				return newValue;
		}
	}

	const selectProps = {
		isMulti: true,
		isClearable: isClearable,
		required: required,
		closeMenuOnSelect: false,
		hideSelectedOptions: true,
		menuPortalTarget: document.body,
		styles: selectStyles,
		name: field.name,
		createOptionPosition: "first",
		placeholder: placeholder,
		isDisabled: isDisabled,
	}

	const asyncProps = {
		cacheOptions: true,
		defaultOptions: true,
		loadOptions: (inputValue) => loadOptions(inputValue),
		isLoading: isLoading,
		noOptionsMessage: () => noOptionsMessage
	}

	const creatableProps = {
		formatCreateLabel: formatCreateLabel
	}

	return (
		<>
			<Labeled label={label} fullWidth={fullWidth}>
				<SelectComponent
					value={controlledValue}
					inputValue={inputValue}
					onChange={handleChange}
					onInputChange={handleInputChange}
					{...selectProps}
					{...(isAsync && asyncProps)}
					{...(isCreatable && creatableProps)}
				/>
			</Labeled>
			{helperText &&
				<HelperText text={helperText} />
			}
		</>
	);
};

export default MultiSelectInput;

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import SettingsContextProvider from './utils/SettingsProvider';
import { ActiveModulesContextProvider } from './utils/ActiveModulesContext';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <SettingsContextProvider>
        <ActiveModulesContextProvider>
            <App />
        </ActiveModulesContextProvider>
    </SettingsContextProvider>
);

import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SectionTitle from '../utils/SectionTitle';
import UpdatePasswordForm from './UpdatePasswordForm';
import UpdateLanguageForm from './UpdateLanguageForm';

const useStyles = makeStyles({
  content: { 'margin-top': '1em' }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}  

export const Account = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
    <>
      <SectionTitle title={translate("pos.account.section_title")} subtitle={translate("pos.account.section_subtitle")} />
      <Paper square className={classes.content}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
        >
        <Tab label={translate("pos.account.tab.password")} />
        <Tab label={translate("pos.account.tab.language")} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant="h6" gutterBottom>{translate("resources.update_password.section_title")}</Typography>
          <UpdatePasswordForm />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" gutterBottom>{translate("resources.update_language.section_title")}</Typography>
          <UpdateLanguageForm />
        </TabPanel>
      </Paper>
    </>
  );
};
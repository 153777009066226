import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Button,
    useUpdateMany,
    useNotify,
    useRedirect,
    useUnselectAll,
    useTranslate
} from 'react-admin';

const BulkAllowDebateButton = (props) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll('sources');
    const translate = useTranslate();
    const [allowDebate, { isLoading }] = useUpdateMany(
        'sources',
        {
            ids: props.selectedIds,
            data: { allow_debate: true },
        },
        {
            mutationMode: 'undoable',
            onSuccess: () => {
                notify(
                    translate('resources.sources.notifications.success_allow'),
                    {
                        type: 'info',
                        undoable: true,
                    }
                );
                redirectTo('/sources');
                unselectAll();
            },
            onFailure: () => {
                notify(
                    translate('resources.sources.notifications.fail'),
                    { type: 'warning' }
                );
            },
        }
    );

    return (
        <Button
            label={translate('pos.actions.allow_debate')}
            onClick={() => allowDebate()}
            disabled={isLoading}
            style={{ margin: '0 10px', color: "#4CAF50" }}
        >
            <CheckCircleOutlineIcon
                color="#4CAF50"
                style={{ color: '#4CAF50' }}
            />
        </Button>
    );
};

export default BulkAllowDebateButton;
import React, { useRef } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	ArrayField,
	SingleFieldList,
	ChipField,
	NumberField,
	BooleanField,
	useTranslate
} from "react-admin";
import SourceFilter from "./SourceFilter";
import SectionTitle from "../../utils/SectionTitle";
import BulkRemoveDebateButton from "./BulkRemoveDebateButton";
import BulkAllowDebateButton from "./BulkAllowDebateButton";
import LinkField from "../../inputs/LinkField";
import CustomPagination from '../../utils/CustomPagination'
import { useSettingsContext } from '../../utils/SettingsProvider';

const SourceBulkActionButtons = (props) => (
	<>
		<BulkAllowDebateButton {...props} />
		<BulkRemoveDebateButton {...props} />
	</>
);


const SourceList = (props) => {
	const translate = useTranslate();
	const pageTokens = useRef({});
	const config = useSettingsContext();

	return (
		<>
			<SectionTitle
				title={translate("resources.sources.section_title")}
				subtitle={translate("resources.sources.section_subtitle")}
			/>
			<List
				title={translate("resources.sources.name", { smart_count: 2 })}
				filters={<SourceFilter />}
				filter={{ cursor_pagination: true }}
				sort={{ field: "published_date", order: "DESC" }}
				pagination={<CustomPagination />}
				queryOptions={{ meta: { pageTokens } }}
				disableSyncWithLocation
				actions={null}
				{...props}
			>
				<Datagrid bulkActionButtons={<SourceBulkActionButtons />}>
					<TextField source='title' />
					<TextField source='uid' />
					<LinkField source='source_url' content={translate("pos.main.see_source")} />
					<BooleanField source={"has_debate"} FalseIcon={null} style={{ color: "#4CAF50" }} />
					<BooleanField source={"allow_debate"} FalseIcon={null} style={{ color: "#4CAF50" }} />
					{config?.settings?.modules?.comments === true &&
						<NumberField source={"comments_count"} />
					}
					<ArrayField source='tags'>
						<SingleFieldList>
							<ChipField variant='outlined' source='display_name' />
						</SingleFieldList>
					</ArrayField>
					<DateField source='published_date' showTime={true} />
				</Datagrid>
			</List>
		</>
	);
};

export default SourceList;

import React, { useState, useEffect, forwardRef } from 'react';
import {
    AppBar,
    useAuthState,
    useTranslate,
    MenuItemLink,
    useGetIdentity,
    Logout,
    UserMenu,
    useUserMenu,
    useStore
} from 'react-admin';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSettingsContext } from './utils/SettingsProvider';
import { makeStyles } from '@mui/styles';
import { ExportIcon } from './exports';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import logo from './images/logo.png';
import GroupIcon from '@mui/icons-material/Group';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import authProvider from './authProvider';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: '1em !important'
    },
    spacer: {
        flex: 1
    },
    appBar: {
        padding: '5px 0',
        boxShadow: 'none'
    },
    select: {
        color: 'white !important',
        '-webkit-text-fill-color': 'white !important',
        '& > fieldset': {
            border: 'none !important',
            '&:hover': {
                border: 'none !important'
            }
        },
        '& > div': {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        }
    },
    selectDisabled: {
        color: 'white !important',
        '-webkit-text-fill-color': 'white !important',
        cursor: 'not-allowed !important',
        '& > div': {
            paddingRight: '15px !important',
            color: 'white !important',
            '-webkit-text-fill-color': 'white !important',
            cursor: 'not-allowed !important'
        },
        '& svg': {
            display: 'none'
        }
    },
    formControl: {
        marginRight: '16px'
    },
    navbarButton: {
        color: 'rgba(0, 0, 0, 0.87) !important',
        '& > div': {
            minWidth: '36px !important'
        },
        '& > div > svg': {
            width: '20px !important',
            height: '20px !important'
        }
    }
});

const AccountMenu = forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <MenuItemLink
            ref={ref}
            to="/account"
            primaryText={translate('pos.account.section_title')}
            leftIcon={<SettingsIcon />}
            onClick={onClose}
            className={classes.navbarButton}
        />
    );
});

const MembersMenu = forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <MenuItemLink
            ref={ref}
            to="/team"
            primaryText={translate('resources.application_members.members')}
            leftIcon={<GroupIcon />}
            onClick={onClose}
            className={classes.navbarButton}
        />
    );
});

const ExportsMenu = forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const [exports] = useStore('exports');
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <MenuItemLink
            ref={ref}
            to="/exports"
            primaryText={
                <>
                    {translate('resources.exports.name', { smart_count: 2 })}
                    <Badge
                        badgeContent={exports?.length}
                        color="primary"
                        sx={{ marginLeft: '15px' }}
                    ></Badge>
                </>
            }
            leftIcon={<ExportIcon />}
            onClick={onClose}
            className={classes.navbarButton}
        />
    );
});

const MyUserMenu = (props) => {
    const [exports] = useStore('exports');
    return (
        <UserMenu {...props}>
            <ExportsMenu />
            <MembersMenu />
            <AccountMenu />
            <Logout />
        </UserMenu>
    );
};

const MyAppBar = (props) => {
    const { data: identity, isLoading: identityLoading } = useGetIdentity();
    const { authenticated } = useAuthState();
    const [searchParams] = useSearchParams();
    const [userApplications, setUserApplications] = useState();
    const [currentApplication, setCurrentApplication] = useState(
        localStorage.getItem('currentApplication')
    );

    const classes = useStyles();
    const settingsContext = useSettingsContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            const shortname = localStorage.getItem('currentApplication');
            settingsContext?.fetchSettings(shortname);
        }
    }, [authenticated]);

    useEffect(() => {
        if (identity && !identityLoading) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser.admin_applications.length > 0) {
                setUserApplications(identity.admin_applications);
                setCurrentApplication(
                    localStorage.getItem('currentApplication') ||
                        identity.admin_applications[0].name
                );
            } else {
                navigate({
                    pathname: '/onboarding',
                    search: searchParams.toString()
                });
            }
        }
    }, [identity, identityLoading, navigate]);

    const onApplicationChange = (e) => {
        setCurrentApplication(e.target.value);
        localStorage.setItem('currentApplication', e.target.value);
        authProvider
            .getUser()
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                window.location.reload();
            });
    };

    return (
        <AppBar
            {...props}
            className={classes.appBar}
            userMenu={<MyUserMenu />}
            elevation={1}
            alwaysOn
        >
            {!identityLoading && userApplications ? (
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        id="role_select"
                        value={currentApplication || userApplications[0].name}
                        onChange={(e) => onApplicationChange(e)}
                        fullWidth
                        className={
                            userApplications.length <= 1
                                ? `${classes.selectDisabled} ${classes.select}`
                                : classes.select
                        }
                        disabled={userApplications.length <= 1}
                    >
                        {userApplications.map((app) => (
                            <MenuItem value={app.name} key={app.name}>
                                {app.display_name || app.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null}
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img src={logo} width={135} alt="Logo Logora" />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default MyAppBar;

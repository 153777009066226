import React, { useState } from 'react';
import { AutocompleteInput, useGetList } from "react-admin";

const AutocompleteReferenceInput = ({ source, reference, label, filters = {}, sort = {}, optionText, optionValue, alwaysOn, sx }) => {
    const [query, setQuery] = useState('');
    const { data: choices } = useGetList(
        reference, 
        { 
            filter: {
                countless: true, 
                ...(query && { query: query}),
                ...filters
            },
            pagination: {
                page: 1,
                perPage: 10
            },
            sort 
        }
    );

    return (
        <AutocompleteInput 
            label={label}
            source={source}
            choices={choices}
            optionText={optionText}
            optionValue={optionValue}
            onInputChange={e => { if(e?.target.value) { setQuery(e?.target.value) } }}
            size={"small"}
            debounce={500}
            alwaysOn={alwaysOn}
            sx={sx}
        />
    )
};

export default AutocompleteReferenceInput;
import { ChamberLabel } from "@logora/parliament.parliament_chamber.chamber_label"
import { GroupLabel } from "@logora/parliament.parliament_group.group_label"
import { MemberBox } from "@logora/parliament.parliament_member.member_box"
import { CheckIcon, CrossIcon, MotionIcon } from "@logora/parliament.icons.regular_icons"
import { Label } from "@logora/parliament.ui.label"

export const formatDate = (field, item) => {
    return field && (field in item) ? new Date(item[field]).toLocaleDateString() : '-'
}

export const formatDateTime = (field, item) => {
    return field && (field in item) ? new Date(item[field]).toLocaleString() : '-'
}

export const capitalizeFirstLetter = (text) => {
    return text?.charAt(0).toUpperCase() + text?.slice(1) || ""
}

export const formatCapitalize = (field, item) => {
    return capitalizeFirstLetter(item[field])
}

export const formatNumber = (field, item) => {
    return item[field]?.toString() || '0';
}

export const formatNumero = (field, item) => {
    if (item[field]) {
        return `n°${item[field]?.toString()}`
    }
}

export const formatNumeroFromUid = (field, item) => {
    if (item[field]) {
        let str = item[field];
        let slicedStr = str.slice(str.length - 6, str.length)
        return `n°${parseInt(slicedStr).toString()}`
    }
}

export const formatLabel = (field, item) => {
    let colorVariants = {
        "success": ["adopté", "Adopté"],
        "cancel": ["rejeté", "Rejeté", "Retiré", "Irrecevable", "Irrecevable 40"],
        "pending": ["En traitement", "Discuté"],
        "neutral": ["Tombé", "A discuter"]
    }

    if (item[field]) {
        return <Label text={item[field]} variantsObject={colorVariants} colorType={item[field]} />
    }
}

export const formatChambre = (field, item) => {
    if (item[field]) {
        return <ChamberLabel chamber={item[field]} short={true} />
    }
}

export const formatGroupeParlementaire = (field, item) => {
    if (item[field] && item[field].libelle) {
        return <GroupLabel groupName={item[field]?.libelle} color={item[field]?.couleurAssociee || "#000000"} />
    }
}

export const formatOrgane = (field, item) => {
    return item[field]?.libelle
}

export const formatActeur = (field, item) => {
    if(item[field]) {
        return (
            <MemberBox 
                firstName={item[field].prenom} 
                lastName={item[field].nom} 
                imageUrl={item[field].urlImage} 
                {...(item[field].groupeParlementaire ? {
                    parliamentGroup: {
                        groupName: item[field].groupeParlementaire?.libelleAbrege,
                        color: item[field].groupeParlementaire?.couleurAssociee
                    }
                } : {})}
            />
        )
    }
}

export const formatActeurSansGroupe = (field, item) => {
    if(item[field]) {
        return (
            <MemberBox 
                firstName={item[field].prenom} 
                lastName={item[field].nom} 
                imageUrl={item[field].urlImage} 
            />
        )
    }
}

export const formatTexteLegislatif = (field, item) => {
    const titre = item[field]?.titrePrincipalCourt
    return capitalizeFirstLetter(titre)
}

export const formatVotePosition = (field, item) => {
    if (item[field]) {
        switch(item[field]) {
            case "pour": 
                return <div style={{ display: "flex", color: "#2E7D32" }}>A voté pour <CheckIcon width={20} height={20} style={{ marginLeft: ".25em", fill: "#2E7D32" }}/> </div>
            case "contre":
                return <div style={{ display: "flex", color: "#D32F2F" }}>A voté contre <CrossIcon width={20} height={20} style={{ marginLeft: ".25em", fill: "#D32F2F" }}/> </div>
            case "abstention":
                return <div style={{ display: "flex", color: "#455A64" }}>S'est abstenu <MotionIcon width={20} height={20} style={{ marginLeft: ".25em", fill: "#455A64" }}/> </div>
            default: 
                return ""
        }
    }
}

export const formatGroupeVotantVote = (field, item) => {
    if (item[field]) {
        const total = parseInt(item.pour) + parseInt(item.contre) + parseInt(item.abstentions);
        const pourPercentage = total === 0 ? 0 : Math.floor((item.pour / total) * 100);
        const contrePercentage = total === 0 ? 0 : Math.floor((item.contre / total) * 100);
        const abstentionsPercentage = total === 0 ? 0 : Math.floor((item.abstentions / total) * 100);
        
        switch(field) {
            case "pour": 
                return <div style={{ display: "flex", color: "#2E7D32" }}><CheckIcon width={20} height={20} style={{ marginRight: ".25em", fill: "#2E7D32" }}/>{`${pourPercentage}% (${item[field]} ${item[field] === 1 ? "vote" : "votes"})`} </div>
            case "contre":
                return <div style={{ display: "flex", color: "#D32F2F" }}><CheckIcon width={20} height={20} style={{ marginRight: ".25em", fill: "#D32F2F" }}/>{`${contrePercentage}% (${item[field]} ${item[field] === 1 ? "vote" : "votes"})`} </div>
            case "abstentions":
                return <div style={{ display: "flex", color: "#455A64" }}><CheckIcon width={20} height={20} style={{ marginRight: ".25em", fill: "#455A64" }}/>{`${abstentionsPercentage}% (${item[field]} ${item[field] === 1 ? "abstention" : "abstentions"})`} </div>
            default: return "";
        }
    }
}

export const formatDateNaisToAge = (field, item) => {
    const currentDate = new Date();
    const birthDate = new Date(item[field]);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    
    return age;
}
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  title: {
    fontSize: "1rem !important",
    color: "#5B7FF1"
  },
  subtitle : {
    fontSize: '0.75rem !important',
  },
  titleContainerBottomMarged:{
    marginBottom: "15px !important"
  },
  titleContainerTopMarged: {
    marginTop: "15px !important"
  },
  titleContainer: {
    margin: 0
  }
});

const SectionTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={props.bottomMarged ? classes.titleContainerBottomMarged : props.topMarged ? classes.titleContainerTopMarged : classes.titleContainer}>
      <Typography variant="h6" className={classes.title} component="div">{props.title}</Typography>
      <Typography variant="h6" className={classes.subtitle} component="div">{props.subtitle}</Typography>
    </div>
  )
}

export default SectionTitle;
import React, { useEffect, useRef } from 'react';
import { useTranslate, useRecordContext, Labeled } from 'react-admin';
import { useSettingsContext } from '../utils/SettingsProvider';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CopyToClipboard from "../utils/CopyToClipboard";
import IframeResizer from 'iframe-resizer-react';

const EmbedField = ({ type, resource }) => {
	const iframeRef = useRef();
	const record = useRecordContext();
    const settingsContext = useSettingsContext();
	const translate = useTranslate();
	const location = useLocation();
	
	useEffect(() => {
		iframeRef.current?.resize();
    }, [location]);
 
	if (record?.identifier && settingsContext?.settings) {
		return (
			<>
				<Labeled label={translate(`resources.groups.labels.${type}`)}>
					<CopyToClipboard>
						<Box mb={'20px'}>
							{type === "oEmbed" ? 
                                "https://app.logora.fr/share/g/" + record.identifier
							: type === "iframe" ? 
                                `<iframe class="logoraIframe" style="border: 0;" src="https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=${resource}" width="100%" height="285"></iframe>`
							: type === "vote_iframe" ? 
                                `<iframe class="logoraIframe" style="border: 0;" src="https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=${resource}&config=%7B%22synthesis%22%3A%7B%22hideArguments%22%3Atrue%7D%7D" width="100%" height="285"></iframe>`
							: type === "widget" ?
                                `<iframe class="logoraIframe" style="border: 0;" src="https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=widget" width="100%" height="150"></iframe>`
						    : null}
						</Box>
					</CopyToClipboard>
				</Labeled>
				<Box width={'800px'}>
					{	type === "iframe" ?
							<IframeResizer forwardRef={iframeRef} className="logoraIframe" style={{ border: 0 }} src={`https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=${resource}`} width="100%" height="285"/>
						: type === "vote_iframe" ?
							<IframeResizer forwardRef={iframeRef} className="logoraIframe" style={{ border: 0 }} src={`https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=${resource}&config=%7B%22synthesis%22%3A%7B%22hideArguments%22%3Atrue%7D%7D`} width="100%" height="285"/>
						: type === "widget" ?
							<IframeResizer forwardRef={iframeRef} className="logoraIframe" style={{ border: 0 }} src={`https://cdn.logora.com/${settingsContext?.settings?.synthesis?.embedFileName || "embed"}.html?shortname=${settingsContext?.settings?.name || settingsContext?.settings?.shortname}&id=${record.identifier}&resource=widget`} width="100%" height="285"/>
						: null
					}
				</Box>
			</>
		);
	} else {
		return null;
	}
};

export default EmbedField;
// in src/MyMenu.js
import React from "react";
import { Menu, useTranslate } from "react-admin";
import { useSettingsContext } from "../utils/SettingsProvider";

export const DebateMenu = () => {
    const translate = useTranslate();
    const config = useSettingsContext();

    return (
        <>
            <Menu.ResourceItem
                name="groups"
                primaryText={translate(`resources.groups.name`, { smart_count: 2 })}
            />
            {config.settings?.modules?.consultation === true &&
                <Menu.ResourceItem
                    name="consultations"
                    primaryText={translate(`resources.consultations.name`, { smart_count: 2 })}
                />
            }
            <Menu.ResourceItem
                name="messages"
                primaryText={translate(`resources.messages.name`, { smart_count: 2 })}
            />
            {config.settings?.modules?.comments === true &&
                <Menu.ResourceItem
                    name="comments"
                    primaryText={translate(`resources.comments.name`, { smart_count: 2 })}
                />
            }
            {config.settings?.modules?.consultation === true &&
                <Menu.ResourceItem
                    name="proposals"
                    primaryText={translate(`resources.proposals.name`, { smart_count: 2 })}
                />
            }
            {config.settings?.modules?.suggestions?.active === true &&
                <Menu.ResourceItem
                    name="debate_suggestions"
                    primaryText={translate(`resources.debate_suggestions.name`, { smart_count: 2 })}
                />
            }
            {config.settings?.translation?.enable === true &&
                <Menu.ResourceItem
                    name="translation_entries"
                    primaryText={translate(`resources.translation_entries.name`, { smart_count: 2 })}
                />
            }
        </>
    );
};
import React from 'react';
import { StatCard } from './StatCard';
import { CardContent, Skeleton, Stack, Typography } from '@mui/material';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
import { useTranslate } from 'react-admin';

interface MetricCardProps {
    title: string;
    value: string;
    icon: React.ReactNode;
    trend: 'up' | 'down' | 'flat';
    trendValue: string;
    isLoading?: boolean;
    subtitle?: string;
}

const trendColors: Record<MetricCardProps['trend'], string> = {
    up: 'success.main',
    down: 'error',
    flat: 'secondary'
};

export function MetricCard({
    title,
    value,
    icon,
    trend,
    trendValue,
    isLoading,
    subtitle
}: MetricCardProps) {
    const translate = useTranslate();

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                width="100%"
                height={209}
                sx={{ borderRadius: 5, maxWidth: 300 }}
            />
        );

    return (
        <StatCard sx={{ maxWidth: 300 }}>
            <CardContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack>
                        <Typography variant="h2" fontSize={18} marginBottom={3}>
                            {title}
                        </Typography>

                        <Typography fontSize={32}>{value}</Typography>
                        <Typography
                            fontSize={14}
                            display="inline"
                            minHeight={20}
                        >
                            {subtitle}
                        </Typography>
                    </Stack>

                    {icon}
                </Stack>

                <Stack direction="row" gap={1} marginTop={2}>
                    {trend === 'up' && <TrendingUp color="success" />}
                    {trend === 'down' && <TrendingDown color="error" />}
                    {trend === 'flat' && <TrendingFlat color="secondary" />}
                    <Typography fontSize={14}>
                        <Typography
                            fontSize={14}
                            fontWeight="bold"
                            display="inline"
                            color={trendColors[trend]}
                        >
                            {trendValue}
                        </Typography>{' '}
                        {translate(`resources.statistics.trend.${trend}`)}
                    </Typography>
                </Stack>
            </CardContent>
        </StatCard>
    );
}

import React, { cloneElement, useRef } from "react";
import {
	List,
	Datagrid,
	TextField,
	BooleanField,
	DateField,
	NumberField,
	FunctionField,
	useRecordContext,
	useTranslate,
	useListContext,
	TopToolbar,
	sanitizeListRestProps
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { useSettingsContext } from "../../utils/SettingsProvider";
import UserReferenceField from "../../users/UserReferenceField";
import SectionTitle from "../../utils/SectionTitle";
import DebateSuggestionFilter from "./DebateSuggestionFilter";
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import rowStyle from '../arguments/rowStyle';
import CustomPagination from '../../utils/CustomPagination';
import ExportButton from "../../utils/ExportButton";

const useStyles = makeStyles((theme) => ({
	positionItem: {
		display: "inline",
	},
}));

const ListActions = (props) => {
	const {
		className,
		exporter,
		filters,
		maxResults,
		...rest
	} = props;
	const {
		resource,
		displayedFilters,
		filterValues,
		showFilter,
		sort
	} = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{filters && cloneElement(filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: 'button',
			})}
			<ExportButton dataName={"DebateSuggestion"} sort={sort} filers={filterValues} />
		</TopToolbar>
	);
};

const DebateSuggestionList = (props) => {
	const translate = useTranslate();
	const pageTokens = useRef({});

	return (
		<>
			<SectionTitle
				title={translate("resources.debate_suggestions.section_title")}
				subtitle={translate("resources.debate_suggestions.section_subtitle")}
				bottomMarged
			/>
			<List
				sort={{ field: "created_at", order: "DESC" }}
				filters={<DebateSuggestionFilter />}
				filter={{ is_admin: false, countless: true }}
				actions={<ListActions />}
				disableSyncWithLocation
				pagination={<CustomPagination alwaysOn />}
				queryOptions={{ meta: { pageTokens } }}
				{...props}
			>
				<Datagrid rowSx={rowStyle} rowClick='edit' bulkActionButtons={<ModerationBulkActionButtons resourceName={"debate_suggestions"} />}>
					<UserReferenceField source='author' />
					<TextField source='name' />
					<DateField source='created_at' />
					<DateField source='expires_at' />
					<BooleanField source={"is_published"} FalseIcon={null} style={{ color: "#4CAF50" }} />
					<BooleanField source={"is_expired"} FalseIcon={null} style={{ color: "#4CAF50" }} />
					<NumberField source='total_votes' />
					<NumberField source='total_downvotes' />
					<FunctionField label={translate("resources.debate_suggestions.fields.status")} render={record => record.moderation_entry?.status === 'rejected' ? translate('resources.messages.status.rejected') : record.moderation_entry?.status === 'accepted' ? translate('resources.messages.status.accepted') : translate('resources.messages.status.pending')} />
				</Datagrid>
			</List>
		</>
	);
};

export default DebateSuggestionList;

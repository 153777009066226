import React from 'react';
import { MetricCard } from './MetricCard';
import { Box } from '@mui/material';
import { Create } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

type PostCountMetric = {
    current_value: number;
    previous_value: number;
};

const getTrend = (currentValue: number, previousValue: number) => {
    if (currentValue > previousValue) {
        return 'up';
    } else if (currentValue < previousValue) {
        return 'down';
    } else {
        return 'flat';
    }
};

export function PostCountCard({
    provider,
    fromDate,
    toDate
}: {
    provider: string;
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery({
        queryKey: ['posts_count', provider, fromDate, toDate],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/posts_count`
            );

            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            if (provider !== 'all') {
                url.searchParams.append('provider', provider);
            }

            const response = await httpClient(url);

            return response && (response.json as PostCountMetric);
        }
    });

    const translate = useTranslate();

    return (
        <MetricCard
            isLoading={isLoading}
            title={translate('resources.statistics.post_count.section_title')}
            trend={
                data
                    ? getTrend(data.current_value, data.previous_value)
                    : 'flat'
            }
            trendValue={
                data
                    ? Math.abs(
                          data.current_value - data.previous_value
                      ).toString()
                    : '0'
            }
            value={data ? `${data.current_value}` : '0'}
            icon={
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center',
                        borderRadius: '1.3em',
                        padding: 1.5,
                        backgroundColor: 'rgba(255, 128, 168, 0.4)'
                    }}
                >
                    <Create sx={{ fontSize: 32, color: '#FF004C' }} />
                </Box>
            }
        />
    );
}

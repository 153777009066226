import { authTokenHandler } from "@logora/debate.auth.use_auth";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const tokenKey = process.env.NODE_ENV === "production" ? "logora_admin_token" : "logora_admin_token_staging";
const tokenHandler = authTokenHandler(axios, process.env.REACT_APP_API_AUTH_URL, tokenKey);

const authProvider = {
	// Login or Signup
	authenticate: (authParams) => {
		return tokenHandler.fetchToken(authParams)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response
			}).then(() => {
				return authProvider.getUser();
			});
	},

	getUser: () => {
		const token = tokenHandler.getToken();
		const headers = new Headers({ "Content-Type": "application/json", Authorization: "Bearer " + token.access_token });
		const currentApplication = localStorage.getItem("currentApplication");
		if (currentApplication) {
			headers.set("current-application", currentApplication);
		}
		const request = new Request(apiUrl + "/admin_me", {
			method: "GET",
			headers: headers,
		});
		return fetch(request)
			.then((response) => response.json())
			.then((json) => {
				if (!json.success) { return Promise.reject(); }
				const currentUser = json.data.resource;
				const currentUserRole = json.data.role;
				if (currentUser.admin_applications.length > 0) {
					localStorage.setItem(
						"currentApplication",
						localStorage.getItem("currentApplication") || currentUser.admin_applications[0].name
					);
				}
				localStorage.setItem("currentUserRole", currentUserRole);
				localStorage.setItem("currentUser", JSON.stringify(currentUser));
				return Promise.resolve();
			});
	},

	// called when the user clicks on the logout button
	logout: () => {
		authProvider.cleanSession();
		return Promise.resolve();
	},

	cleanSession: () => {
		tokenHandler.removeToken();
		localStorage.removeItem("currentApplication");
		localStorage.removeItem("currentUserRole");
		localStorage.removeItem("currentUser");
	},

	// called when the API returns an error
	checkError: ({ status }) => {
		if (status === 403 || status === 401) {
			authProvider.cleanSession();
			return Promise.reject();
		}
		return Promise.resolve();
	},

	// called when the user navigates to a new location
	checkAuth: () => {
		const token = tokenHandler.getToken();
		return token?.access_token ? Promise.resolve() : Promise.reject();
	},

	getPermissions: () => {
		const currentUserRole = localStorage.getItem("currentUserRole");
		return currentUserRole ? Promise.resolve(currentUserRole) : Promise.resolve("visitor");
	},

	getIdentity: () => {
		try {
			const currentUser = JSON.parse(localStorage.getItem("currentUser"));
			return currentUser ? Promise.resolve({
				id: currentUser.id,
				fullName: currentUser.full_name,
				avatar: currentUser.image_url,
				admin_applications: currentUser.admin_applications,
				language: currentUser.language,
				slug: currentUser.slug
			}) : Promise.reject();
		} catch(error) {
			return Promise.reject();
		}
	}
};

export default authProvider;

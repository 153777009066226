import React from 'react';
import { MetricCard } from './MetricCard';
import { Box } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

type AcceptedContentMetric = {
    current_value: number;
    previous_value: number;
};

const getTrend = (currentValue: number, previousValue: number) => {
    if (currentValue > previousValue) {
        return 'up';
    } else if (currentValue < previousValue) {
        return 'down';
    } else {
        return 'flat';
    }
};

export function AcceptedContentCard({
    provider,
    fromDate,
    toDate
}: {
    provider: string;
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery({
        queryKey: ['accepted_content', provider, fromDate, toDate],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/accepted_percentage`
            );

            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            if (provider !== 'all') {
                url.searchParams.append('provider', provider);
            }

            const response = await httpClient(url);

            return response && (response.json as AcceptedContentMetric);
        }
    });

    const translate = useTranslate();

    return (
        <MetricCard
            isLoading={isLoading}
            title={translate(
                'resources.statistics.accepted_content.section_title'
            )}
            trend={
                data
                    ? getTrend(data.current_value, data.previous_value)
                    : 'flat'
            }
            trendValue={
                data
                    ? `${Math.abs(data.current_value - data.previous_value)}%`
                    : '0%'
            }
            value={data ? `${data.current_value}%` : '0%'}
            icon={
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center',
                        borderRadius: '1.3em',
                        padding: 1.5,
                        backgroundColor: 'rgba(254, 197, 61, 0.4)'
                    }}
                >
                    <Check sx={{ fontSize: 32, color: '#FEC53D' }} />
                </Box>
            }
            subtitle={translate(
                'resources.statistics.accepted_content.subtitle'
            )}
        />
    );
}

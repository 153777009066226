import React from 'react';
import Chip from "@mui/material/Chip"
import AccountCircle from '@mui/icons-material/AccountCircle';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

const AuthorField = (props) => {
	const record = useRecordContext() || props.record;
    const author = get(record, props.source);

	if (author) {
		const formattedAuthorName = author.first_name && author.last_name ? `${author.first_name.charAt(0)}. ${author.last_name}` : author.first_name
		return (
			<Chip icon={<AccountCircle />} label={formattedAuthorName} variant="outlined" />
		);
	} else {
		return null;
	}
}

export default AuthorField;
import React from "react";
import { Filter, SearchInput, DateInput, useTranslate, SelectInput } from "react-admin";
import AutocompleteReferenceInput from "../../inputs/AutocompleteReferenceInput";

const DebateSuggestionFilter = (props) => {
	const translate = useTranslate();

	return (
		<Filter {...props}>
			<SearchInput 
				source='query' 
				alwaysOn 
				variant={"outlined"} 
				sx={{ marginBottom: "2px" }}
			/>
			<SelectInput
				source='status'
				resettable
				choices={[
					{ id: "accepted", name: translate("resources.messages.status.accepted") },
					{ id: "pending", name: translate("resources.messages.status.pending") },
					{ id: "rejected", name: translate("resources.messages.status.rejected") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
			<DateInput 
				source='created_at_gte' 
				alwaysOn 
				label={translate("resources.filters.from_date")} 
				sx={{ marginBottom: "4px" }}
			/>
			<DateInput 
				source='created_at_lte' 
				alwaysOn 
				label={translate("resources.filters.to_date")} 
				sx={{ marginBottom: "4px" }}
			/>
			<SelectInput
				source='is_expired'
				resettable
				choices={[
					{ id: "true", name: translate("resources.debate_suggestions.fields.is_expired") },
					{ id: "false", name: translate("resources.debate_suggestions.fields.is_not_expired") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
			<SelectInput
				source='is_published'
				resettable
				choices={[
					{ id: "true", name: translate("resources.debate_suggestions.fields.is_published") },
					{ id: "false", name: translate("resources.debate_suggestions.fields.is_not_published") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
			<AutocompleteReferenceInput
				source="user_id"
				reference="users"
				sort={{ field: 'created_at', order: 'DESC' }}
				label={translate('resources.messages.fields.author')}
				optionText={choice => `${choice.full_name} [${choice.id}]`}
				optionValue={"identifier"}
				alwaysOn
				sx={{ marginBottom: "4px" }}
			/>
		</Filter>
	);
};

export default DebateSuggestionFilter;

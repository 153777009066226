import httpClient from "../httpClient";
import queryString from "query-string";

const apiUrl = process.env.REACT_APP_TRICOTEUSES_API_URL

export const dataProvider = {
    getList: async (resource, params) => {
		try {
			const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
			const { field, order } = params.sort || {};
			const filter = { ...params.filter };
			const search = params.search;
			const searchTable = params.searchTable;
			const include = params.include || [];
			const distinct = params.distinct;
			const query = {
				...filter,
				...(field && { sort: `${field}.${order}` }),
				...(include.length && { include: include.join(',') }),
				...(search && { search: search }),
				...(searchTable && { searchTable: searchTable }),
				...(distinct && { distinct: distinct }),
				page: page,
				perPage: perPage,
			};
			const url = resource_ => `${apiUrl}/${resource_}?${queryString.stringify(query)}`;
            switch (resource) {
				default: {
					const { headers, json } = await httpClient(url(resource), { method: "get" }, false);
					const data = json?.data?.map((resource) => ({ ...resource, id: resource.id }));
					const total = parseInt(headers.get("total"), 10) || 0;
					return { data, total };
				}
            }
        } catch (error) { console.log(error) }
    },

	getOne: (resource, params) => {
		const include = params.include || [];
		const query = {
			...(include.length && { include: include.join(',') }),
		};
		const url = `${apiUrl}/${resource}/${params.id}?${queryString.stringify(query)}`;
		return httpClient(url, { method: "get" }, false).then(({ json }) => ({
			data: { ...json.data, id: json.data.uid },
		})).catch((error) => {console.log(error)});
	},
};

import React, { useState, useEffect } from "react";
import { useDataProvider, useTranslate } from "react-admin";
import { Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MultiSelectInput from "./MultiSelectInput";
import CachedIcon from '@mui/icons-material/Cached';
import dayjs from '../utils/dayJsSetup';

const useStyles = makeStyles((theme) => ({
	loading: {
		fontSize: "0.80rem",
		margin: "3px 0",
		color: "#5b7ff1",
		animation: "$loader 1.5s cubic-bezier(0.1, -0.6, 0.2, 0) infinite",
	},
	'@keyframes loader': {
		from: { opacity: '1' },
		"50%": { opacity: '0.65' },
		to: { opacity: '1' }
	},
	loaded: {
		fontSize: "0.80rem",
		margin: "3px 0",
		color: "#5b7ff1",
	},
	reloadBtn: {
		borderColor: "#5B7FF1",
		color: "#5B7FF1",
		borderRadius: "3px",
		marginLeft: "10px !important",
		border: "1px solid",
		cursor: "pointer"
	}
}));

const ContextMultiSelectInput = ({ query, reloadOnQueryChange = false }) => {
	const dataProvider = useDataProvider();
	const translate = useTranslate();
	const [similarityNumberResults, setSimilarityNumberResults] = useState();
	const [similarityIsLoading, setSimilarityIsLoading] = useState(false);
	const [reloadSimilarity, setReloadSimilarity] = useState(false);
	const [isFetchingSource, setIsFetchingSource] = useState(false);
	const [value, setValue] = useState([]);
	const classes = useStyles();

	const getSimilarity = async (query) => {
		try {
			const { data } = await dataProvider.getSimilarity(query);
			setSimilarityIsLoading(false);
			setReloadSimilarity(false);
			setValue(data);
			setSimilarityNumberResults(data.length);
			return data;
		} catch (error) {
			setSimilarityIsLoading(false);
			setReloadSimilarity(false)
			console.log(error);
		}
	}

	const createContextSource = async (inputValue) => {
		setIsFetchingSource(true);
		try {
			const { data } = await dataProvider.fetchSource(inputValue);
			setIsFetchingSource(false);
			return data;
		} catch (error) {
			setIsFetchingSource(false);
			console.log(error);
		}
	}

	useEffect(() => {
		if (query && (reloadOnQueryChange || reloadSimilarity)) {
			setSimilarityIsLoading(true);
			const timer = setTimeout(() => {
				getSimilarity(query);
			}, 4000);
			return () => clearTimeout(timer);
		}
	}, [query, reloadOnQueryChange, reloadSimilarity]);

	const isValidUrl = (urlString) => {
		try {
			return Boolean(new URL(urlString));
		}
		catch (e) {
			return false;
		}
	}

	const handleChange = async (newValues) => {
		const lastValue = newValues[newValues.length - 1] || {};
		const url = lastValue?.value;
		if (isValidUrl(url)) {
			const data = await createContextSource(url);
			const updatedValues = newValues.map(e => {
				if (e.value === url) {
					return formatValue(data);
				}
				return e;
			});
			setValue(updatedValues);
		} else {
			setValue(newValues);
		}
	}

	const formatValue = (value) => {
		return { label: `${value?.title} (${translate("resources.sources.fields.published_date")} ${dayjs(value?.published_date).format("DD-MM-YYYY")})`, value: value?.id };
	}

	return (
		<Box sx={{ flexDirection: 'column' }}>
			{similarityIsLoading ?
				<span className={classes.loading}>{translate("resources.groups.fields.is_loading")}</span>
				:
				query && (
					<Stack direction={"row"} alignItems={"center"}>
						{similarityNumberResults === 0 &&
							<span className={classes.loaded}>{translate("resources.groups.labels.no_context")}</span>
						}
						{reloadOnQueryChange === false &&
							<Button className={classes.reloadBtn} size={"small"} onClick={() => setReloadSimilarity(true)} endIcon={<CachedIcon fontSize="small" />}>
								{translate("resources.groups.labels.reload")}
							</Button>
						}
					</Stack>
				)
			}
			{isFetchingSource &&
				<span className={classes.loading}>{translate("resources.groups.fields.fetch_source")}</span>
			}
			<MultiSelectInput
				value={value}
				onChange={handleChange}
				isCreatable
				isAsync
				resource={"sources"}
				source="context_source_list"
				initialSource="group_context.context_sources"
				format={(v) => v?.map(formatValue)}
				sort={{ field: "published_date", order: "DESC" }}
				filters={{ typeID: "id" }}
				label={translate('resources.groups.fields.context_source_list')}
				helperText={translate("resources.helpers.context_multi_select")}
				noOptionsMessage={translate("resources.sources.labels.no_options")}
				formatCreateLabel={(inputValue) => `${translate("pos.main.creatable_no_results")}, ${translate("pos.main.creatable_url")}`}
				isLoading={similarityIsLoading || isFetchingSource}
				isDisabled={similarityIsLoading || isFetchingSource}
				fullWidth
			/>
		</Box>
	);
};

export default ContextMultiSelectInput;

import { ModerableSource } from '../moderable_sources/moderableSource';

export type Integration = {
    id: number | string;
    provider: string;
    status: IntegrationStatus;
    created_at: string;
    updated_at: string;
    next_retrieval_at: string;
    moderable_sources: Pick<ModerableSource, 'id' | 'name' | 'url'>[];
};

export const IntegrationStatusEnum = {
    active: 'active',
    suspended: 'suspended',
    not_connected: 'not_connected'
} as const;

export type IntegrationStatus =
    (typeof IntegrationStatusEnum)[keyof typeof IntegrationStatusEnum];

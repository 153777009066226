import { Stack, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import React from 'react';
import { providerIcons } from '../utils/providerIcons';

export const ModerableSourceField = ({ label }: { label: string }) => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <a
            href={record['moderable_source']['url']}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Stack direction="row" alignItems="center" gap={1}>
                {providerIcons[record['moderable_source']['provider']]}{' '}
                <Typography>{record['moderable_source']['name']}</Typography>
            </Stack>
        </a>
    );
};

import React, { useState } from "react";
import { useTranslate, Button, usePermissions, List, Datagrid, TextField, FunctionField, SelectField, DeleteWithConfirmButton, useRefresh, useUpdate, useNotify, useRecordContext } from "react-admin";
import { makeStyles } from "@mui/styles";
import SectionTitle from "../utils/SectionTitle";
import AddIcon from "@mui/icons-material/Add";
import InvitationDialog from "./InvitationDialog";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles({
	inviteButton: {
		marginLeft: "auto !important",
		alignSelf: "end",
		border: "1px solid #5B7FF1 !important",
		color: "#5B7FF1 !important",
		padding: "10px",
	},
	listHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "start",
		margin: "15px 0",
	},
	descriptionTable: {
		border: "1px solid #666",
		backgroundColor: "#e7e7e7",
		borderRadius: "4px",
		marginBottom: "15px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		padding: "6px",
	},
	descriptionItem: {
		margin: "5px",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "0.875rem",
	},
});

export const Team = () => {
	const { permissions } = usePermissions();
	const refresh = useRefresh();
	const translate = useTranslate();
	const classes = useStyles();
	const [openInvitationDialog, setOpenInvitationDialog] = useState(false);

	const isAdmin = (permissions === "admin" || permissions === "super_admin");

	return (
		<>
			<div className={classes.listHeader}>
				<SectionTitle
					title={translate("resources.application_members.team_section_title")}
					subtitle={translate("resources.application_members.team_section_subtitle")}
				/>
				{isAdmin ?
					<Button
						className={classes.inviteButton}
						label={translate("pos.actions.invite_member")}
						alignIcon='left'
						children={<AddIcon />}
						onClick={() => setOpenInvitationDialog(true)}
						disabled={!isAdmin}
					></Button>
					: null
				}
			</div>
			<div className={classes.descriptionTable}>
				<div className={classes.descriptionItem}>
					<b>{translate("resources.application_members.super_admin")}: </b>
					{translate("resources.application_members.super_admin_description")}
				</div>
				<div className={classes.descriptionItem}>
					<b>{translate("resources.application_members.admin")}: </b>
					{translate("resources.application_members.admin_description")}
				</div>
				<div className={classes.descriptionItem}>
					<b>{translate("resources.application_members.editor")}: </b>
					{translate("resources.application_members.editor_description")}
				</div>
				<div className={classes.descriptionItem}>
					<b>{translate("resources.application_members.moderator")}: </b>
					{translate("resources.application_members.moderator_description")}
				</div>
			</div>
			<ApplicationMembersList />
			<div>
				<div>
					<SectionTitle
						title={translate("resources.application_members.invitation_section_title")}
						subtitle={translate("resources.application_members.invitation_section_subtitle")}
					/>
					<ApplicationInvitationsList />
				</div>
			</div>
			{openInvitationDialog &&
				<InvitationDialog
					onClose={() => setOpenInvitationDialog(false)}
					openInvitationDialog={openInvitationDialog}
					onSendInvitation={() => {
						refresh();
						setOpenInvitationDialog(false)
					}}
				/>
			}
		</>
	);
};

const ApplicationMembersList = () => {
	const { permissions } = usePermissions();

	const isAdmin = (permissions === "admin" || permissions === "super_admin");

	return (
		<List resource="application_members" exporter={false} actions={false} disableSyncWithLocation>
			<Datagrid bulkActionButtons={null} rowClick={null}>
				<TextField source="user.full_name" />
				<RoleInput />
				<FunctionField 
					render={record => 
						<DeleteWithConfirmButton 
							disabled={!isAdmin || record.role === "super_admin" || (permissions === "admin" && record.role === "admin")} 
							redirect={false}
						/>
					} 
				/>
			</Datagrid>
		</List>
	);
}

const ApplicationInvitationsList = () => {
	const translate = useTranslate();
	const { permissions } = usePermissions();

	const isAdmin = (permissions === "admin" || permissions === "super_admin");

	return (
		<List
			resource="application_invitations"
			exporter={false}
			actions={false}
			disableSyncWithLocation
		>
			<Datagrid bulkActionButtons={null} rowClick={null}>
				<TextField source="email" />
				<SelectField source="role" choices={[
					{ id: 'admin', name: translate("resources.application_members.admin") },
					{ id: 'editor', name: translate("resources.application_members.editor") },
					{ id: 'moderator', name: translate("resources.application_members.moderator") }
				]} />
				<div style={{ fontStyle: 'italic' }}>
					{translate("resources.application_invitations.pending")}
				</div>
				<DeleteWithConfirmButton disabled={!isAdmin} redirect={false}/>
			</Datagrid>
		</List>
	);
};

const RoleInput = () => {
	const record = useRecordContext();
	const [role, setRole] = useState(record.role);
	const [update] = useUpdate();
	const translate = useTranslate();
	const { permissions } = usePermissions();
	const notify = useNotify();

	const isAdmin = (permissions === "admin" || permissions === "super_admin");

	const handleChange = (e) => {
		setRole(e.target.value);
		update(
			"application_members", 
			{ id: record.id, data: { role: e.target.value }, previousData: record }, 
			{
				onSuccess: () => notify(translate("resources.application_members.role_update_success"), {})
			}
		);
	}

	return (
		<Select
			label={translate("resources.application_members.fields.role")}
			value={role}
			onChange={handleChange}
			disabled={!isAdmin || role === "super_admin" || (permissions === "admin" && role === "admin")}
		>
			<MenuItem disabled value={"super_admin"}>{translate("resources.application_members.super_admin")}</MenuItem>
			<MenuItem value="admin">{translate("resources.application_members.admin")}</MenuItem>
			<MenuItem value="editor">{translate("resources.application_members.editor")}</MenuItem>
			<MenuItem value="moderator">{translate("resources.application_members.moderator")}</MenuItem>
		</Select>
	);
};
import { formatActeur } from "../formatters";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";

const participantsReunionsFields = {
    'acteurRef': { label: 'Nom', field: 'acteurRef', include: "acteurRef", format: formatActeur },
    'presence': { label: 'Présence', field: 'presence' }
}

const participantsReunionsFilters = [
    {
        field: "presence", label: 'Présence', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "présent", label: 'Présent' },
            { value: "absent", label: 'Absent' },
            { value: "excusé", label: 'Excusé' }
        ]
    }
];

export const participantsReunionsData = {
    key: "participants_reunions",
    name: "Participants",
    filters: participantsReunionsFilters,
    fields: participantsReunionsFields,
    icon: <ActeurIcon height={20} width={20} />
}
import React from 'react';
import {
    Stack,
    Badge,
    BadgeOwnProps,
    Typography,
    TypographyProps,
    Box
} from '@mui/material';
import { IntegrationStatus } from './integration';
import { useTranslate } from 'react-admin';
import theme from '../../theme';

interface IntegrationStatusChipProps {
    status: IntegrationStatus;
}

export const IntegrationStatusChip = ({
    status
}: IntegrationStatusChipProps) => {
    const translate = useTranslate();

    let badgeColor: BadgeOwnProps['color'] = 'default';
    let textColor: TypographyProps['color'] = theme.palette.grey['700'];

    switch (status) {
        case 'active':
            badgeColor = 'success';
            textColor = theme.palette.success.main;
            break;
        case 'not_connected':
        case 'suspended':
            badgeColor = 'error';
            textColor = theme.palette.error.main;
            break;
        default:
            break;
    }

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Box
                width={16}
                height={16}
                borderRadius="50%"
                sx={{
                    backgroundColor: '#F5F1EF',
                    display: 'grid',
                    placeItems: 'center'
                }}
            >
                <Badge variant="dot" color={badgeColor} />
            </Box>

            <Typography fontSize={12} fontWeight={600} color={textColor}>
                {translate(`resources.integrations.status.${status}`)}
            </Typography>
        </Stack>
    );
};

import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
    sectionTitle: {
        fontSize: '1.25rem',
        margin: 0,
        color: 'rgba(0, 0, 0, 0.8)',
        fontWeight: 400
    }
});

export function SectionTitle({ text }: { text: string }) {
    const classes = useStyles();

    return <h2 className={classes.sectionTitle}>{text}</h2>;
}

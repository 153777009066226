import React, { useState } from "react";
import { useTranslate, useDataProvider } from "react-admin";
import { makeStyles, withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import logo from '../images/logo_black.png';

const useStyles = makeStyles({
	main: {
		height: "100vh",
		backgroundImage:
			"url('https://uploads-ssl.webflow.com/58c00327a3a19f2169a348cf/58c387c6b6b941e437becd29_bg-gradient-1.svg'), linear-gradient(135deg, #7956ec, #2fb9f8)",
		backgroundPosition: "center",
		backgroundSize: "cover",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	formContainer: {
		margin: "auto",
		boxShadow:
			"box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
		borderRadius: "4px",
		padding: "15px",
		backgroundColor: "white",
		width: "max-content",
		textAlign: "center",
	},
	form: {
		display: "flex",
		minWidth: "300px",
		minHeight: "200px",
		padding: "10px",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	formInput: {
		margin: "10px 0",
		width: "100%",
	},
	infoText: {
		color: "rgba(0, 0, 0, 0.25)",
		marginTop: "20px",
		cursor: "pointer",
		textDecoration: "none",
		display: "block",
		"&:hover": {
			color: "rgba(0, 0, 0, 0.15)",
		},
	},
	forgotPassword: {
		color: "rgba(0, 0, 0, 0.25)",
		paddingTop: "10px",
		alignSelf: "end",
		textDecoration: "none",
		cursor: "pointer",
		"&:hover": {
			color: "rgba(0, 0, 0, 0.15)",
		},
	},
	submitButton: {
		background: "#417ec7",
		display: "block",
		textDecoration: "none",
		borderRadius: "3px",
		margin: "20px auto 10px auto",
		padding: "10px",
		color: "white",
		width: "max-content",
		textTransform: "uppercase",
		cursor: "pointer",
	},
	resetSuccess: {
		padding: "20px 10px",
	},
});

export const ResetPassword = () => {
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(true);
	const [redirectUrl, setRedirectUrl] = useState("#/login");
	const dataProvider = useDataProvider();
	const translate = useTranslate();
	const submit = (e) => {
		e.preventDefault();
		const queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);
		const resetToken = urlParams.get("reset_password_token");
		const redirectUrlParam = urlParams.get("redirect_url");
		if (newPassword !== confirmPassword) {
			setSuccess(false);
			setSubmitted(true);
		} else {
			dataProvider
				.resetPassword({ newPassword, confirmPassword, resetToken })
				.then((response) => {
					if (response.data) {
						if (redirectUrlParam) {
							setRedirectUrl(redirectUrlParam);
						}
						setSuccess(true);
						setSubmitted(true);
					}
				})
				.catch((error) => {
					setSuccess(false);
					setSubmitted(true);
				});
		}
	};

	const StyledButton = withStyles({
		root: {
			marginTop: "1em !important",
			background: "#417ec7 !important",
			color: "white !important",
			"&:hover": {
				background: "#417ec799 !important",
			},
		},
	})(Button);

	const classes = useStyles();
	return (
		<div className={classes.main}>
			<div className={classes.formContainer}>
				<img className={classes.logo} src={logo} width={150} alt='Logo Logora' />
				{!submitted ? (
					<form className={classes.form} onSubmit={submit}>
						<TextField
							required
							type='password'
							className={classes.formInput}
							id='newPassword'
							label={translate("resources.update_password.fields.new_password")}
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
						<TextField
							required
							type='password'
							className={classes.formInput}
							id='confirmPassword'
							label={translate("resources.update_password.fields.new_password_confirmation")}
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<StyledButton type='submit' className={classes.submitButton}>
							{translate("pos.login.reset_password_submit")}
						</StyledButton>
					</form>
				) : (
					<>
						<div className={classes.resetSuccess}>
							{success
								? translate("pos.login.reset_password_success")
								: translate("resources.update_password.notifications.error")}
						</div>
						<a href={decodeURIComponent(redirectUrl)} className={classes.submitButton}>
							{translate("pos.login.redirect_to_login")}
						</a>
					</>
				)}
			</div>
		</div>
	);
};
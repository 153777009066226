import React, { useState} from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useTranslate } from 'react-admin';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
    container : {
        marginBottom: "8px !important",
        width: "100%"
    },
    title : {
        paddingRight: "10px",
        color: "#5B7FF1 !important",
        textTransform: "uppercase",
        minWidth: "fit-content",
        fontSize: "0.95rem !important",
    },
    subtitle: {
        paddingRight: "10px",
        color: "#5B7FF1 !important",
        textTransform: "capitalize",
        minWidth: "fit-content",
        fontSize: "0.95rem !important",
    },
    titleContainer : {
        display: "flex",
        alignItems: "center !important",
        marginTop: "0.5rem",
    },
    description : {
        color: "#545454",
        fontSize: "0.75rem !important",
    },
    example : {
        width: "fit-content",
        color: "#545454",
        textDecoration: "underline",
        cursor: "pointer",
        fontSize: "0.78rem !important",
    },
    isNew : {
        backgroundColor: "#DEE5FC",
        color: "#5B7FF1",
        padding: "0 5px !important",
        borderRadius: "3px",
        marginRight: "10px !important"
    },
    line : {
        width: "100%",
        height: "1px",
        backgroundColor: "#5B7FF1",
        border: "0px"
    },
    closeButton : {
        position: "absolute !important",
        top: "4px",
        right: "0px",
    },
    image: {
        width: "100%",
        objectFit: "cover"
    }
  });

const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "760px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "3px",
};

const FormSectionTitle = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={props.className ? `${classes.container} ${props.className}` : classes.container}>
        <div className={classes.titleContainer}>
            <Typography variant="body1" component="div" className={props.variant === "lowercase" ? classes.subtitle : classes.title}>
                {props.title}
            </Typography>
            {props.isNew &&
                <Typography variant="body2" component="span" className={classes.isNew}>
                    {translate("resources.groups.form_title.new")}
                </Typography>
            }
            <hr className={classes.line} />
        </div>
        {(props.descriptionOne || props.descriptionTwo) && 
            <>
                {props.descriptionOne && 
                    <Typography variant="subtitle2" component="div" className={classes.description}>
                        {props.descriptionOne}
                    </Typography>
                }
                {props.descriptionTwo && 
                    <Typography variant="subtitle2" component="div" className={classes.description}>
                        {props.descriptionTwo}
                    </Typography>
                }
            </>
        }
        {props.example && 
            <>
                <Typography variant="subtitle2" component="div" className={classes.example} onClick={handleOpen}>
                    {translate("resources.groups.form_description.example")}
                </Typography>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={styleModal}>
                        <Button onClick={handleClose} className={classes.closeButton}>
                            <CloseIcon fontSize="small" />
                        </Button>
                        <Typography id="modal-modal-title" variant="h6" component="div" className={classes.title}>
                            {props.exampleTitle}
                        </Typography>
                        <hr className={classes.line} />
                        <Typography id="modal-modal-description" variant="body2" component="div" sx={{ mb: 2, mt: 1 }} className={classes.description}>
                            {props.exampleDescription}
                        </Typography>
                        <img src={props.exampleImageUrl} alt="Context example" width={400} className={classes.image} />
                    </Box>
                </Modal>
            </>
        }
    </div>
  )
}

export default FormSectionTitle;
import React from 'react';
import Avatar from '@mui/material/Avatar';

const AvatarField = ({ src, size = '25', className }) => (
    <Avatar
        src={`${src}`}
        size={size}
        style={{ width: size, height: size }}
        className={className}
    />
);

export default AvatarField;
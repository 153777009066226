import React, { useRef } from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	useRecordContext,
	useTranslate,
	ArrayField,
	SingleFieldList,
	ChipField,
	CreateButton,
	TopToolbar,
	useListContext
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material"
import SectionTitle from "../../utils/SectionTitle";
import LinkField from "../../inputs/LinkField";
import rowStyle from '../arguments/rowStyle';
import ConsultationFilter from './ConsultationFilter';
import CustomPagination from '../../utils/CustomPagination';
import ExportButton from "../../utils/ExportButton";

const useStyles = makeStyles((theme) => ({
	positionItem: {
		display: "inline",
	},
	createButton: {
		justifyContent: "flex-start !important",
		marginTop: "2em !important"
	},
	header: {
		color: "#5B7FF1",
		marginBottom: "1em !important",
		textAlign: "left !important"
	},
	mainButton: {
		background: "#5B7FF1 !important",
		color: "white !important",
		padding: "10px 15px !important",
		fontSize: "18px !important"
	}
}));

const ConsultationListActions = () => {
	const { sort, filterValues } = useListContext();
	const classes = useStyles();

	return (
		<TopToolbar>
			<CreateButton className={classes.mainButton} />
			<ExportButton dataName={"Consultation"} sort={sort} filers={filterValues} />
		</TopToolbar>
	);
}

const Empty = () => {
	const classes = useStyles();
	const translate = useTranslate();

	return (
		<>
			<Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} textAlign="center" m={1}>
				<Typography variant="h6" component="h6" className={classes.header}>Tutoriel consultations</Typography>
				<iframe title="video-5" width="560" height="315" src="https://www.youtube.com/embed/fIbeQ_V5YZY" style={{ border: 0 }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				<CreateButton className={classes.createButton} label={translate('resources.consultations.labels.consultation_create')} />
			</Box>
		</>
	)
};

const ConsultationList = (props) => {
	const translate = useTranslate();
	const pageTokens = useRef({});

	return (
		<>
			<SectionTitle
				title={translate("resources.consultations.section_title")}
				subtitle={translate("resources.consultations.section_subtitle")}
				bottomMarged
			/>
			<List
				title={translate("resources.consultations.name", { smart_count: 2 })}
				sort={{ field: "created_at", order: "DESC" }}
				filter={{ countless: true }}
				filters={<ConsultationFilter />}
				empty={<Empty />}
				actions={<ConsultationListActions />}
				disableSyncWithLocation
				queryOptions={{ meta: { pageTokens } }}
				pagination={<CustomPagination alwaysOn />}
				{...props}
			>
				<Datagrid rowSx={rowStyle} rowClick='edit'>
					<TextField source='title' />
					<LinkField source='direct_url' label={translate("pos.main.consultation_link")} content={translate("pos.main.go_to_consultation_link")} />
					<DateField source='created_at' />
					<DateField source='ends_at' />
					<VotesField source='total_votes' />
					<NumberField source='proposals_count' />
					<NumberField source='total_participants' />
					<ArrayField source='content_tags'>
						<SingleFieldList>
							<ChipField variant='outlined' source='tag.display_name' />
						</SingleFieldList>
					</ArrayField>
				</Datagrid>
			</List>
		</>
	);
};

const VotesField = () => {
	const classes = useStyles();
	const translate = useTranslate();
	const record = useRecordContext();
	const total_votes = (record && record.total_votes) || 0;
	const vote_goal = record && record.vote_goal;

	if (vote_goal > 0) {
		return (
			<p className={classes.positionItem}>
				{total_votes} {translate("pos.main.from_to_pagination")} {vote_goal}
			</p>
		)
	} else {
		return null;
	}
};

export default ConsultationList;

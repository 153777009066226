import React, { useState, useRef } from 'react';
import { MenuSharp, ChevronLeft } from '@mui/icons-material';
import { Alinea } from '../Alinea';
import { capitalizeFirstLetter } from '../../formatters';
import styles from './TexteBox.module.scss';

const TexteBox = ({ document }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { alineas = [], subdivisions = [] } = document || {};
  const containerRef = useRef(null);

  const scrollToSubdivision = (hash) => {
    const element = containerRef.current.querySelector(`[data-hash="${hash}"]`);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIsMenuOpen(false);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.header}>
        <span className={styles.topTexte}>Texte</span>
        <button 
          className={styles.summaryButton}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span>Sommaire</span>
          <MenuSharp fontSize='small' />
        </button>
        {isMenuOpen && (
          <div className={styles.menu}>
            {subdivisions.map(subdivision => (
              <button 
                key={subdivision.hash}
                onClick={() => scrollToSubdivision(subdivision.hash)}
                className={`${styles.menuItem} ${styles[subdivision.type?.toLowerCase()]}`}
              >
                {subdivision.titre}
              </button>
            ))}
          </div>
        )}
      </div>
      <h3 className={styles.formule}>
        {capitalizeFirstLetter(document?.titrePrincipal)}
      </h3>

      <div className={styles.subdivisions}>
        {subdivisions.filter(s => s.type !== "ExposeMotifs").map((subdivision, index) => (
          <div 
            key={subdivision.hash} 
            className={styles.subdivision}
            data-hash={subdivision.hash}
          >
            <h5 className={styles.articleTitle}>
              {subdivision.titre}
            </h5>
            <h5 className={styles.articleTitle}>
              {subdivision.sousTitre}
            </h5>

            <div className={styles.actionButtons}>
              <button href="" className={`${styles.amendmentButton} ${subdivision.nombreAmendements > 0 ? 'active' : ''}`}>
                <ChevronLeft fontSize='small' />
                {subdivisions.nombreAmendements === 0 && "Aucun amendement déposé"}
                {subdivision.nombreAmendements === 1 && "Voir l'amendement déposé"}
                {subdivision.nombreAmendements > 1 && `Voir les ${subdivision.nombreAmendements} amendements déposés`}
              </button>

              {/* <button className={styles.summaryButton}>
                <RemoveRedEye fontSize='small' />
                <span>Voir résumé</span>
              </button> */}
            </div>

            <div className={styles.alineas}>
              {alineas
                .filter(a => a.articleRefHash === subdivision.hash)
                .sort((a, b) => a.numero - b.numero)
                .map((alinea, index) => (
                  <Alinea
                    key={alinea.numero + 1}
                    number={alinea.numero + 1}
                    text={alinea.texte}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TexteBox;
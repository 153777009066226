import React from 'react';
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { ModerableSourceField } from '../moderable_sources/ModerableSourceField';
import CustomPagination from '../../utils/CustomPagination';
import { useNavigate } from 'react-router-dom';
import { Batch } from './batch';

const AcceptedPercentField = (props: {
    record?: Record<string, any>;
    label?: string;
}) => {
    const record = useRecordContext() || props.record;
    const translate = useTranslate();

    if (!record) return null;

    const accepted = record.accepted_count;
    const total = record.count;

    record['accepted_percent'] = total > 0 ? accepted / total : '-';

    return (
        <NumberField
            source="accepted_percent"
            textAlign="center"
            options={{ style: 'percent' }}
        />
    );
};

function useNavigateToBatch() {
    const navigate = useNavigate();

    return (batchId: number) => {
        const filter = {
            batch_id: batchId
        };
        navigate(`/social-networks/contents?filter=${JSON.stringify(filter)}`);
    };
}

export const BatchList = () => {
    const translate = useTranslate();
    const navigateToBatch = useNavigateToBatch();
    const record = useRecordContext<Batch>();

    return (
        <List
            resource="batches"
            title=" "
            sort={{ field: 'created_at', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={3}
            actions={false}
            disableSyncWithLocation
            sx={{ margin: 0 }}
        >
            <Datagrid
                sx={{ boxShadow: '' }}
                bulkActionButtons={false}
                rowClick={(id) => {
                    navigateToBatch(id as number);
                    return false;
                }}
            >
                <DateField
                    source="created_at"
                    label="resources.batches.fields.created_at"
                    showTime
                />

                <NumberField
                    source="count"
                    label="resources.batches.fields.count"
                    textAlign="center"
                />

                <AcceptedPercentField label="resources.batches.fields.accepted_percent" />

                <ModerableSourceField label="resources.batches.fields.source" />
            </Datagrid>
        </List>
    );
};

import React from 'react';
import StatusButton from './StatusButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useRecordContext } from 'react-admin';

const ModerationActionToolbar = ({ path = null, noRedirect = false }) => {
	const record = useRecordContext();
    return (
        <>
			{ record ?
				<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} marginBottom={"10px"}>
					{(record.moderation_entry?.status === "rejected" || record.moderation_entry?.status === "pending") && (
						<Box margin={"0 15px"}>
							<StatusButton resource="moderation_entries" record={record.moderation_entry} currentResource={path} updateObject={{ status: "accepted" }} type="ACCEPT" noRedirect={noRedirect} />
						</Box>
					)}
					{(record.moderation_entry?.status === "accepted" || record.moderation_entry?.status === "pending") && (
						<Box margin={"0 15px"}>
							<StatusButton resource="moderation_entries" record={record.moderation_entry} currentResource={path} updateObject={{ status: "rejected" }} type="REJECT" noRedirect={noRedirect} />
						</Box>
					)}
				</Stack>
			: null}
		</>
    )
}

export default ModerationActionToolbar;

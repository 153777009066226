import React, { useContext, useState } from 'react';
import { debateDataProvider } from '../debate';

interface SettingsContextType {
    settings: Record<string, unknown> | undefined;
    setSettings: (settings: Record<string, unknown>) => void;
    fetchSettings: (shortname: string) => void;
}

const SettingsContext = React.createContext<SettingsContextType>({
    settings: {},
    setSettings: () => {},
    fetchSettings: () => {}
});

const SettingsContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [settings, setSettings] = useState<Record<string, unknown>>({});

    const handleSetSettings = (
        settings: Record<string, unknown> | undefined
    ) => {
        if (settings) {
            setSettings(settings);
        }
    };

    const fetchSettings = async (shortname: string) => {
        try {
            const response = await debateDataProvider.getApplicationSettings(
                shortname
            );

            if (!response) {
                return {};
            }

            const settingsObj = response.data;
            setSettings(settingsObj);
        } catch (error) {}
    };

    return (
        <SettingsContext.Provider
            value={{
                settings: settings,
                setSettings: handleSetSettings,
                fetchSettings: fetchSettings
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = () => useContext(SettingsContext);
export default SettingsContextProvider;

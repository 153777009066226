import React from "react";
import {
	TextField,
	RichTextField,
	DateField,
	SimpleForm,
	EditBase,
	NumberField,
	FunctionField,
	Labeled,
	useTranslate,
} from "react-admin";
import { UserReferenceField } from "../../users";
import { Grid, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ModerationActionToolbar from "../../utils/ModerationActionToolbar";

const ProposalEdit = ({ id, onCancel }) => {
	const translate = useTranslate();

	return (
		<EditBase id={id}>
			<Stack direction="row" p={2}>
				<Typography variant="h6" flex="1">
					{translate("resources.proposals.labels.detail")} #{id}
				</Typography>
				<IconButton onClick={onCancel} size="small">
					<CloseIcon />
				</IconButton>
			</Stack>
			<SimpleForm
				resource='proposals'
				toolbar={<ModerationActionToolbar path={'proposals'} />}
                sx={{ maxWidth: '600px !important' }}
			>
				<Grid container rowSpacing={1} mb={1}>
					<Grid item xs={12}>
						<Labeled>
							<TextField label='ID' source='identifier' />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<TextField source='title' />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<RichTextField source='content' />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<UserReferenceField source="author" targetId="author.hash_id" />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled label={translate("resources.messages.fields.moderation_score")}>
							<NumberField
								source="moderation_entry.moderation_score"
								label={translate('resources.messages.fields.moderation_score')}
								transform={v => Math.floor(100 - v) / 100}
								options={{ style: "percent" }}
							/>
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled>
							<DateField source='created_at' showTime={true} />
						</Labeled>
					</Grid>
					<Grid item xs={12}>
						<Labeled label={translate("resources.messages.fields.status")}>
							<FunctionField
								render={(record) =>
									record?.moderation_entry?.status === "rejected"
										? translate("resources.messages.status.rejected")
										: record.moderation_entry?.status === "accepted"
											? translate("resources.messages.status.accepted")
											: translate("resources.messages.status.pending")
								}
							/>
						</Labeled>
					</Grid>
				</Grid>
			</SimpleForm>
		</EditBase>
	);
};

export default ProposalEdit;

import React from 'react';
import { ButtonBase } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import styles from './ExposeMotifs.module.scss';

const ExposeMotifs = ({ document }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Exposé des motifs</div>

        <ButtonBase href="" className={styles.originalTextButton}>
          <span>Consulter texte original</span>
          <OpenInNew fontSize='small' />
        </ButtonBase>
      </div>

      <div className={styles.content}>
        {document?.exposeMotifs ?
          <div className={styles.text}>
            {document.exposeMotifs}
          </div>
          :
          <i>Pas d'exposé des motifs</i>
        }
      </div>
    </div>
  );
};

export default ExposeMotifs;

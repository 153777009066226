import React, { useState, useEffect } from 'react';
import { SelectInput, useDataProvider } from 'react-admin';
import { useSettingsContext } from '../utils/SettingsProvider';

const SubApplicationSelectInput = ({ label, ...props }) => {
    const [subApplications, setSubApplications] = useState([]);
    const settingsContext = useSettingsContext();
    const dataProvider = useDataProvider();

    const fetchSubApplications = async () => {
        try {
            const response = await dataProvider.getList("sub_applications", {
                sort: { field: "created_at", order: "DESC" },
				pagination: { page: 1, perPage: 10 },
				filter: {}
			});
			setSubApplications(response.data)
        } catch (error) { 
            console.log(error);
        }
    }
    
    useEffect(() => {
        fetchSubApplications();
    }, [])

    return (
        settingsContext?.settings?.actions?.allowDebateBranding === true && (
            <SelectInput
                source="sub_application_id"
                choices={subApplications?.map(value => ({ id: value.id, name: value.display_name || value.name }))}
                {...props}
            />
        )
    )
}

export default SubApplicationSelectInput;
import React from "react";
import { SourceList, SourceIcon } from "./sources";
import { TagList, TagIcon } from "./sources_tags";
import { Resource } from "react-admin";

export const routes = [
    <Resource
        name='sources'
        list={SourceList}
        icon={SourceIcon}
    />,
    <Resource
        name='sources_tags'
        list={TagList}
        icon={TagIcon}
    />
]
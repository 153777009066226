import { useListContext, useTranslate } from 'react-admin'
import { Toolbar, Button } from "@mui/material";
import { makeStyles } from '@mui/styles'
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles(_theme => ({
  toolbar: {
    display: 'flex',
		justifyContent: 'flex-end',
  },
}));

const CustomPagination = ({ alwaysOn = false }) => {
	const { page, hasPreviousPage, hasNextPage, setPage } = useListContext();
	const translate = useTranslate();
	const classes = useStyles();
	if (!alwaysOn && !hasPreviousPage && !hasNextPage) return null;
	return (
		<Toolbar className={classes.toolbar}>
			<Button
				disabled={page === 1 || !alwaysOn && !hasPreviousPage}
				onClick={() => setPage(page - 1)}
				startIcon={<ChevronLeft />}
			>
				{translate("pos.main.previous")}
			</Button>
			<Button
				disabled={!alwaysOn && !hasNextPage}
				onClick={() => setPage(page + 1)}
				startIcon={<ChevronRight />}
			>
				{translate("pos.main.next")}
			</Button>
		</Toolbar>
	);
}

export default CustomPagination

import React from "react";
import { Resource } from "react-admin";
import { 
    DebateList,
    DebateCreate,
    DebateEdit
} from './debates';
import { 
    ArgumentList,
    ArgumentIcon,
    CommentIcon
} from './arguments'
import {
    DebateSuggestionList,
    DebateSuggestionCreate,
    DebateSuggestionEdit,
    DebateSuggestionIcon
} from './debate_suggestions'
import {
    ConsultationList,
    ConsultationCreate,
    ConsultationEdit,
    ConsultationIcon
} from './consultations'
import {
    ProposalList,
    ProposalIcon
} from './proposals'
import {
    TranslationList,
    TranslationIcon
} from './translation_entries'
import PostIcon from "@mui/icons-material/Book";

export const routes = [
    <Resource
        name='groups'
        list={DebateList}
        edit={DebateEdit}
        create={DebateCreate}
        icon={PostIcon}
    />,
    <Resource
        name='consultations'
        list={ConsultationList}
        create={ConsultationCreate}
        edit={ConsultationEdit}
        icon={ConsultationIcon}
    />,
    <Resource
        name='messages'
        list={ArgumentList}
        icon={ArgumentIcon}
    />,
    <Resource
        name='comments'
        list={ArgumentList}
        icon={CommentIcon}
    />,
    <Resource
        name='proposals'
        list={ProposalList}
        icon={ProposalIcon}
    />,
    <Resource
        name='debate_suggestions'
        list={DebateSuggestionList}
        create={DebateSuggestionCreate}
        edit={DebateSuggestionEdit}
        icon={DebateSuggestionIcon}
    />,
    <Resource
        name='translation_entries'
        list={TranslationList}
        icon={TranslationIcon}
    />
]
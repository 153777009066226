import React from 'react';
import ExposeMotifs from './ExposeMotifs';
import TexteBox from './TexteBox';
import AgendaCompteRendus from './AgendaCompteRendus';
import { AmendementList } from './AmendementList';
import styles from './ActeLegislatifDetails.module.scss';

export const ActeLegislatifDetails = ({ acte, document, pointsOdj }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{acte.nomCanonique}</h3>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.top}>
          <ExposeMotifs document={document} />
          <AgendaCompteRendus pointsOdj={pointsOdj} />
        </div>

        <div className={styles.bottom}>
          <TexteBox document={document} />
          <AmendementList document={document} />
        </div>
      </div>
    </div>
  );
};

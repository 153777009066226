import React from 'react';
import styles from './Timeline.module.scss';
import { TimelineMilestone } from './TimelineMilestone';

export const Timeline = ({ milestones = [], selectedActeUid, currentActeUid, onChangeActe }) => {
  return (
    <div className={styles.timeline}>
      {milestones.map((milestone, index) => (
        <TimelineMilestone
          key={index}
          milestone={milestone}
          selectedActeUid={selectedActeUid}
          currentActeUid={currentActeUid}
          onChangeActe={onChangeActe}
        />
      ))}
    </div>
  );
};
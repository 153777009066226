import React from "react";
import { Menu, useTranslate } from "react-admin";
import { ModerationIcon } from './moderation_entries';
import { useLocation } from 'react-router-dom';
import SubMenu from "../utils/SubMenu";

export const ModerationMenu = () => {
	const translate = useTranslate();
	const location = useLocation();

	return (
		<>
			<SubMenu
				name={translate(`resources.moderation_entries.name`, { smart_count: 2 })}
				icon={<ModerationIcon />}
				isSubMenuOpen={ location.pathname.includes("moderation_entries/lobby") || location.pathname.includes("reports") }
			>
				<Menu.Item
					to='/moderation_entries/lobby'
					leftIcon={<ModerationIcon />}
					primaryText={translate("resources.moderation_entries.name")}
				/>
				<Menu.ResourceItem 
					name="reports"
					primaryText={translate(`resources.reports.name`, { smart_count: 2 })}
				/>
			</SubMenu>
		</>
	);
};
import React, { useRef } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ChipField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import TagFilter from './TagFilter';
import RecordLinkField from '../../inputs/RecordLinkField';
import SectionTitle from '../../utils/SectionTitle';
import CustomPagination from '../../utils/CustomPagination';

const TagList = (props) => {
    const translate = useTranslate();
    const pageTokens = useRef({});

    return (
        <>
            <SectionTitle
                title={translate("resources.sources_tags.section_title")}
                subtitle={translate("resources.sources_tags.section_subtitle")}
            />
            <List
                title={translate('resources.sources_tags.name', {smart_count: 2})}
                sort={{ field: 'sources_count', order: 'DESC' }}
                actions={null}
                filters={<TagFilter />}
                filter={{ cursor_pagination: true }}
                disableSyncWithLocation
                queryOptions={{ meta: { pageTokens } }}
                pagination={<CustomPagination />}
                {...props}
            >
                <Datagrid bulkActionButtons={null}>
                    <ChipField variant="outlined" source="display_name" label={translate("resources.sources_tags.fields.display_name")} />
                    <ChipField variant="outlined" source="name" label={translate("resources.sources_tags.fields.name")} />
                    <GroupField targetId={"content_tag.group.slug"} label={translate("resources.sources_tags.fields.linked_source")} />
                    <DateField source="content_tag.created_at" label={translate("resources.sources_tags.fields.created_at")} showTime={true} />
                    <TextField source="sources_count" />
                </Datagrid>
            </List>
        </>
    );
}

const GroupField = () => {
    const record = useRecordContext();

    return (
        <RecordLinkField
            resource={record?.content_tag?.group_type.toLowerCase() + "s"}
            targetId="content_tag.group.slug"
            source={record?.content_tag?.group_type === "Consultation" ? "content_tag.group.title" : "content_tag.group.name"}
        />
    )
}


export default TagList;

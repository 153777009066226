import React from 'react';
import { Box, Typography } from '@mui/material';

export const Alinea = ({ number, text, textAlign = "left" }) => {
    return (
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "10px",
        }}>
            <Typography id={`alinea-${number}`} sx={{
              border: "1px solid #C4C4C4",
              borderRadius: "32px",
              width: "24px",
              height: "24px",
              padding: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "14px",
            }}>{number}</Typography>
            <Typography sx={{
              textAlign,
              width: "100%",
              '& a': {
                color: "#566F7B",
                fontWeight: "600",
                '&:hover': {
                  textDecoration: "underline",
                },
              },
        }} dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
    );
};

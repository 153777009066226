import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles({
  helperText : {
    display: "flex",
    fontSize: "12px !important",
    padding: "5px !important",
    '& > svg': {
        width: "12px !important",
        height: "12px !important",
        marginRight: "5px",
        marginTop: "3px"
    }
  } 
});

const HelperText = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="body1" component="span" className={classes.helperText}>
        <HelpOutlineIcon /> {props.text}
    </Typography>
  )
}

export default HelperText;



import React, { useCallback, useEffect, useState } from 'react';
import { Button, useInput, useTranslate, useRecordContext } from 'react-admin';
import { TextField, Alert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import get from 'lodash/get';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  imageInput: {
    borderRadius: "6px 6px 0 0 !important",
    maxWidth: "210px",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  tinyImage: {
    maxWidth: "80px",
    borderRadius: "6px 6px 0 0 !important",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  uploadBox: {
    padding: '30px',
    border: '2px dashed #777',
    borderRadius: '3px',
    textAlign: 'center',
    width: '250px',
    background: '#e6e6e6',
    "&:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  errorBox: {
    borderColor: "#d32f2f !important",
    color: "#d32f2f !important",
    marginBottom: ".5em !important",
  },
  errorMessage: {
    color: "#d32f2f !important",
    fontSize: "0.75em !important",
  },
  spanInput: {
    marginRight: '5px',
  },
  groupImageInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #e6e6e6",
    borderRadius: "6px",
    paddingTop: "6px"
  },
  errorAlert: {
    marginTop: "15px"
  }
}))

const MAX_FILE_SIZE = 10000000;

const ImageUrlInput = ({ source, initialSource, allowedFormats = ["jpg", "jpeg", "png", "webp"], label, helperText, tinyImage, className, errorMessage, validate, required = false }) => {
  const record = useRecordContext();
  const initialValue = get(record, initialSource);
  const [imageError, setImageError] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const [widget, setWidget] = useState(null);
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({ source, validate, required });
  
  useEffect(() => {
    const initWidget = () => {
      const uploadWidget = window.cloudinary.createUploadWidget({
        cloudName: 'logora-api',
        uploadPreset: 'e07gsced',
        sources: ['local', 'url', 'image_search', 'google_drive'],
        multiple: false,
        cropping: true,
      }, (error, result) => {
        if (result.event === 'success') {
          if (result.info.bytes < MAX_FILE_SIZE && allowedFormats.includes(result.info.format)) {
            field.onChange(result.info.secure_url);
            setImageError(false);
          } else {
            setImageError(true);
          }
        }
      });
      setWidget(uploadWidget);
    };
    initWidget();
  }, []);

  const showWidget = useCallback(
    (event, defaultColor) => {
      setImageError(false);
      event.preventDefault();
      widget.open();
    }, [field]
  );

  return (
    <div>
      <TextField
        margin="normal"
        label={label}
        error={(isTouched || isSubmitted) && invalid}
        helperText={(isTouched || isSubmitted) && invalid ? error?.message : helperText}
        className={className}
        required={isRequired}
        source={source}
        onChange={field.onChange}
        defaultValue={field.value || initialValue}
        sx={{ display: 'none !important' }}
      />
      {field.value || initialValue ?
        <div className={classes.groupImageInput}>
          <img
            src={field.value || initialValue}
            className={tinyImage ? classes.tinyImage : classes.imageInput}
            onClick={(event) => showWidget(event)}
          />
          <Button
            label={translate('resources.groups.labels.edit_picture')}
            alignIcon='left'
            onClick={(event) => showWidget(event)}
          />
        </div>
        :
        <>
          <div className={`${classes.uploadBox} ${(imageError || invalid || error) && classes.errorBox}`} onClick={(event) => showWidget(event)}>
            <span className={classes.spanInput}> {translate('resources.groups.labels.add_picture')} </span><AddCircleOutlineIcon />
          </div>
          {imageError && errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
        </>
      }
      {(imageError || error) &&
        <Alert severity="error" className={classes.errorAlert}>
          {translate("pos.errors.image_error")}
        </Alert>
      }
    </div>
  );
};

export default ImageUrlInput;
import { useState, useEffect } from 'react';

const useFetchScrutinVotes = (dataProvider, scrutinRef) => {
    const [votes, setVotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVotes = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await dataProvider.getList("votes", {
                    filter: { scrutinRefUid: scrutinRef },
                    include: ['acteurRef.mandatPrincipal', 'acteurRef.groupeParlementaire'],
                    distinct: 'acteurRefUid',
                    pagination: { perPage: 1000 }
                });
                const votes = response.data;
                setVotes(votes);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchVotes();
    }, []);

    return { votes, loading, error };
};

export default useFetchScrutinVotes;
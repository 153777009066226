import { authTokenHandler } from "@logora/debate.auth.use_auth";
import { HttpError } from 'ra-core';
import { axiosClient } from './axiosClient';

const tokenKey = process.env.NODE_ENV === "production" ? "logora_admin_token" : "logora_admin_token_staging";
const tokenHandler = authTokenHandler(axiosClient, process.env.REACT_APP_API_AUTH_URL, tokenKey);

const httpClient = (url, options = {}, hasToken = true) => {
    // TODO: utiliser des intercepteurs pour ajouter les headers
    if(!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    
	const tokenObject = tokenHandler.getToken();
	const currentApplication = localStorage.getItem("currentApplication");

	if (tokenObject && hasToken) {
		options.headers.set("Authorization", `Bearer ${tokenObject.access_token}`);
	}
	if (currentApplication) {
		options.headers.set("current-application", currentApplication);
	}

    const requestHeaders = Object.fromEntries(createHeadersFromOptions(options));

    // TODO: utiliser directement les fonctions axios dans dataProvider
    return axiosClient.request({ url: url, method: options.method, headers: requestHeaders, data: options.body })
        .then(response => response)
        .then(({ status, statusText, headers, data }) => {
            const body = data;
            const json = data;        
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        statusText,
                        status,
                        data
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
    }).catch(error => {});
};

const createHeadersFromOptions = (options) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json',
        }
    ));
    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) && 
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    return requestHeaders;
};

export default httpClient;